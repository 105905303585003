/**
 * 将 数组中有值的label、value 隐射为对应{value:label}
 * @param keyName
 * @param arr
 * @param prop
 * @returns {{}}
 */
export function mapFromArrayToObject(arr = [], prop = {}) {
  if (!Array.isArray(arr)) return {};
  if (arr.length <= 0) return {};
  prop = Object.assign({ label: "label", value: "value" }, prop);
  let obj = {};
  arr.forEach((res) => {
    if (
      res[prop.label] !== "全部" &&
      res[prop.label] !== undefined &&
      res[prop.value] !== undefined
    ) {
      obj[res[prop.label]] = res[prop.value];
      obj[res[prop.value]] = res[prop.label];
    }
  });
  return obj;
}
// 物流产品类型 列表
export let productTypeList = [
  { label: "FBX", value: "FBX", icon: "m-icon-FBX", iconType: "aliyun",visible:true, },
  {
    label: "海运拼箱",
    value: "HYPX",
    icon: "m-icon-steamer-l",
    iconType: "aliyun",
    visible:true,
  },
  {
    label: "国际空运",
    value: "GJKY",
    icon: "m-icon-airmail",
    iconType: "aliyun",
    visible:true,
  },
  {
    label: "陆运",
    value: "LY",
    icon: "m-icon-cart2",
    iconType: "aliyun",
    visible:true,
  },
  {
    label: "FBX+海运拼箱",
    value: "FBX&HYPX",
    icon: "",
    iconType: "",
  },
  {
    label: "FBX+国际空运",
    value: "FBX&GJKY",
    icon: "",
    iconType: "",
  },
  {
    label: "FBX+陆运",
    value: "FBX&LY",
    icon: "",
    iconType: "",
  },
  // { label: "专线小包", value: "XB", icon: "m-icon-box", iconType: "aliyun" },
  // {
  //   label: "整柜",
  //   value: "WC",
  //   icon: "m-icon-full-container-load",
  //   iconType: "aliyun",
  // },
];
// 物流产品类型 物流产品类型
export let commonProductTypeListMapObject =
  mapFromArrayToObject(productTypeList);

// 运输方式 LY：陆运 HY：海运 KY：空运
export let commonTransportTypeList = [
  { label: "海运", value: "HY" },
  { label: "空运", value: "KY" },
  { label: "陆运", value: "LY" },
];
// 运输方式 映射对象
export let commonTransportTypeMapObject = mapFromArrayToObject(
  commonTransportTypeList
);

// 派送方式：KP（卡派）、HK（海卡）、HP（海派）、WC（整柜直送）、FP（空派）、KK（空卡）、ZT(自提)、KDP(快递派)、KH（卡航）、TL（铁路）
export let commonDeliveryTypeList = [
  { label: "卡派", value: "KP" },
  { label: "海卡", value: "HK" },
  { label: "海派", value: "HP" },
  { label: "整柜直送", value: "WC" },
  { label: "空派", value: "FP" },
  { label: "空卡", value: "KK" },
  { label: "自提", value: "ZT" },
  { label: "快递派", value: "KDP" },
  { label: "卡航", value: "KH" },
  { label: "铁路", value: "TL" },
  { label: "亚马逊海卡", value: "YMXHK" },
];
// 派送方式 映射对象
export let commonDeliveryTypeMapObject = mapFromArrayToObject(
  commonDeliveryTypeList
);

// 时效单位
export let commonAgingUnitList = [
  { label: "工作日", value: 1 },
  { label: "自然日", value: 2 },
];
// 时效单位 映射对象
export let commonAgingUnitMapObject = mapFromArrayToObject(commonAgingUnitList);

// 分泡方式
export let commonPointsBubbleWay = [
  { label: "实重", value: 1 },
  { label: "实重和体积重取大", value: 2 },
  { label: "分50%泡", value: 3 },
  { label: "分1/3泡", value: 4 },
  { label: "分30%泡 ", value: 5 },
  { label: "体积（CBM）", value: 6 },
];

// 分泡方式 映射对象
export let commonPointsBubbleWayMapObject = mapFromArrayToObject(
  commonPointsBubbleWay
);

// 新闻所属栏目
export let commonNewsType = [
  { label: "公司新闻", value: 1 },
  { label: "行业快讯", value: 2 },
  { label: "用户手册", value: 3 },
  { label: "开放平台", value: 4 },
];

// 新闻所属栏目 映射对象
export let commonNewsTypeMapObject = mapFromArrayToObject(commonNewsType);

// 计费方式
export let commonChargeType = [
  { label: "体积", value: "CBM" },
  { label: "重量", value: "KG" },
  { label: "箱", value: "BOX" },
  { label: "车", value: "CAR" },
];

// 计费方式 映射对象
export let commonChargeTypeMapObject = mapFromArrayToObject(commonChargeType);
