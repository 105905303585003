import axios from "axios";
import { getLocalId, isObject } from "@/utils/utils";
import { hintLogin } from "@/utils/utils.router";
import { useUserStore } from "@/stores/user";
import {
  getCommonRequestParams, getSourceType,
  getSystemType,
  getTenantId,
} from "@/utils/utils.commonTenantParams";

// 请求头 headers.Content-Type.form-data
export const headersContentTypeFormData = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
};

/**
 * 根据是否开启代理等条件，处理基础的 axios config
 * @param isPROD {boolean} 应用是否运行在生产环境
 * @param openProxy {boolean} 是否开启代理
 * @param baseUrl {string}  请求地址
 * @param timeout {number|string|undefined} 超时时间
 * @return {{baseUrl?:string,timeout?:number}}
 */
function disposeBaseConfig(isPROD, openProxy, baseUrl, timeout) {
  const aConfig = {
    baseUrl: "/",
    timeout: 1000 * 30,
    headers: {
      "Content-Type": "application/json;charset:utf-8;",
    },
  };
  if (isPROD) {
    aConfig.baseURL = location.origin + "/";
  } else {
    if (!openProxy) aConfig.baseURL = baseUrl;
  }
  if (timeout) {
    if (typeof timeout !== "number") timeout = parseInt(timeout) | 0;
    if (timeout) aConfig.timeout = timeout;
  }
  return aConfig;
}

// 应用是否运行在生产环境。
export const isPROD = import.meta.env.PROD;
const openProxy = import.meta.env.VITE_REQUEST_OPEN_PROXY;
const beforePath = import.meta.env.VITE_REQUEST_BEFORE_PATH;
const timeout = import.meta.env.VITE_REQUEST_OUT_TIME;
const mainConfig = disposeBaseConfig(isPROD, !!openProxy, beforePath, timeout);
const axiosMain = axios.create(mainConfig);

function logError(msg) {
  console.error(msg);
}

// 添加请求拦截器
axiosMain.interceptors.request.use(
  function (config) {
    // 在发送请求之前做些什么
    // 通过store获取token
    // const token = "";
    // if (token) config.headers.token = token;
    const userStore = useUserStore();
    if (userStore.token)
      config.headers.Authorization = `Bearer ${userStore.token}`;
    // tenantId
    const tenantId = getTenantId();
    if (tenantId) config.headers.tenantId = tenantId;
    else if (config.url !== '/oms/tenant') {
      return Promise.reject({response: { data: {message: '获取租户id异常'}}})
    }
    // 系统类型 1  租户管理、2  TMS、3 OMS
    const systemType = getSystemType();
    if (systemType) config.headers.systemType = systemType;
    // requestId
    let requestId = getLocalId();
    config.headers.requestId = requestId;
    // sourceType
    config.headers.sourceType = getSourceType();
    // request source
    config.headers.source = "WEB";
    // 对符合条件请求包裹公共参数
    const method = config.method.toLowerCase();
    const contentType = config.headers["Content-Type"];
    const isJson =
      typeof contentType === "string"
        ? contentType.includes("application/json")
        : false;
    if (isJson && ["post", "put", "delete"].includes(method)) {
      let originData = config.data;
      // 是否包裹外层公告参数
      let needCommonParams = true;
      if (isObject(originData)) {
        // originData.noCommonParams = true;
        if (originData.noCommonParams === false) {
          needCommonParams = false;
        }
      }
      if (needCommonParams) {
        let newData = getCommonRequestParams(originData, undefined, {
          requestId,
        });
        config.data = newData;
      }
    }
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 添加响应拦截器
axiosMain.interceptors.response.use(
  function (response) {
    if (["arraybuffer", "blob"].includes(response.config.responseType || "")) {
      //文件下载直接返回流数据
      return response;
    }
    const res = response.data;
    let msg = "";
    if (!isObject(res)) {
      // 请求异常：缺失标准响应数据
      msg = `请求异常：缺失标准响应数据`;
      logError(msg);
      return Promise.reject(new Error(msg));
    }
    if (response.config.url.match(/\/cms\//gi)) {
      if ([1, "1"].includes(res.code)) return res;
    }
    if (response.config.url.match(/\/api\//gi)) {
      if (res.code === 200) return res;
    }
    let resultCode = `${res.returnCode || res.return_code || ""}`;
    let returnMsg = `${res.returnMsg || res.return_msg || ""}`;
    if (resultCode !== "200") {
      if (resultCode === "401" || returnMsg === "token.out.of.date.msg") {
        // 未登录/登录超时
        msg = "未登录/登录超时";
        logError(msg);
        hintLogin();
        return Promise.reject(new Error(msg));
      }
      msg = res.returnMsg || `请求异常：code=${resultCode}`;
      logError(msg);
      return Promise.reject(new Error(msg));
    }
    return res;
  },
  function (error) {
    // 对响应错误做点什么
    // let msg = `${error.message} 【`;
    let msg = ``;
    if (error.response) {
      // 服务端有响应
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      if(isObject(error.response.data)){
        if(!error.response.data.message){
          error.response.data.message = error.response.data.returnMsg;
        }
        logError(error.response.data.message);
        return Promise.reject(error.response.data);
      } else if(error.response.status === 401) {
        msg += `未登录/登录超时 `;
        hintLogin();
      } else if (error.response.status === 500) {
        if (isObject(error.response.data)) {
          msg += `${error.response.data.returnMsg || ""} `;
        }
      } else if (error.response.status === 404) {
        msg += `请求不存在 `;
      } else {
        msg += `服务端响应异常 `;
      }
      msg += `(status = ${error.response.status}) `;
    } else if (error.request) {
      // 未收到响应
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      if (/Network Error/gi.test(error.message)) {
        // 网络错误
        msg += `网络错误 `;
      } else if (/timeout/gi.test(error.message)) {
        // 请求超时
        msg += `请求超时 `;
      } else {
        msg += `请求未响应 `;
      }
      msg += `status = ${error.request.status} `;
    } else {
      // 建立请求出现错误，未发出
      // Something happened in setting up the request that triggered an Error
      msg += ` 建立请求出错 `;
    }
    // msg += " 】 ";
    if (!msg) msg = `${error.message} `;
    logError(msg);
    error.message = msg;
    return Promise.reject(error);
  }
);

export default axiosMain;

export const get = axiosMain.get;
export const post = axiosMain.post;
