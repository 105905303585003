<template>
  <div class="select-place-template"
       ref="selectPlaceTemplate"
       :class="[styleClass,{'is-left-padding':isLeftPadding}]">
    <div class="input-box">
      <el-input class="search-text is-input"
                :class="{'is-clear':clearable}"
                ref="selectStartInput"
                :placeholder="isHasSelectValue?'':placeholder"
                v-model="searchText"
                :disabled="disabled"
                @click="noEvent"
                @blur="handlerBlur"
                @focus="handlerFocus"
                @keyup.enter.native="handlerEnter"
                @keydown.tab.native="handlerTab"
      ></el-input>
      <!--    <div class="search-text"-->
      <!--         ref="selectStartInput"-->
      <!--         v-popover:startPopover-->
      <!--         @click.stop="handlerFocus">-->
      <!--      {{isHasSelectValue?'':placeholder}}-->
      <!--    </div>-->

      <div class="value-text"
           :class="[showTextStatus,clearable?'is-clear':'']"
      >
        <slot>
          <div class="text-row">
            {{ multipleShowName }}
            <!--          <template v-if="multiple">{{ multipleShowName }}</template>-->
            <!--          <template v-else>{{modelValue[labelKey] || modelValue[idKey]}}</template>-->
          </div>
        </slot>
      </div>
    </div>

    <div class="btn-delete" v-if="clearable && isHasSelectValue && !disabled">
      <el-icon class="icon el-icon-circle-close"  @click="clickClear"><CloseBold /></el-icon>
    </div>
    <el-popover
        :popper-class="`popover-select-place-template ${popoverClass}`"
        :show-arrow="true"
        ref="startPopover"
        trigger="contextmenu"
        placement="bottom-start"
        title=""
        :offset="offset"
        :width="popoverWidth"
        :visible="visibleListPopover"
        virtual-triggering
        :virtual-ref="$refs.selectPlaceTemplate"
        @hide="handlerPopoverHide"
        @after-leave= "handlerPopoverAfterLeave"
    >
      <div class="alter-place-bpx">
        <template v-if="showList.length">
          <div class="groups-type-wrap" ref="tabs">
            <div class="groups-type"
                 :class="{
                  'align-left':showList.length<=10
                 }">
              <div class="groups-type__item"
                   :class="{
                    'is-active':groupsTypeCurrent === index,
                    'is-undefined': !item,
                    }"
                   @click="clickGroupsType(index,item,$event)"
                   v-for="(item,index) in showList"
                   :key="index">
                <template v-if="item">
                  {{item[tabKey]}}
                </template>
              </div>
            </div>
          </div>

          <div class="groups-list-wrap" ref="list">
            <div class="groups-list">
              <div class="groups-li"
                   v-for="(item,index) in showList"
                   :key="index">
                <template v-if="item">
                  <div class="groups-li__left" v-if="showLeftLabel">
                    <template v-if=" item[tabKey] === '私人地址' || item[tabKey] === '目的城市'">
                      <!-- }私人<br>地址 -->
                      {{isHome ? '目的' : '私人'}}<br>{{isHome ? '城市' : '地址'}}
                    </template>
                    <template v-else>
                      {{item[tabKey]}}
                    </template>
                  </div>
                  <div class="groups-li__right">
                    <template v-if="item[listKey] && item[listKey].length">
                      <div class="groups-li__item"
                           :title="d[labelKey] + (d.disabled ? d.disabledText : '') "
                           :class="{'is-active':judgeItemIsSelect(d),'is-disabled':d.disabled}"
                           @click="selectItem(d)"
                           v-for="(d,i) in item[listKey]"
                           :key="i">
                        {{d[labelKey]}}
                      </div>
                    </template>
                  </div>
                </template>
              </div>
            </div>
          </div>

        </template>
        <template v-else-if="isLoading">
          
          <div class="hint-row"><i class="el-icon-loading"></i> {{ loadingText }}</div>
        </template>
        <template v-else>
          <div class="hint-row"><i class="el-icon-warning-outline"></i> {{ noDataMessage }}</div>
        </template>
        <template v-if="multiple && maxSelectNumber !== 1">
          <div class="footer-row">
            <el-button @click="hideListPopover"
                       class="btn btn-cancel"
                       type="info"
                       plain
                       size="default" >取消</el-button>
            <el-button @click="confirmSelect"
                       type="primary"
                       class="btn"
                       size="default">确认选择</el-button>
          </div>
        </template>
      </div>
    </el-popover>
  </div>
</template>

<script>
import BScroll from "better-scroll";
export default {
  name: "selectPlaceTemplate",
  emits: [
    'update:modelValue',
    'blur',
    'focus',
    'change',
    'clear',
    'hide',
    'after-leave',
  ],
  props:{
    // 是否默认样式
    isDefaultStyle: {
      type:Boolean,
      default:true,
    },
    isWhiteStyle:Boolean,
    isLeftPadding: {
      type:Boolean,
      default:true,
    },
    multiple:{ // 是否支持多选
      type:Boolean,
      default:false,
    },
    filterPrivateAddress:{ // 是否在选中某项时过滤已选列表中 不同类型(是否为私人地址) 的item
      type:Boolean,
      default:false,
    },
    modelValue:{ // 选中值,单选Object,多选Array
      type:[Object,Array],
      default:()=>({}),
    },
    list:{ // 列表
      type:Array,
      default:()=>[],
    },
    disabled:{ // 输入框是否禁用，禁用情况下点击输入框页无法弹出列表
      type:Boolean,
      default:false,
    },
    loadingText:{
      type:String,
      default:"正在请求数据中...",
    },
    popoverClass:{ //为 popper 添加类名
      type:String,
      default:"",
    },
    popoverWidth:{ //弹出层宽度
      type:String,
      default:"490",
    },
    offset:{ //出现位置的偏移量
      type:Number,
      default:undefined,
    },
    placeholder:{ //输入框提示信息
      type:String,
      default:"请选择",
    },
    noDataMessage:{ //列表为空时的提示信息
      type:String,
      default:"暂未找到您要的信息",
    },
    listKey:{ // 二级列表取值字段
      type:String,
      default:"list",
    },
    tabKey:{ // 一级列表分组标签字段
      type:String,
      default:"code",
    },
    labelKey:{ // 显示在输入框的文本取值字段
      type:String,
      default:"namec",
    },
    idKey:{ // 对选中数据进行匹配校验的字段
      type:String,
      default:"id",
    },
    isLoading:{ // 显示 正在请求数据中...
      type:Boolean,
      default:false,
    },
    showLeftLabel:{ // 是否显示左边的分组标签
      type:Boolean,
      default:true,
    },
    isEmptyCanSearch:{ // 输入框文本为空时是否获取列表
      type:Boolean,
      default:true,
    },
    clearable:{ // 是否可清空
      type:Boolean,
      default:true,
    },
    maxSelectNumber:{ //最大选择数量,为零时不限制，只在选择某个item操作后截断处理。
      type:Number,
      default:0,
    },
    isHome: {
      type: Boolean,
      default: false
    },
    // 搜索结果唯一（为true时将对搜索结果去重）
    searchResultUnique:{
      type:Boolean,
      default:false,
    },
    // 是否过滤掉"热门"中的数据
    filterHot:{
      type:Boolean,
      default:false,
    },
    filterHotText:{
      type:String,
      default:"热门",
    },
  },
  data(){
    return {
      searchText:"",//输入框搜索文本
      loading:false,
      selectedValue:[],
      filterList:[],
      isFocus:false,//当前输入框是否获得焦点
      // selectValue:{}, //选中的值
      // 显示列表弹出层
      visibleListPopover:false,
      popperOptions: {
        modifiers: [
          {
            name: 'computeStyles',
            options: {
              gpuAcceleration: false,
              adaptive: false,
            }
          },
        ],
      },
      /*当前选中分组*/
      groupsTypeCurrent:0,
      scrollTargetTab:null,
      scrollTargetList:null,
      listDomHeight:[],
    }
  },
  computed:{
    styleClass(){
      let defaultStyle = "";
      if(this.isWhiteStyle){
        return "is-white-style"
      }else if(this.isDefaultStyle){
        return "is-default-style"
      }
      return defaultStyle
    },
    /**
     * 回显文本状态 class
     * @returns {string}
     */
    showTextStatus(){
      // 搜索框有输入值
      if(this.searchText.length) return "opacity-0"
      // 搜索框没有输入值，但是聚焦状态
      if(this.isFocus) return "opacity-04"
      return "";
    },
    /**
     * 是否有选中值
     * @returns {*}
     */
    isHasSelectValue(){
      // 有选中值时 输入框不显示placeholder
      let list = this.selectedValue;
      return isArray(list) && list.length && list.some((res={})=>res[this.idKey]||res[this.idKey]===0);

      // if(this.multiple){
      //   return isArray(this.modelValue) && this.modelValue.length && this.modelValue.some((res={})=>res[this.idKey]||res[this.idKey]===0);
      // }else{
      //   return this.modelValue[this.idKey]||this.modelValue[this.idKey]===0
      // }
    },
    /**
     * 多选时展示的选中文本
     */
    multipleShowName(){
      let list = this.selectedValue;
      if(!isArray(list)) return "";
      return list.map((res={})=>{
        return res[this.labelKey]|| res[this.idKey]
      }).join(",");
    },
    showList(){
      let list = [];
      if(this.searchText){
        list = this.filterList||[]
      }else{
        list = this.list||[]
      }
      if(!list.length) this.destroyScrollTarget();
      return list;
    },
  },
  watch:{
    modelValue:{
      immediate:true,
      handler: function (newValue) {
        this.selectedValue = this.receiveDefaultValue(this.modelValue);
      },
    },
    list:{
      deep:true,
      handler: function (newValue) {
        if(this.visibleListPopover){
          this.destroyScrollTarget();
          if(newValue.length) {
            setTimeout(()=>{
              this.initGroupsScroll()
            },100)
          }
        }
      },
    },
    visibleListPopover(newValue){
      if(!newValue) return false;
      if(this.list.length) this.$nextTick(()=>{
        setTimeout(()=>{
          this.initGroupsScroll()
        },100)
      });
    },
    /* 监听搜索输入框 */
    searchText(newValue){
      this.filterList = [];
      this.loading = true;
      this.changeSearch();
    },
  },
  methods:{
    noEvent(){},
    /**
     * 根据 单选/多选 类型处理默认选中值为selectValue所需的格式
     */
    receiveDefaultValue(value){
      let list = [];
      if(this.multiple){
        if(isArray(value)) list = value.concat([]);
      }else{
        if(isObject(value)) list = [value];
      }
      return list;
    },
    /**
     * 失去焦点时触发
     */
    handlerBlur(){
      this.isFocus = false;
      this.$emit('blur');
      // this.hideListPopover();
    },
    /**
     * 获得焦点时触发
     */
    handlerFocus(){
      this.isFocus = true;
      this.$emit('focus');
      this.showListPopover();
    },
    /**
     * 按下回车时触发
     */
    handlerEnter(){
      console.log('按下回车')
    },
    handlerTab() {
      console.log('按下tab')
      this.handlerPopoverHide();
      this.hideListPopover();
    },
    /**
     * 清空
     */
    clickClear(){
      let value;
      if(this.multiple){
        value = [];
      }else{
        value = {};
      }
      this.$emit("update:modelValue",value);
      this.$emit("change",value);
      this.$emit("clear",value);
    },
    /**
     * 确认选择
     */
    confirmSelect(){
      this.$emit("update:modelValue",this.selectedValue);
      this.$emit("change",this.selectedValue);
      this.hideListPopover();
    },
    /**
     * 显示、隐藏列表
     */
    showListPopover() {
      this.visibleListPopover = true;
    },
    hideListPopover(e){
      this.visibleListPopover = false;
      if(this.multiple){
        this.selectedValue = this.receiveDefaultValue(this.modelValue);
      }
      this.searchText = "";
    },
    handlerPopoverHide(){
      this.$emit("hide")
      this.destroyScrollTarget();
    },
    handlerPopoverAfterLeave(){
      this.$emit('after-leave');
    },
    /**
     * 初始化分组滚动容器并注册监听
     */
    initGroupsScroll(){
      if(!this.list.length) return false;
      if(!(this.$refs.tabs && this.$refs.list)) return false;
      this.scrollTargetTab = new BScroll(this.$refs.tabs, {
        click: true,
        mouseWheel: true,
        // tap: true,
      });
      this.scrollTargetList = new BScroll(this.$refs.list, {
        probeType: 3, //探针的效果，实时获取滚动高度
        mouseWheel: true, // 开启鼠标滚轮模式
        // disableMouse: true,
        // click: true,

        // scrollY: true,
        // scrollbar: true,
        scrollbar:{
          fade:false,
          interactive:true,
          scrollbarTrackClickable:true,
        },
      });

      // 列表滚动处理逻辑
      this.scrollTargetList.on("scroll", e => this.listenListScroll(e));

      // 存储 列表item 高度
      this.countListDomHeight();
    },
    /**
     * 列表滚动处理逻辑
     * @param e
     */
    listenListScroll:function (e){
      const y = Math.abs(Math.round(e.y));
      const list = this.listDomHeight;
      // const boxHeight = this.$refs.list.clientHeight;
      // const lastItemHeight = list[list.length - 1];
      for (let i = 0; i < list.length; i++) {
        let height = list[i];
        let height2 = list[i + 1];
        if( !height2 || ( y >= height  && y < height2) ){
          this.groupsTypeCurrent = i;
          break;
        }
        // console.log(boxHeight,lastItemHeight,y);
        // console.log(lastItemHeight - y <= boxHeight,list.length - 1,list);
        // if(lastItemHeight - y <= boxHeight){
        //   this.groupsTypeCurrent = list.length - 1;
        //   console.log("ok",list.length - 1)
        //   break;
        // }
      }
    },
    /**
     * 当列表数据变动时（dom内容改变时），需要重新计算dom高度等数据
     */
    refreshScrollDom(){
      this.scrollTargetTab.refresh();
      this.scrollTargetList.refresh();
      this.scrollTargetList.scrollTo(0,0);
      this.countListDomHeight();
    },
    /**
     * 销毁 BetterScroll，解绑事件
     */
    destroyScrollTarget(){
      // if(this.scrollTargetTab && this.scrollTargetTab.destroy) this.scrollTargetTab = this.scrollTargetTab.destroy();
      // if(this.scrollTargetList && this.scrollTargetList.destroy) this.scrollTargetList = this.scrollTargetList.destroy();
      this.scrollTargetTab = this.scrollTargetTab && typeof this.scrollTargetTab.destroy === "function" ? this.scrollTargetTab.destroy() : null;
      // this.scrollTargetList = this.scrollTargetList && typeof this.scrollTargetList.destroy === "function" ? this.scrollTargetList.destroy() : null;
      if(this.scrollTargetList && typeof this.scrollTargetList.destroy === "function"){
        this.scrollTargetList.scrollTo(0,0);
        this.scrollTargetList = this.scrollTargetList.destroy();
      }else{
        this.scrollTargetList = null;
      }
      this.listDomHeight = [];
    },
    /**
     * 计算列表中每一个item的高度
     */
    countListDomHeight(){
      const target = this.$refs.list;
      if(!target) return this.listDomHeight = [];
      let listItems = target.getElementsByClassName("groups-li");
      let height = 0;
      let arr = [height];
      for (let i = 0; i < listItems.length; i++) {
        let item = listItems[i];
        height += item.clientHeight||0;
        arr.push(height);
      }
      this.listDomHeight = arr;
    },
    /**
     * 点击切换分组
     */
    clickGroupsType(index, item,e){
      if (!e._constructed) {
        return false;
      } else {
        let rightItems = this.$refs.list.getElementsByClassName("groups-li");
        let el = rightItems[index];
        this.scrollTargetList.scrollToElement(el, 300);
      }
    },
    /**
     * 点击选中某个值
     * @param item
     */
    selectItem(item){
      if(item.disabled) return this.$message.error(`${item.namec}:${item.disabledText}`);
      let list = this.selectedValue;
      if(this.multiple){
        if(this.filterPrivateAddress) list = list.filter(res=>!!res.isPrivateAddress === !!item.isPrivateAddress); // 选中时清除不同类型（FBA仓库/私人地址）的选项
        const index = list.findIndex((res={})=>res.id === item.id);
        if(index >=0) {
          list.splice(index,1);
        }else{
          list.push(item);
        }
        if(this.maxSelectNumber && list.length > this.maxSelectNumber){
          list.splice(0,list.length - this.maxSelectNumber);
        }
        if(this.maxSelectNumber === 1){
          this.$emit("update:modelValue",list);
          this.$emit("change",list);
          this.hideListPopover();
        }else{
          // 一个以上的选择点击确认按钮
        }
      }else{
        list = [item];
        this.$emit("update:modelValue",item);
        this.$emit("change",item);
        this.hideListPopover();
      }
    },
    /**
     * 判断某项值是否选中
     */
    judgeItemIsSelect(item={}){
      let list = this.selectedValue;
      return isArray(list) && list.some((res={})=>res[this.idKey] === item[this.idKey]);
      // if(this.multiple){
      //   return isArray(this.modelValue) && this.modelValue.some((res={})=>res[this.idKey] === item[this.idKey]);
      // }else{
      //   return this.modelValue[this.idKey] === item[this.idKey];
      // }
    },
    /**
     * 触发搜索
     */
    changeSearch:debounce(function(){
      let text = this.searchText.replace(/(^\s+)|(\s+$)/g,"");
      text = text.replace(/[a-z]+/g,function (match,matchIndex,str){
        return match.toUpperCase();
      });
      console.log("触发搜索",text);
      let list = [];
      if(!text && !this.isEmptyCanSearch){
        // 输入框为空时 且 不允许为空搜索时
      }else{
        list = this.list.map(one=>{
          if(!isObject(one)) return false;
          let tabName = one[this.tabKey];
          if(!!this.filterHot && this.filterHotText && tabName.match(this.filterHotText)) return false;
          let twoList = one[this.listKey];
          if(!isArray(twoList)) return false;
          twoList = twoList.filter(two=>{
            if(!isObject(two)) return false;
            let str = two[this.labelKey];
            if(typeof str === "number") str +="";
            if(!str || typeof str !== "string") return false;
            // console.log(`______【${two.namec}】`,str.match(text))
            return !!str.match(text);
          });
          if(twoList.length){
            let newObj = {};
            newObj[this.listKey] = twoList;
            return Object.assign({},one,newObj);
          }else{
            return false;
          }
        });
      }
      list = list.filter(res=> res!== false);
      if(this.searchResultUnique) list = this.searchResultRemoveDuplication(list);
      this.filterList = list;
      this.loading = false;
      if(list.length) this.$nextTick(()=>{
        setTimeout(()=>{
          this.initGroupsScroll()
        },100)
      })
    },200,false),
    /**
     * 全局关闭
     */
    globalClosePopover(e){
      // console.log("this.visibleListPopover",this.visibleListPopover)
      if(!this.visibleListPopover) return false;
      let wrapView = this.$refs.startPopover;
      let {contentRef,triggerRef} = wrapView.popperRef;
      // console.log(wrapView.popperRef)
      // console.log("contentRef",triggerRef.contains(e.target))
      // console.log("triggerRef",triggerRef,contentRef.contains(e.target))
      if(triggerRef && triggerRef.contains && triggerRef.contains(e.target)) return true;
      if(contentRef && contentRef.contains && contentRef.contains(e.target)) return true;
      this.hideListPopover();
    },
    /**
     * 搜索结果去重
     * @param list {Array}  二级列表
     */
    searchResultRemoveDuplication(list = []){
      let hasValueList = [];
      list = list.map(one => {
        if(!isObject(one)) return false;
        let twoList = one[this.listKey];
        twoList = twoList.filter(two=>{
          let twoValue = two[this.idKey];
          let isHasValue = hasValueList.includes(twoValue);
          if(isHasValue) return false
          hasValueList.push(twoValue);
          return true;
        });
        return Object.assign({},one,{
          [this.listKey]:twoList,
        })
      });
      list = list.filter(one => {
        if(!isObject(one)) return false;
        let twoList = one[this.listKey];
        return !!twoList.length;
      })
      return list;
    },
  },
  created() {
    // this.countDefaultSelect();
    document.addEventListener("click",this.globalClosePopover,false);
  },
  destroy() {
    document.removeEventListener("click",this.globalClosePopover);
  },
}
</script>
<style lang="scss">
/* 列表弹出层 */
.popover-select-place-template{
  box-sizing: border-box;
  background-color: #FFFFFF;
  box-shadow: 0 0 24px 0px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  overflow: hidden;
  border: none;
  &,
  &.el-popover--plain{
    padding: 0 ;
  }
  &[x-placement^="bottom"] {
    margin-top: 10px;
  }
  &[x-placement^="top"] {
    margin-bottom: 10px;
  }
  /* 全局滚动条样式统一 */
  ::-webkit-scrollbar {
    width: 4px; /*滚动条宽度*/
    height: 4px; /*滚动条高度*/
  }
  /*定义滚动条轨道 内阴影+圆角*/
  ::-webkit-scrollbar-track {
    border-radius: 4px; /*滚动条的背景区域的圆角*/
  }
  /*定义滑块 内阴影+圆角*/
  ::-webkit-scrollbar-thumb {
    border-radius: 4px; /*滚动条的圆角*/
    background-color: #eee; /*滚动条的背景颜色*/
  }
}
</style>
<style scoped lang="scss">
/* 最外层容器 */
.select-place-template{
  --sp-input-color: inherit;
  --sp-input-font-size: 18px;
  --sp-input-font-weight: 600;
  --sp-input-placeholder-color: #999;
  --sp-input-placeholder-font-weight: 600;
  //width: 100%;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;

  /* 默认样式 */
  width: 200px;
  max-width: 100%;
  height: 50px;
  max-height: 100%;
  background-color: transparent;
  //background-color: #F5F5F5;
  border-radius: 10px;
  :deep(.el-input__inner){
    height: inherit;
    line-height: inherit;
  }
  &,
  * {
    box-sizing: border-box;
  }
  &:not(.is-disabled){
    &:hover{
      .btn-delete{
        display: flex;
      }
      //.search-text{
      //  :deep(input){
      //    padding-right: 0;
      //  }
      //  &:not([class~="is-input"]){
      //    padding-right: 0;
      //  }
      //}
      //.value-text{
      //  right: 30px;
      //}
    }
  }
}
.input-box{
  flex: 1;
  position: relative;
  color: var(--sp-input-color);
}

.btn-delete{
  position: relative;
  z-index: 1;
  min-width: 30px;
  height: 30px;
  font-size: 14px;
  display: none;
  //display: flex;
  align-items: center;
  justify-content: center;
  .icon{
    cursor: pointer;
    color: #c0c4cc;
    &:hover{
      color: #909399;
    }
  }
}

/* 搜索框 */
.search-text{
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  font-size: var(--sp-input-font-size);
  font-weight: var(--sp-input-font-weight);
  &,
  :deep(.el-input__wrapper),
  :deep(input)
  {
    box-sizing: border-box;
    color: inherit;
    background-color: transparent;
    box-shadow: none;
  }
  :deep(.el-input__wrapper){
    padding: 0;
  }
  //&,
  //:deep(input) {
  //  background-color: transparent !important;
  //}
  :deep(input){
    min-width: 0;
    width: 100%;
    height: 100%;
    border: none;
    font-weight: inherit;
  }
  ::-webkit-input-placeholder {
    font-weight: var(--sp-input-placeholder-font-weight);
    color: var(--sp-input-placeholder-color);
  }
  &:not([class~="is-input"]){
    display: flex;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 15px;
    cursor: pointer;
    color: #999;
  }
  &.is-clear{
    //:deep(input){
    //  padding-right: 0;
    //}
    //&:not([class~="is-input"]){
    //  padding-right: 0;
    //}
  }
}

/* 选中值回显文本 */
.value-text{
  position: absolute;
  z-index: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  transition: opacity 0.4s ease;
  font-size: var(--sp-input-font-size);
  font-weight: var(--sp-input-font-weight);
  //&.is-clear{
  //  right: 30px;
  //}
}
.opacity-0{
  opacity: 0;
}
.opacity-04{
  opacity: 0.4;
}
.text-row{
  //padding: 0 11px;
  //font-size: 16px;
  //color: inherit;
  line-height: 30px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.is-default-style{
  &.select-popover{

  }
  .search-text{
    //color: #333333;
  }
}
.is-left-padding{
  .text-row,
  .search-text{
    padding-left: 15px;
    //  :deep(.el-input__wrapper){
    //    padding-left: 15px;
    //    padding-right: 15px;
    //  }
  }
}
.is-white-style{
  --sp-input-color:white;
}

/* 弹出框内容 */
.alter-place-bpx{
  //padding: 14px;
  //font-size: 16px;
}
.groups-type-wrap{
  overflow: hidden;
  margin-bottom: 10px;
}
.groups-type{
  display: flex;
  align-items: center;
  border-bottom: 1px solid #F5F5F5;
  &:not([class~="align-left"]){
    justify-content: space-between;
  }
  &__item{
    .align-left & {
      margin-right: 10px;
    }
    white-space: nowrap;
    line-height: 28px;
    //font-size: 16px;
    text-align: center;
    cursor: pointer;
    position: relative;
    &.is-undefined{
      display: none !important;
    }
    &.is-active{
      color: var(--el-color-primary);
      font-weight: 600;
      &:before{
        content: "";
        position: absolute;
        z-index: 1;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 2px;
        background-color: var(--el-color-primary);
      }
    }
  }
}
.groups-list-wrap{
  height: 200px;
  margin-right: -14px;
  overflow: hidden;
  position: relative;
}
.groups-list{

}
.groups-li{
  display: flex;
  align-items: flex-start;
  &__left{
    box-sizing: border-box;
    width: 50px;
    color: #333;
    font-weight: 600;
    margin-bottom: 10px;
    padding-top: 7px;
    line-height: 20px;
    min-height: 27px;
    font-size: 14px;
    text-align: left;
    //overflow: hidden;
    //text-overflow: ellipsis;
    //white-space: nowrap;
  }
  &__right{
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
  }
  &__item{
    width: 60px;
    padding: 0 6px;
    background: #f5f5f5;
    margin-right: 10px;
    margin-bottom: 6px;
    border-radius: 20px;
    color: #666;
    cursor: pointer;
    min-width: 60px;
    height: 34px;
    line-height: 34px;
    text-align: center;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &:not(.is-active):not(.is-disabled):hover{
      color:var(--el-color-primary);
      background-color: var(--el-color-primary-light-9);
    }
    &.is-active{
      background: var(--el-color-primary);
      color: white;
    }
    &.is-disabled{
      cursor: not-allowed;
      color: #bcbec2;
      background-color: #f4f4f5;
      border-color: #e9e9eb;
    }
  }
}

.footer-row{
  display: flex;
  justify-content: flex-end;
  margin-top: 14px;
  .btn{
    width: 123px;
    border-radius: 5px;
  }
  .btn-cancel{
    &:not(:hover){
      color: #999999;
      background-color: #EEEEEE;
      border-color: #EEEEEE;
    }
  }
}

</style>

