<template>
  <div class="goods-list-box">
    <div v-if="loading">
      <i class="el-icon-loading"></i> {{loadingText}}
    </div>
    <template v-else>
      <div class="goods-type-level1" v-if="oneList.length">
        <ul>
          <li v-for="(item, i) in oneList" :key="i" :class="{'is-active': oneValue && oneValue.value === item.value}" @click="selectOneItem(item)">
            <div class="label">{{ item.label }}</div>
            <el-icon class="el-icon-arrow-right"><ArrowRightBold /></el-icon>
          </li>
        </ul>
      </div>
      <div class="goods-type-level1" v-else>
        <p>抱歉，暂无数据</p>
      </div>
      <div class="goods-type-level2" v-if="oneValue && oneValue.value">
        <ul v-if="twoList.length">
          <li v-for="(item, i) in twoList" :key="i" :class="{'is-active': twoValue && twoValue.value === item.id}" @click="selectTwoItem(item)">
            <div>
              <div class="label">{{ item.label }}</div>
              <p class="price-info">加收 {{ item.price || 0 }} {{ item.currency || 'CNY' }}/{{item.billingUnit || 'KG'}}</p>
            </div>
            <el-icon class="el-icon-arrow-right"><ArrowRightBold /></el-icon>
          </li>
        </ul>
        <p v-else>抱歉，暂无数据</p>
      </div>
      <div class="goods-type-level3" v-if="oneValue && oneValue.value && twoValue && twoValue.value">
        <el-input v-model="searchInfo" placeholder="请输入商品名称搜索" @input="searchInfoChange"/>
        <div class="product-wrap">
          <template v-if="searchInfo">
            <div v-if="searchInfoLoading">
              <p >正在搜索</p>
            </div>
            <ul v-else-if="searchInfoFilerList.length">
              <li :title="item.label" v-for="item in searchInfoFilerList" :key="item.id" :class="{'is-active': threeValue && threeValue.value === item.id }" @click="selectThreeItem(item, '1')">{{ item.label }}</li>
            </ul>
            <div v-else>
              <p >抱歉，暂未找到相关产品品名</p>
            </div>
          </template>
          <ul v-else-if="threeList.length">
            <li :title="item.label" v-for="item in threeList" :key="item.id" :class="{'is-active': threeValue && threeValue.value === item.id}" @click="selectThreeItem(item, '1')">{{ item.label }}</li>
          </ul>
          <div v-else>
            <p >抱歉，暂未找到相关产品品名</p>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script setup>
const emit = defineEmits(["change","update:currentValue"])
const props = defineProps({
  loading:Boolean,
  loadingText:{
    type:String,
    default:"正在加载中...",
  },
  // 一级列表
  data:{
    type:Array,
    default:()=>[],
  },
  currentValue:{
    type:Object,
    default:()=>{},
  },
  searchCallback:Function,
})
watch(
  ()=>props.data,
  newValue=>{
    initOneList();
  }
)
watch(
  ()=>props.currentValue,
  newValue=>{
    threeValue.value = newValue;
  }
)
onMounted(()=>{
  if(props.data.length){
    initOneList();
  }
  if(props.currentValue){
    threeValue.value = props.currentValue;
  }
})


function initOneList(){
  let _oneValue = {};
  let _twoValue = {};
  if(props.data.length){
    _oneValue = props.data[0];
    if(isArray(_oneValue.children) && _oneValue.children.length){
      _twoValue = _oneValue.children[0];
    }
  }
  oneValue.value = _oneValue;
  twoValue.value = _twoValue;
}

function selectOneItem(item,index) {
  oneValue.value = item;
  twoValue.value = {};
  threeValue.value = {};
}
function selectTwoItem(item,index) {
  twoValue.value = item;
  threeValue.value = {};
}
function selectThreeItem(item,index) {
  threeValue.value = item;
  emit("update:currentValue",item);
  emit("change",item);
}
let oneList = computed(()=>props.data);
let oneValue = ref({});
let twoList = computed(()=>oneValue.value.children||[]);
let twoValue = ref({});
let threeList = computed(()=>twoValue.value.children||[]);
let threeValue = ref({});

// 搜索文本，从三级列表中搜索
let searchInfo = ref("");

// 搜索输入框改变
function searchInfoChange(val) {
  searchInfoLoading.value = true;
  changeSearch(val)
}
let changeSearch = debounce(function(text){
  text = text.replace(/(^\s+)|(\s+$)/g,"");
  console.log(text);
  let baseList = unref(threeList);
  let filterList = [];
  if(text){
    filterList = searchList(text,baseList);
  }
  searchInfoFilerList.value = filterList;
  searchInfoLoading.value = false;
},200)
/**
 * 搜索函数
 * @param text  {string}  搜索文本
 * @param baseList  {array} tabItem对应的list数据
 * @returns {*[]}
 */
function searchList(text="",baseList=[]){
  let filterList = [];
  let hasCallback = typeof props.searchCallback === "function";
  let filterFunction = (text="",item)=>{
    // console.log("============",item[this.itemLabelKey])
    let itemLabel = String(item.label||"");
    let itemLabelE = String(item.labelE||"");
    let reg = RegExp(text,"ig");
    return reg.test(itemLabel) || reg.test(itemLabelE);
  };
  filterList = baseList.filter(item=>{
    return hasCallback ? props.searchCallback(text,item) : filterFunction(text,item);
  });
  return filterList;
}
// 结果结果列表
let searchInfoFilerList = ref([]);
let searchInfoLoading = ref(false);
</script>

<style scoped lang="scss">
.goods-list-box {
  display: flex;
  height: 100%;
  max-height: 300px;

  .goods-type-level1,
  .goods-type-level2,
  .goods-type-level3 {
    overflow-y: auto;
    &.is-disabled :deep(.el-input__wrapper),
    &.is-disabled :deep(input),
    :deep(.el-input__wrapper),
    :deep(input)
    {
      box-sizing: border-box;
      color: inherit;
      background-color: #F7F7F7;
      box-shadow: none;
    }
    ul{
      margin: 0;
      padding: 0;
      list-style-type: none;
    }
  }
  .goods-type-level1,
  .goods-type-level2 {
    padding: 0 4px;
    width: 160px;
    line-height: 30px;
    li {
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 4px 10px;
      cursor: pointer;
      min-height: 40px;
      &:hover {
        background: #F5F7FA;
      }
      &.is-active {
        background: #FFF1F2;
      }
      .label{
        font-size: 16px;
        line-height: 24px;
      }
      .price-info{
        color: #FF4D01;
        font-size: 12px;
        margin: 0;
        line-height: 20px;
      }
    }
  }
  .goods-type-level2,
  .goods-type-level3 {
    padding-left: 10px;
    border-left: 1px solid #efefef;
  }
  .goods-type-level3 {

    .product-wrap {
      max-width: 224px;
      padding: 10px 0;
      ul {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        li {
          padding: 3px 4px;
          width: calc(33% - 8px);
          line-height: 20px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          cursor: pointer;
          &.is-active {
            background-color: #FFF1F2;
          }
        }
      }
    }
  }
}
</style>