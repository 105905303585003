// 当需要请求原货代拼接口做某些测试时，请在接口前加上改文本
export const huoDaiPinTargetName = "/huodaipin";
/**
 *
 * @param target  {string}  要使用url模块解析的url字符串
 * @param changeOrigin  {boolean} 将主机标头的来源更改为目标URL
 * @return {{changeOrigin: boolean, target: any}}
 */
function getProxyRuleItem(target, changeOrigin = true) {
  let config = {
    target: target,
    changeOrigin: true,
    // secure:false,
  };
  return config;
}
export function getProxyConfig(env) {
  const node_env = env.VITE_USER_NODE_ENV;
  const openProxy = env.VITE_REQUEST_OPEN_PROXY;
  const beforePath = env.VITE_REQUEST_BEFORE_PATH;
  const timeout = env.VITE_REQUEST_OUT_TIME;
  if (!openProxy) return undefined;
  const proxyRules = {
    [huoDaiPinTargetName]: {
      target: "https://qa2.huodaipin.com.cn/",
      changeOrigin: true,
      rewrite: (path) => path.replace(huoDaiPinTargetName, ""),
    },
    "/api/": getProxyRuleItem(beforePath),
    "/oms/": getProxyRuleItem(beforePath),
    "/fina/": getProxyRuleItem(beforePath),
  };
  return proxyRules;
}
