import {isArray, isObject} from "@/utils/utils";
import {getLocalLanguage} from "@/language";
import {debounce} from "@/utils/utils.debounce";
import {useUserStore} from "@/stores/user";
import {useRoute, useRouter} from "vue-router";
import {ElMessageBox} from "element-plus";
import {busShowLoginPopover} from "@/utils/bus";
import router from "@/router";

/**
 * 判断指定路由是否需要进行某项检查
 * @param route 指定路由，例如 to/from
 * @param key {string}  要进行的检查key,例如login,permission
 * @return {boolean|*}
 */
export function isNeedCheckRouteProperty(route,key="login"){
  let hasKey = !!key && typeof key === "string";
  if(!hasKey) return false;
  let check = getRouteMeta(route,"check");
  if(isArray(check)){
    return check.includes(key);
  }else if(typeof check === "string"){
    return check === key;
  }
}

/**
 * 获取指定路由的meta属性，并可以指定一个key,直接返回meta[key]
 * @param route 指定路由，例如 to/from
 * @param key {string?}  指定key
 * @return {undefined|*}
 */
export function getRouteMeta(route,key){
  let isValid = isObject(route) && isObject(route.meta);
  let hasKey = !!key && typeof key === "string";
  if(isValid){
    let meta = route.meta;
    if(hasKey){
      return meta[key];
    }else{
      return meta;
    }
  }else{
    return undefined;
  }
}
/**
 * 根据路由注册参数，获取页面 title
 * @param t
 * @return {string}
 */
export function getRouterMetaTitle(t){
  let title= "";
  if(isObject(t) && isObject(t.meta)) {
    let meta = t.meta;
    let titleConfig = t.meta.title;
    if(isObject(titleConfig)){
      let lang = getLocalLanguage();
      title = titleConfig[lang] || titleConfig["zh"] || "";
    }else if(typeof titleConfig === "string"){
      title = titleConfig;
    }
  }
  else if(typeof t === "string" && t) title = t;
  return title
}
/**
 * 是否是登录页
 * @param path {string} 当前页面路径  router.currentRoute.path route.path
 * @returns {boolean}
 */
export function isLoginPage(path){
  if(typeof path !== "string") path = "";
  return ["/login","login"].includes(path.toLowerCase());
}
/**
 * 跳转登录页
 */
export function goLoginPage() {
  let route = unref(router.currentRoute);
  if(!isLoginPage(route.path)){
    router.push({
      // path:"/login",
      path:"/",
    })
  }
}

/**
 * 跳转登录页之前，自动合并"当前页面"或者"登录后需要跳转页面"的参数
 * @param route {{path:string,params:object,query:object}|*}  当前路由或指定路由，例如 to/from
 * @param params
 * @return {any}
 */
export function mergeCurrentPagePathParams(route ,...params){
  if(!isObject(route)) route = {};
  return Object.assign({
    path:route.path,
    params:route.params,
    query:route.query,
  },...params);
}

/**
 * 跳转登录页之前 保存登录后需要跳转的参数
 * @param params {string | {path,name,params,query}}  path 或者 {path,name,params,query}
 */
/**
 *
 * @param params {string | {path,name,params,query}}  登录后需要跳转的路由参数
 */
export function setParamsBeforeTheJumpLogin(params){
  if(isPath(params)){
    params = {path:params};
  }else if(!isObject(params)){
    params = {};
  }
  sessionStorage.setItem("login_after_params",JSON.stringify(params));
}
/**
 * 移除 跳转登录页之前 保存的参数
 */
export function removeParamsBeforeTheJumpLogin(){
  sessionStorage.removeItem("login_after_params");
}
/**
 * 获取 跳转登录页之前 保存的参数
 * @returns {{path?, name?, query?, params?}|undefined} 保存的跳转数据
 */
export function getParamsBeforeTheJumpLogin(){
  let str = sessionStorage.getItem("login_after_params");
  let data = undefined;
  if(str){
    try {
      data = JSON.parse(str);
    } catch (e) {
      console.error(new Error("解析登录前数据失败：" + e.message ))
    }
  }
  return data
}

/**
 * 是否是路由path
 * @param path {string}
 * @return {boolean}
 */
export function isPath(path) {
  return !!path && typeof path === "string" && /^\//.test(path);
}
/**
 * 主动弹出需要登录提示弹窗
 * @type {(function())|(function(): void)}
 */
export const hintLogin = debounce(function (){
  let userStore = useUserStore();
  let route = unref(router.currentRoute);
  ElMessageBox.confirm('您还未登录或登录信息已失效,请重新登录',"登录提示",{
    closeOnClickModal:false,
  })
    .then(()=>{
      userStore.clearUserLoginInfo();
      if(checkLoginTimeoutNeedGoLoginPage(route.path)){
        let data = mergeCurrentPagePathParams(route);
        setParamsBeforeTheJumpLogin(data);
        goLoginPage();
        setTimeout(()=>{
          busShowLoginPopover();
        },500)
      }else{
        busShowLoginPopover();
      }
    })
    .catch(()=>{
      userStore.clearUserLoginInfo();
      removeParamsBeforeTheJumpLogin();
      if(checkLoginTimeoutNeedGoLoginPage(route.path)){
        goLoginPage();
      }
    })
},500,false);

/**
 * 检查登录超时时，当前route.path是否需要跳转登录页
 * @param routePath
 */
export function checkLoginTimeoutNeedGoLoginPage(routePath){
  let notList = [
    "/",
    "/productList",
    "/Trajectory",
    "/userGuide",
    "/companyIntroduction",
  ];
  let needGoLogin = !notList.includes(routePath);
  if(needGoLogin) console.warn(`当前路由【${routePath}】需要跳转登录页`);
  return needGoLogin;
}
