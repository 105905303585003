<template>
  <g-wrap>
    <div class="cooperative-partner">
      <cooperative-partner-scroll-row class="scroll-row" :limitMoveNum="6" :list="list1" @clickItem="clickItem" v-if="list1.length"/>
      <cooperative-partner-scroll-row class="scroll-row" :limitMoveNum="6" :list="list2" @clickItem="clickItem" v-if="list2.length"/>
      <cooperative-partner-scroll-row class="scroll-row" :limitMoveNum="6" :list="list3" @clickItem="clickItem" v-if="list3.length"/>
    </div>
  </g-wrap>
</template>

<script setup>
import GWrap from "@/components/gWrap/gWrap.vue";
import CooperativePartnerScrollRow from "@/views/home/components/cooperativePartner/cooperativePartnerScrollRow.vue";
import {ElMessage} from "element-plus";

const props = defineProps({
  list: {
    type: Array,
    default: () => [],
  },
})
const list1 = ref([]);
const list2 = ref([]);
const list3 = ref([]);

watch(()=>props.list,(newValue,oldValue)=>{
  console.log("watch 合作伙伴列表",newValue.length,newValue,oldValue);
  initData(newValue);
},{immediate:false,deep:false})

onMounted(()=>{
  if(isArray(props.list) && props.list.length){
    initData(props.list);
  }
})

function initData(newValue){
  let arr = disposeList(newValue);
  console.log("合作伙伴分组",arr);
  list1.value = arr[0]||[];
  list2.value = arr[1]||[];
  list3.value = arr[2]||[];
}
/**
 * 处理合作伙伴列表
 * @param list
 * @return {*[]}
 */
function disposeList(list=[]) {
  list = list.map(item=>item);
  let len = 6;
  let maxRow = 3;
  let arr = [];
  // 满足多少行
  let row = Math.floor(list.length / len);
  // 如果不满足一行但又有值
  if(row === 0 && list.length > 0) row = 1;
  if(row > maxRow) row = maxRow;
  // 单行多少个
  let singleRowNumber = Math.floor(list.length/row);

  // 剩余几个
  let residue = list.length - (singleRowNumber * row);
  for(let i = 0;i<row;i++){
    let n = singleRowNumber;
    if(residue>0){
      n ++;
      residue --;
    }
    arr[i] = list.splice(0,n);
  }
  return arr;
}

/**
 * 点击回调
 * @param item
 * @param index
 */
function clickItem(item,index) {
  // console.log("回调",item,index);
  // ElMessage.info("点击"+item);
}
</script>

<style scoped lang="scss">
.cooperative-partner{
  position: relative;
  z-index: 1;
  padding-bottom: 90px;
  &:before {
    content: "";
    position: absolute;
    height: 100%;
    box-shadow: 100px 0px 53px inset #fff;
    left: 0;
    top: 0;
    bottom: 0;
    width: 200px;
    z-index: 10;
  }
  &:after {
    content: "";
    position: absolute;
    height: 100%;
    box-shadow: -100px 0px 53px inset #fff;
    right: 0;
    top: 0;
    bottom: 0;
    width: 200px;
    z-index: 10;
  }
}
.scroll-row{
  & + &{
    margin-top: 40px;
  }
}
</style>