import { ref, computed, reactive } from 'vue'
import { defineStore } from 'pinia'
import {
  $t,
  checkHasLanguage,
  defaultLang,
  loadLocaleMessages,
  setI18nLanguage,
  setLocalLanguage
} from "@/language";
import axiosMain from "@/axios";
import { getTenantId, requestTenantId } from "@/utils/utils.commonTenantParams";
import { ElMessage } from "element-plus";
import { getRouterMetaTitle } from "@/utils/utils.router";
import { alterWebPrimaryColor, setLocalPrimaryColor } from "@/utils/utils.system";
import { getUserPagePermissionList } from "@/api/modules/user";
import { useUserStore } from "@/stores";
import router from "@/router";

/**
 * 将 公共底部栏信息 保存在浏览器本地缓存
 * @param params
 */
export function setLocalPublicFooterInfo(params) {
  let str = ""
  if (isObject(params)) {
    try {
      str = JSON.stringify(params);
    } catch (e) {
      console.error(e);
    }
  }
  localStorage.setItem("public_footer_info", str);
}
/**
 * 从浏览器本地缓存获取 公共底部栏信息
 * @param key
 */
export function getLocalPublicFooterInfo(key) {
  let info = null;
  try {
    info = localStorage.getItem('public_footer_info');
    if (info) info = JSON.parse(info);
  } catch (e) {
    console.error(e);
  }
  if (!isObject(info)) info = {};
  if (!(typeof key === 'string' && key)) return info;
  return info[key];
}

/**
 * 设置web tab title
 * @param title
 */
function setWebHeaderTitle(title = "") {
  if (title) document.title = title;
}

export const useSystemStore = defineStore('system', () => {
  // 租户oms系统配置
  let systemConfig = ref({
    // OMS允许欠费下单
    "omsAllowCreditOrder": false,
    // OMS允许订单预估运费为0下单
    "omsAllowZeroFeeOrder": false,
    // OMS运单客户单号是否必填
    "omsCustomerNosRequired": true,
    // OMS非登录状态下是否展示公布价
    "omsOpenShowQuotation": false,
    // KG保留位数
    "decimalKgScale": 3,
    // KG进位规则 1 四舍五入 2 向前进1 3 向下取整
    "decimalKgScaleType": 2,
    // CM保留位数
    "decimalCmScale": 3,
    // CM进位规则 1 四舍五入 2 向前进1 3 向下取整
    "decimalCmScaleType": 2,
    // CBM保留位数
    "decimalCbmScale": 3,
    // CBM进位规则 1 四舍五入 2 向前进1 3 向下取整
    "decimalCbmScaleType": 2,
    // 金额保留位数
    "decimalAmountScale": 3,
    // 金额进位规则 1 四舍五入 2 向前进1 3 向下取整
    "decimalAmountScaleType": 2,
    // 判断客户是否有配置小程序
    "miniProgramUrlFlag": false
  });
  // 网站底部信息
  let webFooterInfo = ref(getLocalPublicFooterInfo() || {
    "id": 0,
    // ERP网站名称
    "erpWebsiteName": "",
    // OMS网站名称
    "omsWebsiteName": "",
    // ERP首页背景图
    "erpIndexBgImgUrl": "",
    // ERP首页登陆区域背景图
    "erpLoginAreaBgImgUrl": "",
    // ERP的系统名称
    "erpSystemName": "",
    // ERP系统登录页logo
    "erpLoginLogoUrl": "",
    // ERP和OMS左上角的logo
    "websiteLogoUrl": "",
    // OMS左上角的logo
    "omsWebsiteLogoUrl": "",
    // OMS密码强度设置 -1 不限制 1 字母+数字 2 大小写字母 + 数字 3 大小写字母 + 数字 + 特殊字符,可用值:-1,1,2,3
    "omsPwdSetType": "",
    // ERP密码强度设置 -1 不限制 1 字母+数字 2 大小写字母 + 数字 3 大小写字母 + 数字 + 特殊字符s,可用值:-1,1,2,3
    "erpPwdSetType": "",
    // 主题色
    "themeColor": "",
    // 网站语言
    "websiteLanguage": "",
    // 微信公众号二维码URL
    "weChatQrCode": "",
    // 微信小程序二维码URL
    "weChatAppletQrCode": "",
    // 网站对应的主体
    "websitePrincipal": "",
    // 网站备案号
    "filingNumber": "",
    // 备案号连接
    "filingNumberUrl": "",
    // 网站上显示的联系人(公司名称)
    "contacts": "XXXXXXXXX货代公司",
    // 网站上显示的联系电话
    "telephone": "152XXXX2323",
    // 网站上显示的联系地址
    "address": "广东省深圳市XXXXXXXX号",
    // 客服二维码URL
    "serviceQrCode": "",
    // OMS系统名称
    "omsSystemName": "",
  });
  // 当前语言
  const language = ref(defaultLang);
  // 设置/切换语言
  async function setLanguage(value) {
    console.log(`useSystemStore setLanguage(${value})`)
    if (!checkHasLanguage(value)) {
      console.log(`未预加载${value}语言包，等待加载中...`);
      await loadLocaleMessages(value);
    }
    setLocalLanguage(value);
    setI18nLanguage(value);
    language.value = value;
  }

  // 设置 网站底部信息
  function setWebFooterInfo(value) {
    if (!isObject(value)) value = {};
    setLocalPublicFooterInfo(value);
    webFooterInfo.value = value;
  }
  // 设置 租户oms系统配置
  function setSystemConfig(value) {
    if (!isObject(value)) value = {};
    systemConfig.value = value;
    const userStore = useUserStore()
    if (!value.uncheckedLoginFlag && userStore.userInfo?.checkStatus !== 1) {
      userStore.clearUserLoginInfo();
      router.push({
        path:"/",
      })
    }
  }

  // 是否需要初始化 公共配置信息
  let needInitCommonInfo = true;
  /**
   * 自动获取 网站底部信息
   * @param compelGet {boolean} 是否强制重新请求
   * @return {Promise<T|{}|MessageHandler|*>}
   */
  async function autoGetWebFooterInfo(compelGet = false) {
    setWebHeaderTitle(unref(webFooterInfo).omsWebsiteName);
    if (needInitCommonInfo || compelGet) {
      needInitCommonInfo = false;
      let loginResult = await getPublicFooterInfo();
      console.log("loginResult", loginResult)
      if (loginResult && loginResult.returnCode === "200") {
        let data = loginResult.data || {};
        setWebHeaderTitle(data.omsWebsiteName)
        setLocalPrimaryColor(data.themeColor || "");
        setWebFooterInfo(data);
        // 解决备案号不显示时，物流商城未查询报价，footer未紧贴底部的问题
        const body = document.querySelector('body')
        if (data.filingNumberFlag) {
          body.style.setProperty('--filing-height', '94px')
        } else {
          body.style.setProperty('--filing-height', '0px')
        }
        return data;
      }

      // if(loginResult && loginResult.returnCode === "700"){
      // 因租户配置不可用提示已在左侧弹出，所以不用提示
      // }else{
      ElMessage.error(loginResult.returnMsg || loginResult.message || $t("login.systemException"));
      // }
    }
    return unref(webFooterInfo);
  }
  // 是否需要初始化 租户oms系统配置
  let needInitSystemConfig = true;
  async function autoGetSystemConfig(compelGet = false) {
    if (needInitSystemConfig || compelGet) {
      needInitSystemConfig = false;
      let loginResult = await getSystemConfig();
      if (loginResult && loginResult.returnCode === "200") {
        let data = loginResult.data || {};
        setSystemConfig(data);
        return data;
      }
      ElMessage.error(loginResult.returnMsg || loginResult.message || $t("login.systemException"));
    }
    return unref(systemConfig);
  }
  return {
    language,
    setLanguage,
    webFooterInfo,
    setWebFooterInfo,
    autoGetWebFooterInfo,
    systemConfig,
    setSystemConfig,
    autoGetSystemConfig,
  }
})

let footerInfoBeforePromise;
let footerInfoLoad = false;
/**
 * 获取 公共底部栏信息
 * @return {Promise<AxiosResponse<any>>}
 */
async function getPublicFooterInfo() {
  if (footerInfoLoad) return footerInfoBeforePromise;
  let tenantId = getTenantId();
  if (!tenantId) {
    await requestTenantId()
    tenantId = getTenantId()
  }
  // if(!tenantId){
  // return {"returnCode": "700", "returnMsg": "配置未完善，系统不可用"};
  // }
  let url = `/oms/data/publicBottomColumn/${tenantId}`
  let data = {};
  footerInfoLoad = true;
  return footerInfoBeforePromise = axiosMain.get(url)
    .then(res => {
      footerInfoLoad = false;
      return res;
    })
    .catch(res => {
      footerInfoLoad = false;
      return res;
    })
}

let systemConfigBeforePromise;
let systemConfigLoad = false;
/**
 * 获取 租户oms系统配置
 * @return {Promise<AxiosResponse<any>>}
 */
function getSystemConfig() {
  if (systemConfigLoad) return systemConfigBeforePromise;
  const tenantId = getTenantId();
  let url = `/oms/sysConfig/${tenantId}`
  let data = {};
  systemConfigLoad = true;
  return systemConfigBeforePromise = axiosMain.get(url)
    .then(res => {
      systemConfigLoad = false;
      return res;
    })
    .catch(res => {
      systemConfigLoad = false;
      return res;
    })
}
