/**
 * 使用登录和注册逻辑
 */
export function useLoginAndRegister() {}

// 获取租户oms协议
export function omsAgreement(data){
    let url = `/oms/agreement/${data.tenantId}/${data.agreementType}`
    return axiosMain.get(url)
}
