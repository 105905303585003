<template>
  <div class="productDetails">
    <div
      class="details-check"
      @click.stop=""
      @mouseenter="dialogShow.data.showDialog = true"
      @mouseleave="
        dialogShow.data.showDialog = false;
        dialogShow.data.clientX = '';
        dialogShow.data.clientY = '';
      "
      v-if="dialogShow.data.showDialog && isBlurring"
      :style="{ top: dialogShow.data.clientY, left: dialogShow.data.clientX }"
    >
      <!-- <img src="../../assets/images/register.png" alt="" />
      <div class="title">登录即可查看运价</div>
      <div
        class="btn"
        @click="
          dialogShow.data.showDialog = false;
          busShowLoginPopover();
        "
      >
        去登录
      </div> -->
      <template v-if="bluringType === 'login'">
        <img src="../../assets/images/register.png" alt="" />
        <div class="title">登录即可查看运价</div>
        <div
          class="btn"
          @click="
            dialogShow.data.showDialog = false;
            busShowLoginPopover();
          "
        >
          去登录
        </div>
      </template>
      <template v-else>
        <img :src="ossPath + '/common/unapproval-ico.png'" alt="">
        <div class="title">审核通过即可查看运价</div>
      </template>
    </div>
    <el-drawer
      v-model="visibleDrawer"
      :show-close="false"
      :size="width"
      style="max-width: 100%"
      @open="handleOpen"
      @opened="handleOpened"
      @close="handleClose"
      @closed="handleClosed"
      :with-header="false"
      class="product-details"
    >
      <!--    <template #header="{ close, titleId, titleClass }"></template>-->
      <div
        class="header-row"
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 18px;
          background-color: #fff;
        "
      >
        <div class="product-title" style="color: #333333; font-weight: bold">
          方案详情
        </div>
        <el-icon class="close-btn" @click="visibleDrawer = false">
          <CircleCloseFilled />
        </el-icon>
      </div>
      <div class="padding-20">
        <div class="detail-box2">
          <div
            style="display: flex; align-items: center"
            class="header-row m-b-16"
          >
            <img v-if="productDetails.preferentialFlag && !productDetails.needInquiryFlag" class="charge-price-ico" :src="ossPath + '/common/charge-price-l.png'" />
            <div class="icon-box">
              <i :class="transportTypeIcon"></i>
            </div>
            <span class="start-city">{{ startCityName || "-" }}</span>
            <el-icon class="space-icon">
              <Right />
            </el-icon>
            <span class="end-destination"
              >{{ endCountryName || "-" }}({{ endWarehouseName || "-" }})</span
            >
            <div class="flex-1"></div>
            <el-button
              class="place-order-btn"
              type="primary"
              @click="clickPlaceOrder"
              >立即下单</el-button
            >
          </div>
          <div class="header-row m-b-10">
            <div class="header-title text-line-2">
              <span>
                {{ productDetails.productName }}
              </span>
              <span class="isContainsTheTariffs">{{
                productDetails.containsTheTariffs ? "包税" : "不包税"
              }}</span>
            </div>
            <div class="time">
              报价有效时间：{{
                productDetails.effectiveStartTimeText ||
                productDetails.effectiveStartTime
              }}~{{
                productDetails.effectiveEndTimeText ||
                productDetails.effectiveEndTime
              }}
            </div>
          </div>
          <div class="product-info">
            <div class="product-info--item">
              <div class="value">{{ productDetails.goodsType || "-" }}</div>
              <div class="label">可接货物</div>
            </div>

            <div class="product-info--item">
              <div class="value">
                {{ productDetails.containsTheTariffs ? "包税" : "不包税" }}
              </div>
              <div class="label">是否包税</div>
            </div>

            <div class="product-info--item">
              <div class="value">{{ productDetails.chargeType || "-" }}</div>
              <div class="label">计费单位</div>
            </div>

            <div class="product-info--item">
              <div class="value">
                {{
                  productDetails.chargeType === "CBM"
                    ? "实际CBM和转换CBM取大值"
                    : commonPointsBubbleWayMapObject[
                        productDetails.pointsBubbleWay
                      ] ||
                      productDetails.pointsBubbleWay ||
                      "-"
                }}
              </div>
              <div class="label">
                计费规则
                <el-tooltip placement="top" content="" v-if="chargeableTips">
                  <template #content>
                    <div style="max-width: 300px">{{ chargeableTips }}</div>
                  </template>
                  <span>
                    <el-icon color="#999999" :size="14">
                      <QuestionFilled />
                    </el-icon>
                  </span>
                </el-tooltip>
              </div>
            </div>

            <template v-if="true">
              <div class="product-info--item">
                <!-- <div class="value" v-if="productDetails.chargeType === 'KG'">{{ productDetails.volumeWeightRatio ?
              productDetails.volumeWeightRatio : '-' }}</div>
            <div class="value" v-else-if="productDetails.chargeType === 'CBM'">{{
              productDetails.weightVolumeRatio ? productDetails.weightVolumeRatio : '-' }}</div>
            <div class="value" v-else>{{ "-" }}</div> -->
                <div class="value">
                  {{ productDetails.volumeWeightRatio || "-" }}
                </div>
                <div class="label">
                  转换系数
                  <el-tooltip placement="top" content="">
                    <template #content>
                      <div style="max-width: 300px">{{ conversionTip }}</div>
                    </template>
                    <span
                      ><el-icon color="#999999" :size="14">
                        <QuestionFilled /> </el-icon
                    ></span>
                  </el-tooltip>
                </div>
              </div>
            </template>

            <div class="product-info--item">
              <div class="value">
                {{
                  commonDeliveryTypeMapObject[productDetails.endDelivery] ||
                  productDetails.endDelivery ||
                  "-"
                }}
              </div>
              <div class="label">派送方式</div>
            </div>
          </div>
        </div>
        <div class="detail-box3">
          <div class="header-row m-b-16">
            <div class="header-title text-line-2">
              {{ isSegment ? "头程报价" : "全包报价" }}
            </div>
          </div>
          <simple-data-table
            :setChargePrice="productDetails.preferentialFlag ? {preferentialFlag: productDetails.preferentialFlag, preferentialRange: productDetails.preferentialRange} : {preferentialFlag : productDetails.preferentialFlag}"
            @mouseenterStuff="watchMouseEnter"
            @mouseleaveStuff="watchMouseLeave"
            class="no-common-style"
            :thead-data="productDetails.headThead"
            :table-data="productDetails.headPriceSectionList"
            quoteType="head"
          />
        </div>
        <div v-if="isSegment" class="detail-box4">
          <div class="header-row">
            <div class="header-title text-line-2 m-b-16">
              <span>派送报价</span>
              <span class="remark-tag" v-if="productDetails.pickupRemarks">{{
                productDetails.pickupRemarks
              }}</span>
            </div>
          </div>
          <simple-data-table
            @mouseenterStuff="watchMouseEnter"
            @mouseleaveStuff="watchMouseLeave"
            class="no-common-style"
            :thead-data="productDetails.deliveryThead"
            :table-data="productDetails.deliveryPriceSectionList"
            quoteType="delivery"
          />
        </div>

        <div class="detail-box5">
          <g-tabs2
            class="remark-tabs"
            :list="remarkTabs"
            v-model="remarkCurrent"
            @change="changeRemark"
          />
          <div class="remark-content" v-html="remarkContent"></div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script setup>
import SimpleDataTable from "@/components/simpleDataTable/simpleDataTable.vue";
import GTabs2 from "@/components/gTabs/gTabs2.vue";
import { toPlaceOrderPage } from "@/utils/utils.placeOrder";
import { busShowLoginPopover } from "@/utils/bus";
import {
  commonDeliveryTypeMapObject,
  commonPointsBubbleWayMapObject,
} from "@/utils/utils.type";
import { userPriceBlurring } from "@/components/priceBlurring/priceBlurring";
import { ElMessage } from "element-plus";
import { useUserStore } from "@/stores";
import {ossPath} from "@/../config/variables";

const userStore = useUserStore();
// 是否需要模糊价格
let { isBlurring, bluringType } = userPriceBlurring();
const emit = defineEmits(["update:visible"]);
const props = defineProps({
  visible: Boolean,
  width: {
    type: [String, Number],
    default: "1100px",
  },
  headerCellStyle: {
    type: Object,
    default: () => {
      return { background: "#F1F3F8", color: "#333333" };
    },
  },
  productDetails: {
    type: Object,
    default: () => {
      return {
        // headThead:[],
        // headPriceSectionList:[],
        // deliveryThead:[],
        // deliveryPriceSectionList:[],
      };
    },
  },
  // 物流产品类型
  productType: [String, Number],
  // 起运城市参数
  startCityName: String,
  startCityId: [String, Number],
  // 目的国家
  endCountryName: String,
  endCountryId: [String, Number],
  endCountryCode: String,
  privateAddressType: [String, Number],
  // 目的地（目的仓库）
  endWarehouseName: String,
  endWarehouseId: [String, Number],
  isPrivateAddress: Boolean,
});
// 弹窗
let visibleDrawer = ref(false);

// 控制弹窗位置与显隐
let dialogShow = reactive({
  data: {
    showDialog: false,
    clientX: "",
    clientY: "",
  },
});
// 根据不同的运输方式展示不同的icon
let transportTypeIcon = ref("");
watch(
  () => props.productDetails.transportType,
  (newVal) => {
    if (newVal === "LY") {
      transportTypeIcon.value = "iconfont m-icon-cart";
    } else if (newVal === "HY") {
      transportTypeIcon.value = "iconfont m-icon-steamer";
    } else if (newVal === "KY") {
      transportTypeIcon.value = "iconfont m-icon-aircraft";
    }
  }
);
/**
 * 监听鼠标移入事件
 * @param data 鼠标移入位置
 * */
function watchMouseEnter(data) {
  dialogShow.data.showDialog = true;
  dialogShow.data.clientX = data.pageX + "px";
  dialogShow.data.clientY = data.pageY - 866 + "px";
}

/**
 * 监听鼠标移出事件
 * @param data 鼠标移出位置
 * */
function watchMouseLeave(data) {
  dialogShow.data.showDialog = false;
}

watch(
  () => props.visible,
  (newVal) => {
    visibleDrawer.value = newVal;
  },
  { immediate: true }
);
watch(
  () => visibleDrawer.value,
  (newVal) => {
    emit("update:visible", newVal);
    if (newVal) {
      nextTick(() => {
        changeRemark(remarkTabs.value[0].value);
      });
    }
  }
);
// 计费规则对应的提示
// let chargeableTips = ref('')
const chargeableTips = computed(() => {
  let tips = "";
  const pointsBubbleWay = props.productDetails.pointsBubbleWay;
  if (pointsBubbleWay === 1) {
    tips =
      "不需要判断实重和体积重的大小，计费重量直接取实重作为计费重量。例如：一票订单，实重是20，体积重是25时，该订单的计费重量是20KG";
  } else if (pointsBubbleWay === 2) {
    tips =
      "根据实重和体积重的对比，取最大值。例如：一票订单，实重是20，体积重是25时，该订单的计费重量是25KG";
  } else if (pointsBubbleWay === 3) {
    tips =
      "当体积重大于实重时，按分泡50%计算计费重量。例如：一票订单，实重是20，体积重是25时，该订单的计费重量是25-（25-20）*0.5=22.5KG";
  } else if (pointsBubbleWay === 4) {
    tips =
      "当体积重大于实重时，按分泡1/3计算计费重量。例如：一票订单，实重是20，体积重是25时，该订单的计费重量是25-（25-20）*1/3=23.333KG";
  } else if (pointsBubbleWay === 5) {
    tips =
      "当体积重大于实重时，按分泡30%计算计费重量。例如：一票订单，实重是20，体积重是25时，该订单的计费重量是25-（25-20）*0.3=23.5KG";
  } else if (pointsBubbleWay === 6) {
    tips =
      "计费重等于实重和体积重的和除以2。例如：一票订单，实重是20，体积重是25时，该订单的计费重量是（20+25）/2=22.5KG";
  }
  if (props.productDetails.chargeType === "CBM") {
    tips =
      "根据体积和重量体积的对比，取最大值。例如：一票订单，体积是1.5，重量体积是2时，该订单的计费体积是2CBM";
  }
  return tips;
});
// 转换系数对应的提示
let conversionTip = ref("");
watch(
  () => props.productDetails.chargeType,
  (newVal) => {
    if (newVal === "KG") {
      conversionTip.value =
        "体积重比率用于计算体积重，体积重=长*宽*高/体积重比率。例如:100CM*100CM*100CM规格的箱子，若体积重比率=6000，则体积重=100*100*100/6000≈167KG";
    } else if (newVal === "CBM") {
      conversionTip.value =
        "重量转换系数用于计算重量体积，重量体积=实重/重量转换系数。例如:一票订单实重300KG，若重量转换系数=200，则重量体积=300/200=1.5CBM";
    }
  }
);
// 是否是分段  true-分段  false-全包
let isSegment = computed(() => {
  let list = props.productDetails.deliveryPriceSectionList;
  return isArray(list) && list.length;
});
// Drawer 打开的回调
function handleOpen() {}
// Drawer 打开动画结束时的回调
function handleOpened() {}

// Drawer 关闭的回调
function handleClose() {}
// Drawer 关闭动画结束时的回调
function handleClosed() {
  remarkCurrent.value = remarkTabs.value[0].value;
}

// 全包/头程报价 表头
let headQuotationHeadList = ref([
  { label: "目的分区", prop: "name1" },
  { label: "拆货城市", prop: "name2" },
  { label: "可走货物类型", prop: "name3", minWidth: "120px" },
  { label: "0-10KG", prop: "name4" },
  { label: "10-30KG", prop: "name5" },
  { label: "30-9999KG", prop: "name6" },
  { label: "币种", prop: "name7" },
  { label: "提取时效", prop: "name8" },
  { label: "整体时效", prop: "name9" },
]);
// 全包/头程报价
let headQuotationList = ref([
  {
    name1: "A区",
    name3: "带电/普货",
    name7: "CNY",
    name8: "6-10工作日",
    name9: "8-11工作日",
  },
  {
    name1: "B区",
    name3: "带电/普货/带磁",
    name7: "CNY",
    name8: "6-10工作日",
    name9: "8-11工作日",
  },
  {
    name1: "C区",
    name3: "带电/普货/敏货",
    name7: "CNY",
    name8: "6-10工作日",
    name9: "8-11工作日",
  },
  {
    name1: "D区",
    name3: "带电/普货/液体",
    name7: "US",
    name8: "6-10工作日",
    name9: "8-11工作日",
  },
  {
    name1: "E区",
    name3: "带电/普货",
    name7: "CNY",
    name8: "6-10工作日",
    name9: "8-11工作日",
  },
]);
// 派送报价 表头
let deliveryQuotationHeadList = ref([]);
// 派送报价
let deliveryQuotationList = ref([]);

// 备注tabs
let remarkTabs = ref([
  { label: "下单须知", value: "xdxz", prop: "orderInformation" },
  { label: "特殊要求", value: "txyq", prop: "specialRequirements" },
  { label: "温馨提示", value: "wxts", prop: "warmPrompt" },
  { label: "包装要求", value: "bzyq", prop: "packagingRequirements" },
  { label: "赔付标准", value: "pfbz", prop: "compensationStandard" },
  // {label:"附加费",value:"fjf",prop:"",},
]);
// 备注tabs current
let remarkCurrent = ref("xdxz");
// 备注 current 当前内容
let remarkContent = ref("");
// let remarkContent = `<i class="iconfont m-icon-book-fill"></i>This is drawer content.<div class="" style="background-color: #999999;height: 1000px;"></div>`;
function changeRemark(value) {
  let item = remarkTabs.value.find((item) => item.value === value);
  let text = "";
  if (item) {
    text = props.productDetails[item.prop] || "";
  }
  remarkContent.value = text;
}
/**
 * 立即下单
 */
function clickPlaceOrder() {

  if (props.productDetails.userEnableFlag === false) {
    return ElMessage.warning("用户已禁用");
  }

  if (!userStore.token) return busShowLoginPopover();
  if(userStore.userInfo?.checkStatus!==1 && !props.omsUncheckedCustomerOrderFlag){
    return ElMessage.warning("您的账户仍在审核中，如需下单请联系客服人员");
  }
  toPlaceOrderPage({
    // 物流产品类型
    productType: props.productType,
    // 起运城市参数
    startCityName: props.startCityName,
    startCityId: props.startCityId,
    // 目的国家
    endCountryName: props.endCountryName,
    endCountryId: props.endCountryId,
    endCountryCode: props.endCountryCode,
    privateAddressType: props.privateAddressType,
    // 目的地（目的仓库）
    endWarehouseName: props.endWarehouseName,
    endWarehouseId: props.endWarehouseId,
    isPrivateAddress: props.isPrivateAddress,
    // 产品报价id
    id: props.productDetails.id,
  });
}
</script>

<style lang="scss">
.product-details {
  .el-drawer__body {
    height: 100vh;
    overflow: hidden auto;
    box-sizing: border-box;
    padding: 0;
    background-color: #f6f6f6;
  }
}
</style>
<style scoped lang="scss">
.productDetails {
  position: relative;

  .product-title {
    font-size: 24px;
  }
  .details-check {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    position: absolute;
    top: 1077px;
    left: 399px;
    transform: translateX(-50%);
    // width: 154px;
    // height: 177px;
    width: 140px;
    min-height: 130px;
    background: #ffffff;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    z-index: 99999;
    cursor: default;

    img {
      width: 50px;
      height: 50px;
      object-fit: contain;
    }

    .title {
      font-size: 14px;
      // font-family: NotoSansHans;
      font-weight: 400;
      color: #333333;
    }

    .btn {
      width: 105px;
      height: 30px;
      background: #ffffff;
      border-radius: 4px;
      // border: 1px solid #2A63ED;
      border: 1px solid var(--el-color-primary);
      text-align: center;
      line-height: 30px;
      // color: #2A63ED;
      color: var(--el-color-primary);
      cursor: pointer;
    }
  }
}

.close-btn {
  cursor: pointer;
  color: #cfcfcf;
  font-size: 28px;

  // &:hover {
  //   color: #B93947;
  // }
}

.header-row {
  // margin-bottom: 20px;
  position: relative;
  .charge-price-ico {
    position: absolute;
    width: 67px;
    height: 67px;
    top: -34px;
    left: -34px;
  }
  .iconfont {
    font-size: 33px;
    color: var(--el-color-primary);
    margin-right: 11px;
  }

  .isContainsTheTariffs {
    box-sizing: border-box;
    display: inline-block;
    margin-left: 7px;
    width: 50px;
    height: 20px;
    background: #ffdecc;
    border-radius: 13px;
    font-weight: 400;
    font-size: 14px;
    color: #ff5b02;
    line-height: 20px;
    text-align: center;
  }

  .start-city,
  .end-destination {
    font-size: 24px;
    font-weight: bold;
    color: #333333;
    margin-right: 20px;
  }

  .start-city {
  }

  .end-destination {
  }

  .space-icon {
    font-size: 18px;
    color: #cfcfcf;
    margin-right: 20px;
  }

  .place-order-btn {
    width: 160px;
    height: 50px;
    border-radius: 10px;
    font-size: 18px;
    font-weight: 500;
    color: #ffffff;
  }
}

.header-row {
  display: flex;
  line-height: 24px;
  // margin-bottom: 20px;

  .header-title {
    display: flex;
    align-items: center;
    flex: 1;
    font-size: 18px;
    font-weight: bold;
    color: #333333;

    .remark-tag {
      margin-left: 10px;
      display: inline-block;
      padding: 2px 4px;
      font-size: 14px;
      font-weight: normal;
      color: #ff4d01;
      background: #fff8f5;
      border-radius: 2px;
      border: 1px solid #ffe3d7;
    }
  }

  .time {
    font-size: 16px;
    color: #999;
    margin-left: 20px;
  }
}

.product-info {
  box-sizing: border-box;
  display: flex;
  padding: 20px 20px;
  height: 100px;
  background: #f1f3f8;
  border-radius: 8px;
  justify-content: space-around;
  align-items: center;
  // margin-bottom: 20px;

  &--item {
    text-align: center;
    box-sizing: border-box;
    max-width: 30%;
    width: auto;
    min-width: 10%;
    position: relative;

    //&:not(:first-child)::after{
    //  content: "";
    //  position: absolute;
    //  z-index: 1;
    //  left: 0;
    //  top: 25%;
    //  bottom: 25%;
    //  width: 1px;
    //  background-color: #B6B6B6;
    //}
  }

  &--item-space {
    height: 100%;
    width: 1px;
    position: relative;

    &:not(:first-child)::after {
      content: "";
      position: absolute;
      z-index: 1;
      left: 0;
      top: 25%;
      bottom: 25%;
      width: 1px;
      background-color: #b6b6b6;
    }
  }

  .label {
    font-size: 16px;
    font-weight: 500;
    color: #666666;
    margin-top: 10px;
  }

  .value {
    font-size: 16px;
    font-weight: bold;
    color: #333333;

    //-webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    //display: -webkit-box;
    //-webkit-box-orient: vertical;
    //word-break: break-word;
    white-space: nowrap;
  }
}

.remark-tabs {
  font-size: 18px;
  margin-bottom: 10px;
  border-bottom: 1px solid #eee !important;
  :deep(.g-tabs--item) {
    min-width: 142px;
  }
}

.remark-content {
  min-height: 100px;
  white-space: pre-wrap;
}

.detail-box2,
.detail-box3,
.detail-box4 {
  background-color: #fff;
  padding: 20px;
  border-radius: 12px;
  margin-bottom: 15px;

  :deep(.el-table) {
    border-radius: 8px;
  }
}

.detail-box5 {
  padding-top: 10px;
  background-color: #fff;
  // padding: 20px;
  border-radius: 12px;
}
:deep(.g-tabs2) {
  border-bottom: none;
}
:deep(.g-tabs2--item) {
  color: #555 !important;
  font-size: 16px;
}
:deep(.g-tabs2--item.is-active) {
  color: #2a2a2a !important;
  // box-sizing: border-box;
  font-weight: 600;
  // font-size: 16px;
  // border-bottom: 3px solid var(--el-color-primary);
}
:deep(.el-table__inner-wrapper) {
  border-radius: 0 !important;
}
</style>
