import {isObject} from "@/utils/utils";
import axiosMain from "@/axios";
import {getSystemType} from "@/utils/utils.commonTenantParams";

/**
 * 账号密码登录
 * @param data {{name:string,pwd:string,loginType:string|number,verificationCode?:string}} name:登录账号;pwd:登录密码;verificationCode:验证码;loginType:登录类型1 账号密码登录；2 手机验证码登录；3 扫描登录;
 * @return {Promise<AxiosResponse<any>>}
 */
export function login(data) {
  if (!isObject(data)) return Promise.reject(Error("缺少登录参数"));
  if (!data.loginType) return Promise.reject(Error("缺少登录类型"));
  if (data.loginType === 1) {
    if (!data.name) return Promise.reject(Error("缺少登录账号"));
    if (!data.pwd) return Promise.reject(Error("缺少登录密码"));
  } else if (data.loginType === 2){
    // 手机号登录
    if (!data.name) return Promise.reject(Error("缺少手机号"));
    if (!data.verificationCode) return Promise.reject(Error("缺少验证码"));
  }
  let url = "/oms/user/login";
  return axiosMain.post(url,data)
}
/**
 * 获取用户信息
 */
export function getUserInfo(userId){
  let url = `/oms/user/userInfo/${userId}`;
  return axiosMain.get(url);
}
/**
 * 获取用户页面权限列表
 */
export function getUserPagePermissionList( ) {
  let systemType = getSystemType();
  let url = "/oms/authority/menu/" + systemType ;
  return axiosMain.post(url);
}
/**
 * 获取图形验证码
 */
export function getVerificationCode(data) {
  let url = "/oms/user/verificationCode";
  return axiosMain.post(url);
}

/**
 * 获取注册短信验证码
 * @param phone {String}
 * @return {Promise<AxiosResponse<any>>}
 */
export function getRegisterPhoneCode(phone) {
  let url = `/oms/user/registerSmsCode/${phone}`;
  let data = {
    phone,
  }
  return axiosMain.get(url);
}
/**
 * 获取登录短信验证码
 * @param phone {String}
 * @return {Promise<AxiosResponse<any>>}
 */
export function getLoginPhoneCode(phone) {
  let url = `/oms/user/loginSmsCode/${phone}`;
  let data = {
    phone,
  }
  return axiosMain.get(url);
}

/**
 * 账号密码登录
 * @param data {object}
 * @return {Promise<AxiosResponse<any>>}
 */
export function register(data) {
  if (!isObject(data)) return Promise.reject(Error("缺少注册数据"));
  if (!data.userType) return Promise.reject(Error("缺少注册用户类型"));
  if (!data.name) return Promise.reject(Error("缺少注册账号"));
  if (!data.pwd) return Promise.reject(Error("缺少注册密码"));
  if (!data.phoneNo) return Promise.reject(Error("缺少手机号"));
  if (!data.verificationCode) return Promise.reject(Error("缺少验证码"));
  if (data.userType === "G") {

  } else if (data.userType === "Q"){

  } else if (data.userType === "H"){

  }
  let url = "/oms/user/register";
  return axiosMain.post(url,data)
}