import {getImportPathModuleName} from "@/utils/utils.import";
const modules = import.meta.glob('./modules/*.js',{eager:true});
const model = {};
for (const path in modules) {
  let moduleName = getImportPathModuleName(path);
  const mod = modules[path];
  model[moduleName] = mod.default.zh;
}
const zh = {
  message: {
    hello: '你好，世界'
  },
  ...model,
}
console.log('zh',zh);
export default zh