/**
 * 首页覆盖国家相关
 */
import {continentListSort} from "@/utils/utils";

export function useCoverageCountry(){
  // 覆盖国家二级列表
  let coverageCountryList = ref([]);
  let coverageCountryUnactivatedList = ref([]);
  // 覆盖国家当前激活的洲
  let coverageCountryCurrentValue = ref("all");
  /**
   * 请求覆盖国家列表
   */
  function getCoverageCountryList(){
    let url = "/oms/quotedPrice/listOverlayCountryPrice"
    return axiosMain.post(url)
      .then(res=>{
        let list = res.data|| [];
        // let {
        //   alreadyList,
        //   alreadyTotal,
        //   afootList,
        //   afootTotal,
        // } = disposeHotCountryList(list);
        // let newList = disposeCoverageCountryList(list);
        let newList = initCoverageCountryList(list);
        if(newList[0]) coverageCountryCurrentValue.value = newList[0].value;
        coverageCountryList.value = newList;
      })
      .catch(res => {
        console.error(res)
        coverageCountryList.value = [];
      })
  }
  /**
   * 处理 覆盖国家（热门国家）数据 为已开通和未开通
   * @param list
   * @return {{afootTotal: (*|number), alreadyTotal: (*|number), alreadyList: (*|*[]), afootList: (*|*[])}}
   */
  function disposeHotCountryList(list = []){
    // 已上线
    let alreadyList = [];
    let alreadyTotal = 0;
    // 筹备中
    let afootList = [];
    let afootTotal = 0;

    list.forEach(continentItem=>{
      let continentItemObj = {
        label: continentItem.continentName||"全部",
        value: continentItem.id||`all`,
        list: [],
        total: 0,
      };
      let arr1 = [];
      let arr2 = [];
      let sonList = continentItem.dataList || [];
      sonList.forEach(item=>{
        if(item.status === 1){
          arr1.push(item);
        }else{
          arr2.push(item);
        }
      })
      alreadyList.push(Object.assign({},continentItemObj,{
        list:arr1,
        total:arr1.length,
      }))
      afootList.push(Object.assign({},continentItemObj,{
        list:arr2,
        total:arr2.length,
      }))
    })
    alreadyList = continentListSort(alreadyList, 'label')
    afootList = continentListSort(afootList, 'label')


    let alreadyAllItem = alreadyList.find(item=>item.value === "all");
    alreadyTotal = alreadyAllItem.list.length;

    let afootAllItem = afootList.find(item=>item.value === "all");
    afootTotal = afootAllItem.list.length;

    return {
      alreadyList,
      alreadyTotal,
      afootList,
      afootTotal,
    }
  }
  /**
   * 处理 覆盖国家（热门国家）数据2
   */
  function disposeCoverageCountryList(list = [],pageSize = 12) {
    let newList = list.map(continentItem=> {
      let sonList = continentItem.dataList || [];
      let groupingLength = Math.ceil(sonList.length / pageSize);
      let groupingList = [];
      for (let i =0;i < groupingLength;i++){
        groupingList[i] = sonList.slice(i * pageSize,(i + 1) * pageSize);
      }
      let continentItemObj = {
        label: continentItem.continentName || "全部",
        value: continentItem.id || `all`,
        list: sonList,
        groupingList:groupingList,
        total: sonList.length,
      };
      return continentItemObj
    });
    newList = continentListSort(newList, 'label')
    return newList;
  }
  /**
   * 初始化接口获取的 覆盖国家（热门国家）数据（一级列表[国家]分组为二级列表[洲[国家]]）
   */
  function initCoverageCountryList(list,pageSize = 12){
    // 所有项
    let allItem = createContinentGroupItem("全部","all",list);
    allItem.sort = 0
    // 大洲分组列表
    let continentGroupList = [allItem];

    list.forEach(item=>{
      let continentItem = continentGroupList.find(res=>res.value === item.continentId);
      if(!continentItem){
       let groupItem = createContinentGroupItem(item.continent,item.continentId,[]);
       groupItem.sort = item.continetSort
        continentGroupList.push(groupItem);
       continentItem = groupItem;
      }
      continentItem.list.push(item);
    })
    continentGroupList.forEach(continentItem=>{
      let sonList = continentItem.list || [];
      continentItem.total = sonList.length || 0;
      let groupingLength = Math.ceil(sonList.length / pageSize);
      let groupingList = Array(groupingLength);
      for (let i =0;i < groupingLength;i++){
        groupingList[i] = sonList.slice(i * pageSize,(i + 1) * pageSize);
      }
      continentItem.groupingList = groupingList;
    })
    continentGroupList.sort((a,b)=> a.sort - b.sort)
    return continentGroupList;
  }
  /**
   * 创建单个洲分组
   * @param label {string}  洲名称
   * @param value {string|number} 洲id
   * @param list  {Array<Object>} 大洲内所有数据列表
   * @returns {{total: number, groupingList: *[], label: string, list: *[], value: string}}
   */
  function createContinentGroupItem(label="洲名称",value="洲id",list=[]) {
    return {
      label: label,
      value: value,
      list: list,
      groupingList: [],
      total: list.length,
    }
  }
  return {
    coverageCountryList,
    coverageCountryCurrentValue,
    getCoverageCountryList,
  }
}
