/**
 * 获取当前滚动条位置
 * @return {number}
 */
function getScrollY() {
  return window.pageYOffset || document.documentElement.scrollTop;
}

/**
 * 滚动到指定位置
 * @param targetY {number}  指定位置（Y轴）
 * @param timeSpace {number}  滚动间隔 毫秒ms
 * @param distance  {number}  第一次滚动距离,逐渐加快 px
 */
export function scrollTo (targetY=0,timeSpace=20,distance = 50) {
  let currentY = getScrollY();
  let isTop = currentY > targetY;
  let timeout = setInterval(()=>{
    let currentY = getScrollY();
    if(isTop ? currentY <= targetY : currentY >= targetY ){
      clearInterval(timeout);
      return false;
    }
    distance = Math.ceil(distance * 1.4);
    let l = isTop ? -distance : distance;
    window.scroll(0,currentY + l);
  },timeSpace)
}

/**
 * 记录Y轴滚动条位置
 * @param timeSpace {number} 滚动监听回调间隔 ms
 * @return {{scrollY: *}}
 */
export function useFixedToolsScroll(timeSpace=100){
  // 记录滚动条历史位置
  let scrollY = ref(0);
  /**
   * 滚动监听
   */
  let scrollCallback = throttle(function () {
    scrollY.value = getScrollY();
  },timeSpace)
  // onActivated(()=>{})
  onMounted(()=>{
    scrollY.value = getScrollY();
    window.addEventListener("scroll",scrollCallback)
  })
  // onDeactivated(()=>{})
  onBeforeUnmount(()=>{
    window.removeEventListener("scroll",scrollCallback);
  })

  return {
    scrollY
  }
}