<template>
  <!--首页：切屏箭头-->
  <div class="screen-down-arrows" :class="{'is-default-position':isDefaultPosition}">
    <el-icon class="arrow-item"><ArrowDownBold /></el-icon>
    <el-icon class="arrow-item"><ArrowDownBold /></el-icon>
  </div>
</template>

<script>
export default {
  name: "screenDownArrows",
  props:{
    isDefaultPosition:{
      type:Boolean,
      default:true,
    },
  },
}
</script>

<style scoped lang="scss">
@mixin up-down-anima(){
  animation-name: arrowUpDown;
  animation-direction: reverse;
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
}
.is-default-position{
  position: absolute;
  z-index: 2;
  bottom: 10px;
  left: 50%;
  margin-left: -17px;
}
.screen-down-arrows{
  user-select: none;
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: var(--el-color-primary);
  border-radius: 4px;
  cursor: pointer;
  &:hover{
    //background-color: rgba(0,0,0,0.2);
    background-color: rgba(153,153,153,0.1);
  }
  &:active{
    background-color: rgba(153,153,153,0.3);
  }
}
.arrow-item{
  @include up-down-anima();
  font-size: 14px;
  &:first-child{
    opacity: 0.4;
    margin-bottom: -6px;
  }
}

@keyframes arrowUpDown {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(6px);
  }
}
</style>