import "@/assets/style/main.scss";
import { createApp } from "vue";
import { createPinia } from "pinia";

import App from "./App.vue";
import router from "./router";

import "@/assets/style/element/index.scss";
import "@/assets/style/element/dark.scss";
import ElementPlus from "element-plus";
// import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import zhCn from "element-plus/dist/locale/zh-cn.mjs";

import axios from "./axios/index";
import VueAxios from "vue-axios";

import "nprogress/nprogress.css";

// import "@/mock/index";

import { i18n } from "@/language";
import { mountDirectives } from "@/directives";
import { createHead } from '@unhead/vue'
const head = createHead()
// lodash引入问题参考 https://www.rollupjs.com/guide/faqs
// import _ from "lodash"
// window._ = _;

const app = createApp(App);
app.config.warnHandler = () => null;
app.use(head)
app.use(createPinia());
app.use(router);
app.use(ElementPlus, {
  locale: zhCn,
});
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}
// import {globalComponentList} from "@/components";
// for (const [key, component] of Object.entries(globalComponentList)) {
//   app.component(key, component)
// }
app.use(VueAxios, axios);
app.use(i18n);
app.use(mountDirectives);

app.mount("#app");
console.log("环境变量", import.meta.env);
