<template>
  <div class=" page-size-container"
       :class="[styleClass,{'is-default-space':isDefaultSpace}]">
    <div class="title-row">
    <span class="title-row--line"></span>
    <span class="title-row--dot">
      <span class="title-row--dot-item"></span>
      <span class="title-row--dot-item"></span>
    </span>
    <div class="title-row--content">
      <slot>{{title}}</slot>
    </div>
    <span class="title-row--dot is-right">
      <span class="title-row--dot-item"></span>
      <span class="title-row--dot-item"></span>
    </span>
    <span class="title-row--line is-right"></span>
    </div>
    <div class="tip-row" v-if="titletip">
      {{titletip}}
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  title:String,
  isDefaultSpace:{
    type:Boolean,
    default:true,
  },
  // isPrimaryStyle:Boolean,
  isWhiteStyle:Boolean,
  titletip:String,
})
const styleClass = computed(()=>{
  let defaultStyle = "is-primary-style";
  if(props.isWhiteStyle){
    return "is-white-style"
  }
  return defaultStyle
})
</script>

<style scoped lang="scss">
@use 'sass:color';
@mixin definedStyle($lineColor,$fontColor){
  .title-row--line{
    background: linear-gradient(270deg, $lineColor 0%, transparent 100%);
    &.is-right{
      background: linear-gradient(90deg, $lineColor 0%, transparent 100%);
    }
  }
  .title-row--content{
    color: $fontColor;
  }
  .title-row--dot-item{
    background-color: $lineColor ;
  }
  .tip-row {
    color: $fontColor;
  }
}
.title-row{
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;
  box-sizing: border-box;
  &.is-white-style{

  }
  &--line{
    width: 98px;
    height: 2px;
    border-radius: 1px;
    //background: linear-gradient(270deg, var(--el-color-primary) 0%, transparent 100%);
    //&.is-right{
    //  background: linear-gradient(90deg, var(--el-color-primary) 0%, transparent 100%);
    //}
  }
  &--content{
    font-size: 36px;
    font-weight: bold;
    //color: var(--el-color-black);
    margin: 0 10px;
    line-height: 1.6;
  }
  &--dot{
    display: inline-flex;
    margin: 0 10px;
    padding: 0 3px;
    &-item{
      transform: rotateZ(45deg);
      display: inline-block;
      width: 12px;
      height: 12px;
      //background-color: var(--el-color-primary) ;
      margin: 0 -3px;
      &:first-child{
        opacity: 0.5;
      }
    }
    &.is-right{
      flex-direction: row-reverse;
    }
  }
}
.tip-row {
  margin-top: 10px;
  text-align: center;
  font-size: 20px;
  color: #333333;
  font-weight: normal;
}
.is-default-space{
  $space:80px;
  //height: 68px + $space * 2;
  //padding-top: $space;
  //padding-bottom: $space;
  margin-top: $space;
  margin-bottom: $space;
}
.is-primary-style{
  @include definedStyle(var(--el-color-primary),var(--el-color-black))
}
.is-white-style{
  @include definedStyle(white,white)
}
</style>