import * as user from "./modules/user"
import * as news from "./modules/news"
import * as boxInfo from "./modules/boxInfo"
import * as home from "./modules/home"
import * as operate from "./modules/operate"
export const api = {
  user: user,
  news: news,
  boxInfo: boxInfo,
  home: home,
  operate: operate
};