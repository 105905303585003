<template>
  <div class="select-place-template"
       ref="selectPlaceTemplate"
       :class="[styleClass,{'is-left-padding':isLeftPadding}]">
    <div class="input-box">
      <el-input class="search-text is-input"
                :class="{'is-clear':clearable,'is-temporary-disabled':isTemporaryDisabled}"
                ref="selectStartInput"
                :placeholder="isHasSelectValue?'':placeholder"
                v-model="searchText"
                :disabled="disabled || isTemporaryDisabled"
                @click="noEvent"
                @blur="handlerBlur"
                @focus.="handlerFocus"
                @keyup.enter.native="handlerEnter"
                @keydown.tab.native="handlerTab"
      ></el-input>

      <div class="value-text"
           :class="[showTextStatus,clearable?'is-clear':'']"
      >
        <slot name="valueText" :value-text="multipleShowName">
          <div class="text-row">
            {{ multipleShowName }}
          </div>
        </slot>
      </div>
    </div>

    <div class="btn-delete" v-if="!disabled && clearable && isHasSelectValue">
      <el-icon class="icon el-icon-circle-close"  @click="clickClear"><CloseBold /></el-icon>
    </div>

    <el-popover
      :popper-class="`select-place-template-popper-3 ${popperClass}`"
      :show-arrow="true"
      ref="startPopover"
      trigger="contextmenu"
      placement="bottom-start"
      title=""
      :offset="offset"
      :width="popoverWidth"
      :visible="visiblePopover"
      virtual-triggering
      :virtual-ref="$refs.selectPlaceTemplate"
      @show="handlerShow"
      @after-enter="handlerAfterEnter"
      @hide="handlerHide"
      @after-leave="handlerAfterLeave"
    >
      <div class="alter-place-bpx">
        <!-- <div class="alter-place-bpx--left">
          <div class="left-tabs">
            <div
                class="left-tabs--item"
                :class="{'is-active': currentTabValue === item.value,'is-multi-line': item.description}"
                v-for="(item,index) in tabHeadList"
                :key="item.value"
                @click="changeTabs(item.value,item)"
            >
              {{item.label}}
              <template v-if="item.description">
                <span class="description">{{item.description}}</span>
              </template>
            </div>
          </div>
        </div> -->
        <div class="alter-place-bpx--right">
          <!--    货物类型      -->
          <template v-if="currentTabValue === 'goodsType'">
<!--            <el-input v-model="goodsTypeSearchText"  @change="changeGoodsTypeSearchText" @keydown.enter="changeGoodsTypeSearchText(goodsTypeSearchText)" class="m-b-20"/>-->
            <template v-if="isLoading">
              <div class="hint-row"><i class="el-icon-loading"></i> {{ loadingText }} </div>
            </template>
            <template v-if="goodsTypeSearchLoading">
              <div class="hint-row"><i class="el-icon-warning-outline"></i>正在搜索... </div>
            </template>
            <template v-else-if="goodsTypeFilterList && goodsTypeFilterList.length">
              <div class="groups-list-wrap is-allow-scroll">
                <div class="groups-list">
                  <div class="groups-li">
                    <!--<div class="groups-li__left"></div>-->
                    <div class="groups-li__right">
                      <div class="groups-li__item"
                           :class="{'is-active': goodsTypeCurrentValue[goodsTypeValueKey] === d[goodsTypeValueKey],'is-disabled':d.disabled}"
                           @click="selectItemGoodsType(d)"
                           v-for="(d,i) in goodsTypeFilterList"
                           :key="i">
                        {{d[goodsTypeLabelKey]}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="hint-row"><i class="el-icon-warning-outline"></i> {{ noDataMessage }} </div>
            </template>
          </template>
          <!--    货物名称      -->
          <template v-else-if="currentTabValue === 'goodsName'">
            <el-input v-model="goodsNameSearchText" @change="changeGoodsNameSearchText" @keydown.enter="changeGoodsNameSearchText(goodsNameSearchText)"/>
            <div class="m-t-10" style="height: 230px;">
              {{ goodsNameLoading}} - {{goodsNameSearchLoading}}
              <template v-if="goodsNameLoading">
                正在获取....
              </template>
              <template v-else-if="goodsNameSearchLoading">
                正在搜索....
              </template>
              <template v-else>
                <goods-name-multilevel-list
                    ref="goodsNameMultilevel"
                    :data="goodsNameList"
                    v-model:current-value="goodsNameCurrentValue"
                    :loading="goodsNameLoading || goodsNameSearchLoading"
                    @change="selectGoodsNameItem"/>
              </template>
            </div>
<!--            <div class="footer-row is-placeholder"/>-->
<!--            <div class="footer-row">-->
<!--              <el-button @click="hideListPopover" class="btn btn-cancel" type="" plain size="large" >取消</el-button>-->
<!--              <el-button @click="confirmSelect"-->
<!--                         type="primary"-->
<!--                         class="btn"-->
<!--                         size="large">确认选择</el-button>-->
<!--            </div>-->
          </template>
        </div>
      </div>
      <slot name="footer"></slot>
    </el-popover>

  </div>
</template>
<script>
import {baseDataPrivateAddressList} from "@/components/newSelectPlace/newSelectPlace.utils";
import BScroll from "better-scroll";
import {ElMessage} from "element-plus";
import GoodsNameMultilevelList from "@/components/newSelectPlace/goodsNameMultilevelList.vue";
export default {
  name: "selectGoodsInfo",
  components: {GoodsNameMultilevelList},
  emits: [
    'update:visible',
    'blur',
    'focus',
    'clear',
    'hide',
    'show',
    'after-leave',
    'after-enter',
    'update:defaultTabValue',
    'changeTab',
    'selectGoodsType',
    'update:goodsType',
    'selectGoodsName',
    'update:goodsName',
    'update:modelValue',
    'change',
    'selectItem',
  ],
  props:{
    // 是否默认样式
    isDefaultStyle: {
      type:Boolean,
      default:true,
    },
    isWhiteStyle:Boolean,
    isLeftPadding: {
      type:Boolean,
      default:true,
    },
    modelValue:{ // 选中值,单选Object/Array[{}],多选必须Array
      type:[Object,Array],
      default:()=>({}),
    },
    disabled:{ // 输入框是否禁用，禁用情况下点击输入框页无法弹出列表
      type:Boolean,
      default:false,
    },
    clearable:{ // 是否可清空
      type:Boolean,
      default:true,
    },
    selectItemCallback:{// 选中某项item时（或全选时），会调用该callback,返回true/false
      type:Function,
      // default(item,list,currentTabValue){
        // item 当前点击准备选择的item,（如果是全选时则，则为[item1,item2,item3....]的数组结构）
        // list 已选择的item集合，即selectValue[](copy版本,只有返回ture，对该list的修改才会生效)
        // currentTabValue 当前点击的item所属的tab Value
        // 返回true则正常执行插入list，返回false则不执行后续逻辑
        // return true;
      // },
    },
    // filterPrivateAddress:{ // 是否在选中某项时过滤已选列表中 不同仓库类型(是否为私人地址) 的item
    //   type:Boolean,
    //   default:false,
    // },
    // differentTypesValue:{ // 是否允许同时选中不同类型的值(不同类型)
    //   type:Boolean,
    //   default:false,
    // },
    isLoading:{ // 显示 正在请求数据中...
      type:Boolean,
      default:false,
    },
    loadingText:{ // 加载时显示的文本
      type:String,
      default:"正在请求数据中...",
    },
    placeholder:{ //输入框提示信息
      type:String,
      default:"请选择",
    },
    noDataMessage:{ //列表为空时的提示信息
      type:String,
      default:"暂未找到您要的信息",
    },
    popoverWidth:{ //弹出层宽度
      type:String,
      default:"625",
    },
    offset:{ //出现位置的偏移量
      type:Number,
      default:undefined,
    },
    popperClass:{ // 弹窗class
      type:String,
      default:"",
    },
    itemLabelKey:{ // 选中item数据：展示的文本取值key，输入框也取该配置展示（无论是一级还是多级列表）
      type:String,
      default:"namec",
    },
    itemValueKey:{ // 选中item数据：进行匹配校验的字段（无论是一级还是多级列表）
      type:String,
      default:"id",
    },
    // 货物类型选展示的文本取值key，输入框也取该配置展示
    goodsTypeLabelKey:{
      type:String,
      default:"goodsTypeName",
    },
    // 货物类型选中item取值key
    goodsTypeValueKey:{
      type:String,
      default:"goodsTypeId",
    },
    // 默认选中的tab
    defaultTabValue: String,
    // 目的国家ID
    countryId: [String,Number],
    // 弹窗默认是否展示
    visible:Boolean,
    // 供选择的货物类型列表
    goodsTypeList:{
      type:Array,
      default:()=>[],
    },
    // 货物类型 当前选中值
    goodsType:Object,
    // 货物类型 列表是否正在加载中
    goodsTypeLoading:{
      type:Boolean,
      default:false,
    },
    goodsName:Object,
  },
  data() {
    return {
      /* 筛选 */
      searchText:"",//输入框搜索文本
      searchLoading:false,//筛选状态
      searchFilerList:[],//筛选出的列表
      /* */
      isFocus:false,//当前输入框是否获得焦点
      visiblePopover:false,// 显示弹出层
      // 是否临时禁用(关闭前需要设为true,关闭后再设回false,防止popover组件给触发目标内的input获取焦点导致关闭弹窗失败)
      isTemporaryDisabled:false,
      // 选项卡
      tabHeadList:[
        {label:"货物类型", value:"goodsType",},
        // {label:"商品名称", value:"goodsName",description:"（选填）",isMultiLine:true,},
      ],
      // 选项卡当前激活项
      currentTabValue:"goodsType",

      // 货物类型 列表
      // goodsTypeList:[
      //   // {label:"全部",value:"all",},
      //   {label:"带电",value:"dd",},
      //   {label:"普货",value:"ph",},
      //   {label:"带磁",value:"dc",},
      //   {label:"液体",value:"yt",},
      // ],
      // 货物类型 列表是否正在加载中
      // goodsTypeLoading:false,
      // 货物类型 当前选中值
      goodsTypeCurrentValue:{},
      // 货物类型 筛选文本
      goodsTypeSearchText:"",
      // 货物类型 筛选状态
      goodsTypeSearchLoading:false,
      // 货物类型 当前展示列表
      goodsTypeFilterList:[],

      // 商品名称 当前选中值
      goodsNameCurrentValue:{},
      // 商品名称 列表
      goodsNameList:[],
      goodsNameAllList:[],
      // 商品名称 列表是否正在加载
      goodsNameLoading:false,
      // 商品名称 筛选文本
      goodsNameSearchText:"",
      // 商品名称 筛选状态
      goodsNameSearchLoading: false,

      /* 当前选中item 和 tab*/
      selectedValue:{},//选中值

    }
  },
  computed:{
    styleClass(){
      let defaultStyle = "";
      if(this.isWhiteStyle){
        return "is-white-style"
      }else if(this.isDefaultStyle){
        return "is-default-style"
      }
      return defaultStyle
    },
    /**
     * 回显文本状态 class
     * @returns {string}
     */
    showTextStatus(){
      // 搜索框有输入值
      if(this.searchText.length) return "opacity-0"
      // 搜索框没有输入值，但是聚焦状态
      if(this.isFocus) return "opacity-04"
      return "";
    },
    /**
     * 是否有选中值
     * @returns {*}
     */
    isHasSelectValue(){
      // 有选中值时 输入框不显示placeholder
      let hasGoodsType = this.goodsTypeCurrentValue[this.goodsTypeValueKey] || this.goodsTypeCurrentValue[this.goodsTypeValueKey] === 0;
      let hasGoodsName = this.goodsNameCurrentValue.value || this.goodsNameCurrentValue.value === 0;
      console.log("hasGoodsType",hasGoodsType);
      return hasGoodsType || hasGoodsName;
    },
    /**
     * 展示的选中文本
     */
    multipleShowName(){
      let text = "";
      if(this.isHasSelectValue){
        let goodsTypeText = this.goodsTypeCurrentValue[this.goodsTypeLabelKey] || "";
        let goodsNameText = this.goodsNameCurrentValue.label || "";
        if(goodsTypeText && goodsNameText){
          if(goodsTypeText === "全部"){
            text = goodsNameText;
          }else{
            text = goodsTypeText;
          }
        }else if(goodsTypeText){
          text = goodsTypeText;
        }else if(goodsNameText){
          text = goodsNameText;
        }
      }
     return text;
    },
  },
  watch:{
    /**
     * 默认选中值
     */
    modelValue:{
      immediate:true,
      // deep: true,
      handler: function (newValue) {

      },
    },
    goodsType(newValue){
      this.goodsTypeCurrentValue = newValue||{};
    },
    // countryId:{
    //   immediate:true,
    //   handler: function (newValue) {
    //     if(newValue){
    //       this.getGoodsTypeList()
    //     }else{
    //       this.goodsTypeList = [];
    //     }
    //   },
    // }
    goodsName(newValue){
      this.goodsNameCurrentValue = newValue || {};
    },
    defaultTabValue(newValue) {
      this.currentTabValue = newValue;
    },
    countryId(newValue) {
      this.goodsNameAllList = [];
      this.goodsNameList = [];
      // this.goodsNameCurrentValue = {};
      this.searchText = "";
    },
    visiblePopover(newValue){
      if(newValue){
        this.checkNeedRequestGoodsNameList();
      }
    },
    /* 监听搜索输入框 */
    searchText(newValue){
      if(this.currentTabValue === "goodsType"){
        this.goodsTypeSearchText = newValue;
        this.changeGoodsTypeSearchText(newValue);

        this.goodsNameSearchText = "";
        this.changeGoodsNameSearchText();
      }else{
        this.goodsNameSearchText = newValue;
        this.changeGoodsNameSearchText();

        this.goodsTypeSearchText = "";
        this.changeGoodsTypeSearchText();
      }
    },
    goodsTypeList(newValue){
      this.changeGoodsTypeSearchText();
    },
  },
  methods:{
    /**
     * 空函数
     * */
    noEvent(){
      console.log("点击")
    },
    /**
     * 失去焦点时触发
     */
    handlerBlur(){
      console.log("失去焦点")
      this.isFocus = false;
      this.$emit('blur');
      // this.hideListPopover();
    },
    /**
     * 获得焦点时触发
     */
    handlerFocus(){
      console.log("获取焦点")
      this.isFocus = true;
      this.$emit('focus');
      this.showListPopover();
    },
    /**
     * 按下回车时触发
     */
    handlerEnter(){
      console.log('按下回车')
    },
    handlerTab() {
      console.log('按下tab')
      this.handlerHide();
      this.hideListPopover();
    },
    /**
     * 清空
     */
    clickClear(){
      this.goodsTypeCurrentValue = {};
      this.emitChangeGoodsType();
      this.$emit("clear");
    },
    /**
     * 确认选择
     */
    confirmSelect(){
      this.emitChange();
      this.hideListPopover();
    },
    /**
     * emit change
     * @param value
     */
    emitChange(value = this.selectedValue){
      if(!isArray(value)) value = [];
      this.$emit("update:modelValue",value);
      this.$emit("change",value);
    },
    /**
     * 显示、隐藏列表
     */
    showListPopover(){
      console.log("showListPopover()");
      // console.error("show")
      this.visiblePopover = true;
      this.$emit("update:visible",true);
      console.log("this.visiblePopover",this.visiblePopover);
    },
    hideListPopover(){
      console.log("hideListPopover()");
      // console.error("hide")
      this.isTemporaryDisabled = true;
      this.visiblePopover = false;
      this.searchText = "";
      this.$emit("update:visible",false);
      console.log("this.visiblePopover",this.visiblePopover);
    },
    handlerShow(){ //显示时触发
      console.log("显示时触发")
      this.$emit("show");
    },
    handlerAfterEnter(){ //	显示动画播放完毕后触发
      console.log("显示动画播放完毕后触发")
      this.$emit("after-enter");
      if(this.isCanInitGroupsScroll){
        this.$nextTick(()=>this.initGroupsScroll());
      }
    },
    handlerHide(){ // 隐藏时触发
      console.log("隐藏时触发")
      this.isTemporaryDisabled = true;
      this.$emit("hide");
    },
    handlerAfterLeave(){ // 隐藏动画播放完毕后触发
      console.log("隐藏动画播放完毕后触发")
      this.hideListPopover()
      this.$emit("after-leave");

      this.$nextTick(()=>{
        setTimeout(()=>{
          this.isTemporaryDisabled = false;
        },100)

      })
    },

    /**
     * 全局关闭
     */
    globalClosePopover(e){
      if(!this.visiblePopover) return false;
      let wrapView = this.$refs.startPopover;
      let {contentRef,triggerRef} = wrapView.popperRef;
      // console.log(wrapView.popperRef)
      // console.log(contentRef)
      // console.log(triggerRef)
      if(triggerRef && triggerRef.contains && triggerRef.contains(e.target)) return true;
      if(contentRef && contentRef.contains && contentRef.contains(e.target)) return true;
      this.hideListPopover();
    },
    /**
     * 切换table
     */
    changeTabs(value,tabItem){
      if(this.currentTabValue === value ) return true;
      this.currentTabValue = value;
      this.checkNeedRequestGoodsNameList();
      // this.searchText = "";
      // this.searchFilerList = [];
      // this.searchLoading = false;
      this.emitChangeTabValue(this.currentTabValue);
    },
    emitChangeTabValue(value){
      this.$emit("update:defaultTabValue",value);
      this.$emit("changeTab",value);
    },
    /**
     * 货物类型下拉列表
     */
    getGoodsTypeList(){
      let url = "/oms/data/getGoodsTypeByCountryId";
      let data = {
        // 国家id
        countryId: this.countryId,
      };
      this.goodsTypeLoading = true;
      axiosMain.get(url, {params:data})
        .then(res=>{
          this.goodsTypeLoading = false;
          let list = res.data || [];
          list = list.map(item=>{
            return {
              label: item.goodsTypeName,
              value: item.goodsTypeId,
            }
          })
          this.goodsTypeList = list;
        })
        .catch(res=>{
          console.error(res);
          this.goodsTypeLoading = false;
          this.goodsTypeList = [];
        })
    },
    /**
     * 选中货物类型item
     * @param item
     * @param index
     */
    selectItemGoodsType(item,index){
      if(item.disabled) return false;
      this.goodsTypeCurrentValue = item;
      this.emitChangeGoodsType(item);
      this.hideListPopover();
    },
    emitChangeGoodsType(value){
      if(!isObject(value)) value = {};
      this.$emit("update:goodsType",value);
      this.$emit("selectGoodsType",value);
    },
    /**
     * 选中商品名称
     * @param item
     * @param index
     */
    selectGoodsNameItem(item,index){
      if(!isObject(item)){
        item = {};
      }
      this.goodsNameCurrentValue = item
      this.emitChangeGoodsName(item);
      this.hideListPopover();
    },
    emitChangeGoodsName(value){
      if(!isObject(value)) value = {};
      this.$emit("update:goodsName",value);
      this.$emit("selectGoodsName",value);
    },
    /**
     * 检查是否需要获取商品名称列表
     */
    checkNeedRequestGoodsNameList(){
      console.log("检查是否需要获取商品名称列表")
      if(this.visiblePopover){
        if( this.currentTabValue === "goodsName"){
          if(!this.goodsNameAllList.length) {
            console.log("需要")
            this.getGoodsNameList();
          }
        }
      }

    },
    /**
     * 货物货物名称列表
     */
    getGoodsNameList(){
      let url = "/oms/data/selectHomePageAllProductFeeByCountryAndType";
      let data = {
        // 国家id
        "countryId": this.countryId,
        // 产品品名
        "productName": "",
        // 货物类型
        "productTypeFirstName": this.goodsTypeCurrentValue[this.goodsTypeValueKey]||"",
        // 产品类型
        "productTypeSecondaryName": "",
        noCommonParams:false,
      };
      this.goodsNameLoading = true;
      return axiosMain.post(url,data)
        .then(res=>{
          this.goodsNameLoading = false;
          let list = res.data || [];
          let disposeData = this.disposeGoodsNameList(list);
          this.goodsNameAllList = disposeData.list;
          this.goodsNameList = disposeData.list;
          return res;
        })
        .catch(res=>{
          this.goodsNameLoading = false;
          console.error(res);
        })
    },
    disposeGoodsNameList(list = []){

      list = list.map(one=>{
        let oneChildren = one.productTpeSecondaryNameList||[];
        oneChildren = oneChildren.map(two=>{
          let twoChildren = two.productList||[];
          twoChildren = twoChildren.map(three=>{
            return {
              label: three.productName,
              labelE: three.productNameE,
              code: three.productCode,
              value:three.id,
              id:three.id,
            }
          })
          return {
            label: two.productTpeSecondaryName,
            value:two.id,
            id:two.id,
            currency:two.currency,
            price:two.price,
            children:twoChildren,
          }
        })
        return {
          label: one.productTypeFirstName,
          value:one.id,
          id:one.id,
          children:oneChildren,
        }
      });
      return {
        list,
      };
    },
    /**
     * 变更商品名称搜索文本
     */
    changeGoodsNameSearchText(value){
      this.goodsNameSearchLoading = true;
      this.goodsNameList = [];
      this.startSearchGoodsName(value);
    },
    /**
     * 搜索文本按下回车
     */
    enterGoodsNameSearchText(){
      this.startSearchGoodsName(this.goodsNameSearchText);
    },
    startSearchGoodsName:debounce(function (searchText=""){
      let text = searchText.replace(/(^\s+)|(\s+$)/g,"");
      let baseList = cloneDeep(this.goodsNameAllList);
      let filterList = [];
      if(text){
        let filterFunction = (text="",item)=>{
          let itemLabel = String(item.label||"");
          let itemLabelE = String(item.labelE||"");
          let reg = RegExp(text,"ig");
          return reg.test(itemLabel) || reg.test(itemLabelE);
        };
        filterList = this.searchList(text,baseList,filterFunction);
      }else{
        filterList = baseList;
      }
      this.goodsNameList = filterList;
      this.goodsNameSearchLoading = false;
    },1000),
    searchList(text="",list=[],filterFunction = () =>[]){
      return list.filter(item=>{
        let check = filterFunction(text,item);
        if(check){
          return true;
        }else if(isArray(item.children)){
          item.children = this.searchList(text,item.children,filterFunction);
          return item.children.length;
        }
      })
    },
    /**
     * 变更商品类型搜索文本
     */
    changeGoodsTypeSearchText(value){
      this.goodsTypeSearchLoading = true;
      this.goodsTypeFilterList = [];
      this.startSearchGoodsType(value);
    },
    /**
     * 搜索文本按下回车
     */
    enterGoodsTypeSearchText(){
      this.startSearchGoodsType(this.goodsTypeSearchText);
    },
    /**
     * 搜索 货物类型
     */
    startSearchGoodsType:debounce(function (searchText=""){
      let text = searchText.replace(/(^\s+)|(\s+$)/g,"");
      // let baseList = cloneDeep(this.goodsTypeList);
      let baseList = this.goodsTypeList;
      let filterList = [];
      if(text){
        let filterFunction = (text="",item)=>{
          let itemLabel = String(item.goodsTypeName||"");
          // let itemId = String(item.goodsTypeId||"");
          let reg = RegExp(text,"ig");
          return reg.test(itemLabel);
        };
        filterList = baseList.filter(item => filterFunction(text,item));
      }else{
        filterList = baseList;
      }
      this.goodsTypeFilterList = filterList;
      this.goodsTypeSearchLoading = false;
    },300),
  },
  created() {
    // this.countDefaultSelect();
    document.addEventListener("click",this.globalClosePopover,false);
  },
  mounted() {
    if(this.defaultTabValue) {
      this.currentTabValue = this.defaultTabValue;
    }
    this.checkNeedRequestGoodsNameList();
    if(this.goodsType) this.goodsTypeCurrentValue = this.goodsType;
    if(this.goodsName) this.goodsNameCurrentValue = this.goodsName;
    if(this.goodsTypeList?.length){
      this.changeGoodsTypeSearchText();
    }
    if(this.countryId) {
      // this.getGoodsTypeList();
      // this.getGoodsNameList();
    }
  },
  destroy() {
    document.removeEventListener("click",this.globalClosePopover);
  },
}
</script>

<style lang="scss">
.select-place-template-popper-3{
  &.el-popover.el-popper{
    padding: 0;
    border-radius: 10px;
  }

}
</style>
<style scoped lang="scss">

/* 最外层容器 */
.select-place-template{
  --sp-input-color: inherit;
  --sp-input-font-size: 18px;
  --sp-input-font-weight: 600;
  --sp-input-placeholder-color: #999;
  --sp-input-placeholder-font-weight: 600;
  //width: 100%;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;

  /* 默认样式 */
  width: 200px;
  max-width: 100%;
  height: 50px;
  max-height: 100%;
  background-color: transparent;
  //background-color: #F5F5F5;
  border-radius: 10px;

  :deep(.el-input__inner){
    height: inherit;
    line-height: inherit;
  }
  &,
  * {
    box-sizing: border-box;
  }
  &:not(.is-disabled){
    &:hover{
      .btn-delete{
        display: flex;
      }
      //.search-text{
      //  :deep(input){
      //    padding-right: 0;
      //  }
      //  &:not([class~="is-input"]){
      //    padding-right: 0;
      //  }
      //}
      //.value-text{
      //  right: 30px;
      //}
    }
  }
}
.input-box{
  flex: 1;
  position: relative;
  color: var(--sp-input-color);
}
.btn-delete{
  position: relative;
  z-index: 1;
  min-width: 30px;
  height: 30px;
  text-align: center;
  font-size: 14px;
  display: none;
  //display: flex;
  align-items: center;
  justify-content: center;
  .icon{
    cursor: pointer;
    color: #c0c4cc;
    &:hover{
      color: #909399;
    }
  }
}


/* 搜索框 */
.search-text{
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  font-size: var(--sp-input-font-size);
  font-weight: var(--sp-input-font-weight);
  &,
  &.is-disabled :deep(.el-input__wrapper),
  &.is-disabled :deep(input),
  :deep(.el-input__wrapper),
  :deep(input)
  {
    box-sizing: border-box;
    color: inherit;
    background-color: transparent;
    box-shadow: none;
  }
  :deep(.el-input__wrapper){
    padding: 0;
  }
  :deep(input){
    min-width: 0;
    width: 100%;
    height: 100%;
    border: none;
    font-weight: inherit;
  }
  ::-webkit-input-placeholder {
    font-weight: var(--sp-input-placeholder-font-weight);
    color: var(--sp-input-placeholder-color);
  }
  &:not([class~="is-input"]){
    display: flex;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 15px;
    cursor: pointer;
    color: #999;
  }
  &.is-clear{
    //:deep(input){
    //  padding-right: 0;
    //}
    //&:not([class~="is-input"]){
    //  padding-right: 0;
    //}
  }
}

/* 选中值回显文本 */
.value-text{
  position: absolute;
  z-index: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  transition: opacity 0.4s ease;
  font-size: var(--sp-input-font-size);
  font-weight: var(--sp-input-font-weight);
  //&.is-clear{
  //  right: 30px;
  //}
}
.opacity-0{
  opacity: 0;
}
.opacity-04{
  opacity: 0.4;
}
.text-row{
  //padding: 0 11px;
  //font-size: 16px;
  //color: inherit;
  line-height: 30px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.is-default-style{
  &.select-popover{

  }
  .search-text{
    //color: #333333;
  }
}
.is-left-padding{
  .text-row,
  .search-text{
    padding-left: 15px;
    //  :deep(.el-input__wrapper){
    //    padding-left: 15px;
    //    padding-right: 15px;
    //  }
  }
}
.is-white-style{
  --sp-input-color:white;
}

/* 弹出框内容 */
.alter-place-bpx{
  display: flex;
  //align-content: space-evenly;
  height: 290px;
  &--left{
    $-left-width:100px;
    $-left-inner-padding:12px;
    box-sizing: border-box;
    width: $-left-width;
    //min-width: 100px;
    background-color: #F1F1F1;
    border-radius: 10px 0 0 10px;
    height: 100%;
    overflow: hidden;
    .left-tabs{
      box-sizing: border-box;
      width: $-left-width + 17px;
      height: 100%;
      padding: $-left-inner-padding 0 $-left-inner-padding $-left-inner-padding;
      overflow: hidden scroll;
      &--item{
        $-left-item-width: $-left-width -  $-left-inner-padding * 2;
        width: $-left-item-width;
        box-sizing: border-box;
        border-radius: 5px;
        font-size: 16px;
        font-weight: 500;
        color: #333333;
        text-align: center;
        &:not(.is-multi-line){
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          line-height: 28px;
        }
        &.is-multi-line{
          padding: 4px 0;
          line-height: 20px;
        }
        &:not(:first-child){
          margin-top: 12px;
        }
        &:not(.is-active){
          cursor: pointer;
          &:hover{
            color: var(--el-color-primary);
          }
        }

        &.is-active{
          background-color: var(--el-color-primary);
          color: white;
        }
      }
    }
    /*.left-tabs.el-tabs{
      height: 100%;
      .el-tabs__header {
        margin-right: 0 !important;
      }
      .el-tabs__item{
        padding: 0 10px;
      }
      .el-tabs__nav-wrap:after{
        content: none;
        display: none;
      }
      .el-tabs__active-bar{
        display: none;
      }
      .el-tabs__content {

      }
    }

    &{
      .el-tabs__nav{
        padding: 0 10px;
      }
      .el-tabs__item + .el-tabs__item{
        margin-top: 10px;
      }
      .el-tabs__item{
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 16px;
        font-weight: 500;
        color: #333333;
        text-align: center !important;
        height: 28px;
        line-height: 28px;
        &.is-active{
          //height: 28px;
          color: white;
          background-color: var(--el-color-primary);
          border-radius: 5px;
        }
      }
    }*/
  }
  &--right{
    flex: 1;
    box-sizing: border-box;
    padding: 12px 12px 0;
    overflow: hidden;
    height: 100%;
    position: relative;
  }
}
.groups-type-wrap{
  overflow: hidden;
  margin-bottom: 10px;
}
.groups-type{
  display: flex;
  align-items: center;
  border-bottom: 1px solid #F5F5F5;
  &:not([class~="align-left"]){
    justify-content: space-between;
  }
  &__item{
    .align-left & {
      margin-right: 10px;
    }
    white-space: nowrap;
    line-height: 28px;
    //font-size: 16px;
    text-align: center;
    cursor: pointer;
    position: relative;
    &.is-undefined{
      display: none !important;
    }
    &.is-active{
      color: var(--el-color-primary);
      font-weight: 600;
      &:before{
        content: "";
        position: absolute;
        z-index: 1;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 2px;
        background-color: var(--el-color-primary);
      }
    }
  }
}
.groups-list-wrap{
  height: 100%;
  box-sizing: border-box;
  margin-right: -14px;
  overflow: hidden;
  position: relative;
  &.is-allow-scroll{
    overflow-y: auto;
  }
}
.groups-list{

}
.groups-li{
  display: flex;
  &__left{
    box-sizing: border-box;
    width: 50px;
    color: #333;
    font-weight: 600;
    margin-bottom: 10px;
    line-height: 34px;
    height: 34px;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &__right{
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
  }
  &__item{
    box-sizing: border-box;
    width: 70px;
    padding: 0 6px;
    background: #f5f5f5;
    margin-right: 10px;
    margin-bottom: 6px;
    border-radius: 20px;
    color: #666;
    cursor: pointer;
    min-width: 60px;
    height: 34px;
    line-height: 34px;
    text-align: center;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &:not(.is-active):not(.is-disabled):hover{
      color:var(--el-color-primary);
      background-color: var(--el-color-primary-light-9);
    }
    &.is-active{
      background: var(--el-color-primary);
      color: white;
    }
    &.is-disabled{
      cursor: not-allowed;
      color: #bcbec2;
      background-color: #f4f4f5;
      border-color: #e9e9eb;
    }
  }
}

.footer-row{
  display: flex;
  justify-content: flex-end;
  &:not(.is-placeholder){
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
  &.is-placeholder{
    height: 36px;
    margin-top: 14px;
  }
  .btn{
    height: 36px;
    width: 96px;
    margin: 0;
    border-radius: 0;
    &:first-child{
      border-top-left-radius: 10px;
    }
    &:last-child{
      border-bottom-right-radius: 10px;
    }
  }
  .btn-cancel{
    &:not(:hover){
      //color: #999999;
      //background-color: #EEEEEE;
      //border-color: #EEEEEE;
    }
  }
}



</style>
