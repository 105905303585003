/**
 * 防抖
 * 短时间内多次触发同一事件（函数），只执行最后一次 ，或者只执行第一次，中间的不执行
 * @param callback    {function}  需要执行的方法
 * @param time        {number}    间隔时间(毫秒)
 * @param inFront     {boolean}   是否在开头执行
 * @return {(function())|(function(): void)}
 */
export function debounce(callback,time = 500, inFront = false){
  let target = 0;
  if(typeof callback !== "function") return function(){};
  if(typeof time !== "number" || time < 0) time = 500;
  return function(){
    if(target) clearTimeout(target);
    else if(inFront) callback.apply(this,arguments); // 在最开始执行
    target = setTimeout(()=>{
      target = 0;
      if(!inFront) callback.apply(this,arguments); // 在最后执行
    },time);
  }
}
/**
 * 节流
 * 一定的时间周期内时间内只执行一次函数，执行函数后，进入下一个时间周期计时
 * @param callback    {function}  需要执行的方法
 * @param time        {number}    间隔时间(毫秒)
 * @return {(function())|(function(): void)}
 */
export function throttle(callback,time = 500){
  let before = 0;
  if(typeof callback !== "function") return function(){};
  if(typeof time !== "number" || time < 0) time = 500;
  return function(){
    let now = Date.now();
    if(now === 0 ||  now - time >= before) {
      callback.apply(this,arguments);
      before = now;
    }
  }
}