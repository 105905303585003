<template>
  <div class="scroll-wrap" @click.stop="handlerParentClick">
    <vue-seamless-scroll2 :data="list" :class-option="classOption" class="scroll-component" ref="scrollComponent">
      <div class="partner-list">
        <cooperative-partner-item
            class="partner-item"
            :data-index="index"
            v-for="(item,index) in list"
            :key="`list1_${index}`"
        >
          <template v-if="item.thumbnailUrl">
            <img class="cover" :src="item.thumbnailUrl"/>
          </template>
          <template v-else-if="item.name">
            <div class="name">{{ item.name }}</div>
          </template>
        </cooperative-partner-item>
      </div>
    </vue-seamless-scroll2>
  </div>
</template>

<script setup>
import VueSeamlessScroll2 from "@/components/vue-seamless-scroll2/vue-seamless-scroll2.vue";
import CooperativePartnerItem from "@/views/home/components/cooperativePartner/cooperativePartnerItem.vue";
const emit = defineEmits(["clickItem"])
const props = defineProps({
  list: {
    type: Array,
    default: () => [],
  },
  // 数值越大速度滚动越快。
  step:{
    type:Number,
    default:1,
  },
  // 开启无缝滚动的数据量。
  limitMoveNum:{
    type:Number,
    default:3,
  },
  // 方向: 0 往下 1 往上 2 向左 3 向右。
  direction:{
    type:Number,
    default:3,
  },
  autoPlay:{
    type:Boolean,
    default:true,
  }
})
// 滚动配置
const classOption = computed(() => {
  return {
    direction:props.direction,
    step:props.step,
    limitMoveNum:props.limitMoveNum,
    autoPlay:props.autoPlay,
  }
})
const scrollComponent = ref(null);
/**
 * 重置滚动
 */
async function scrollReset() {
  await nextTick();
  if(scrollComponent.value && scrollComponent.value.reset) scrollComponent.value.reset();
}
/**
 * 滚动元素的父级挂载click事件代理
 */
function handlerParentClick(e){
  let $target = e.target;
  let result = eachFindItem($target);
  if(result){
    let index = result.getAttribute("data-index");
    if(typeof index === "string") index = parseInt(index);
    if(index >= 0){
      let item = props.list[index];
      if(item) clickItem(item,index);
    }
  }
}
/**
 * 查找触发点击代理的元素是否符合目标
 * @param $target
 * @return {boolean|*}
 */
function eachFindItem($target){
  if($target.className.match("scroll-wrap")) return false;
  if($target.className.match("scroll-component")) return false;
  if($target.className.match("partner-list")) return false;
  if($target.className.match("partner-item")) return $target;
  if($target.parentNode) return eachFindItem($target.parentNode);
  return false;
}
/**
 * 点击item
 * @param item
 * @param index
 */
function clickItem(item,index){
  console.log("点击新消息",item,index)
  emit("clickItem",item,index);
}


// watch(()=> props.list,()=> {
//   console.log(props.list,'11111111111111111111111111111111111111');
// })
</script>

<style scoped lang="scss">
.scroll-wrap {

}

.scroll-component {
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
}

.partner-list {
  display: flex;
  flex-wrap: nowrap;
}

.partner-item {
  margin-right: 40px;
  flex-shrink: 0;
  .cover {
    box-sizing: border-box;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .name{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>