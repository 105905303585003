<template>
  <el-select
      class="select-area-code"
      v-model="currentValue"
      filterable
      slot="prepend"
      placeholder="请选择"
      @change="handleSelect"
      remote
      :remote-method="handleRemoteMethod"
      remote-show-suffix
      :suffix-icon="CaretBottom"
  >
    <el-option
        v-for="item in phoneBeforeList"
        :key="item.value"
        :label="'+' + item.tel"
        :value="item.tel"
    >
      <span style="float: left">{{ item.name }}</span>
      <span
          style="
                          float: right;
                          color: #8492a6;
                          font-size: 13px;
                          margin-left: 30px;
                        "
      >+{{ item.tel }}</span
      >
    </el-option>
  </el-select>
</template>

<script setup>
import {CaretBottom} from "@element-plus/icons-vue"
import {phoneAreaCodeList} from "@/components/loginPopover/phoneAreaCode";
let emit = defineEmits(["update:modelValue","change"]);
let props = defineProps({
  modelValue:String,
  list:{
    type:Array,
    default:() => phoneAreaCodeList,
  }
})
let currentValue = ref("");
let phoneBeforeList = ref([
  phoneAreaCodeList[0]
]);

watch(()=>props.modelValue,newValue=>currentValue.value = newValue,{immediate:true});
function handleSelect(value){
  emit("update:modelValue",value)
  emit("change",value)
}
// 搜索手机区号
const handleRemoteMethod = debounce(filterPhoneBefore,100) ;
// 过滤手机区号
function filterPhoneBefore(query){
  if(!!query){
    phoneBeforeList.value = phoneAreaCodeList.filter(item => {
      return item.tel.indexOf(query) > -1;
    });
  }else{
    // phoneBeforeList.value = [
    //   phoneAreaCodeList[0]
    // ];
    phoneBeforeList.value = phoneAreaCodeList.filter(item => ["86",unref(currentValue)].includes(item.tel));
  }
}
</script>

<style scoped lang="scss">
.select-area-code{
  width: 100%;
}
</style>