<template>
  <div class="page-wrap" :style="[wrapStyle]">
    <slot name="before"/>
    <div class="page-size-container">
      <slot/>
    </div>
    <slot name="after"/>
  </div>
</template>

<script setup>
const props = defineProps({
  bgUrl:String,
})
let wrapStyle = computed(()=>{
  let obj = {};
  if(props.bgUrl){
    obj["backgroundImage"] = `url(${props.bgUrl})`
  }
  return obj;
})
</script>

<style scoped lang="scss">
.container-box.page-wrap{
  width: 1200px;
  border-radius: 10px
}
</style>