const Layout = () => import('@/layout/layout.vue')
const route = [
  {
    path: '/login',
    name: 'login',
    meta: {
      title: "登录页",
    },
    component: () => import('@/views/login/login.vue')
  },
  {
    path: '/testLink',
    name: 'testLink',
    meta: {
      title: {
        zh: "测试用页面",
        en: "test page",
      },
    },
    component: () => import('@/views/testLink/index.vue')
  },
  {
    path: '/system',
    name: 'system',
    meta: {
      title: "layout",
    },
    component: Layout,
    children: [
      {
        path: "/system/systemHome",
        name: "systemHome",
        meta: {
          title: {
            zh: "个人首页",
            en: "Management background Home page",
          },
          check: ["login"],
        },
        component: () => import("@/views/system/systemHome.vue"),
        beforeEnter: (to, from, next) => {
          let user_info = JSON.parse(localStorage.getItem("user_info")) || {};
          api.operate.addSysUserOperateLog({
            customerId: user_info.customerId,
            userId: user_info.id,
            operationButtonCode: "PERSONAL_CENTER",
            operationContent: "",
            appType: "oms",
            productPriceHeaderId: "",
            loginType: "",
            remark: "",
          });
          next()
        }
      },
      {
        path: "/system/systemManagement",
        name: "systemManagement ",
        meta: { title: "系统管理页", check: ["login"], },
        component: () => import("@/views/system/systemManagement.vue")
      },
      // {
      //   path: '/system/:pathMatch(.*)',
      //   name: 'system404',
      //   component: () => import("@/views/system/404.vue")
      // },
    ]
  },
  {
    path: '/notPermission',
    name: 'notPermission',
    component: () => import("@/views/system/notPermission.vue")
  },
  // {
  //   path: '/:pathMatch([a-zA-Z0-9_-]+)/:pathMatch(.*)',
  //   name: 'NotFoundInner',
  //   redirect:{
  //     name:"system404",
  //   },
  // },
  {
    path: '/:pathMatch(.*)',
    name: 'NotFound',
    component: () => import("@/views/system/404.vue")
  },
  {
    path: '/accountManagement/bindWechat',
    name: 'bindWechat',
    component: () => import("@/views/accountManagement/bindWechat.vue")
  }
]
export default route;