<template>
  <div class="product-search-item-box"
       :class="[styleClass]">
    <div class="label" :class="[{'is-required':isRequired}]">
      <slot name="label">{{label}}</slot>
    </div>
    <slot>
      <div class="content">
        {{value}}
      </div>
    </slot>
  </div>
</template>

<script setup>
const props = defineProps({
  isRequired:Boolean,
  label:String,
  value:String,
  isWhiteStyle: {
    type:Boolean,
    default:true,
  },
  isBlackStyle:{
    type:Boolean,
    default:false,
  },
})

const styleClass = computed(()=>{
  let defaultStyle = "";
  if(props.isBlackStyle){
    return "is-black-style"
  }else if(props.isWhiteStyle){
    return "is-white-style"
  }
  return defaultStyle
})
</script>

<style scoped lang="scss">
.product-search-item-box{
  --ps-label-font-size: 16px;
  //--ps-label-font-weight: 400;
  --ps-content-font-size: 18px;
  //--ps-content-font-weight: 400;
  box-sizing: border-box;
  height: 100%;
  width: 150px;
  display: flex;
  flex-direction: column;
  .label{
    box-sizing: border-box;
    height: 40px;
    line-height: 24px;
    padding: 15px 0 0 15px;
    color: var(--ps-label-color,inherit);
    font-size: var(--ps-label-font-size,inherit);
    //font-weight: var(--ps-label-font-weight,inherit);
    &.is-required{
      &:before{
        content: "* ";
        color: #FF3A1A;
        font-size: 14px;
      }
    }
  }
  .content{
    color: var(--ps-content-color,inherit);
    font-size: var(--ps-content-font-size,inherit);
    //font-weight: var(--ps-content-font-weight,inherit);
  }
}
.is-white-style{
  display: flex;
  --ps-label-color: #999;
  --ps-content-color: #333;
}
.is-black-style{
  background-color: #4E566F;
  border-radius: 10px;
  --ps-label-color: white;
  --ps-content-color: white;
  .label{
    padding: 10px 0 0 15px;
    height: 30px;
    line-height: 20px;
  }
}
</style>