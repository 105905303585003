/**
 * 首页优势产品线路
 */
import { commonAgingUnitMapObject } from "@/utils/utils.type";
import { getTenantId, requestTenantId } from "@/utils/utils.commonTenantParams";

export function useDominantProductLine() {
  let dominantProductLineList = ref([]);
  getUserEnableFlag();
  /**
   * 请求优势产品线路列表
   */
  function getDominantProductLineList() {
    let url = "/oms/quotedPrice/listAdvantageLine"
    return post(url)
      .then(res => {
        let list = res.data || [];
        list.forEach(item => {
          if (isArray(item.cityList)) {
            item.startCityText = item.cityList.map(son => son.name).join("/");
          } else {
            item.startCityText = "";
          }
          if (isArray(item.destinationList)) {
            item.endWarehouseText = item.destinationList.map(son => son.name).join("/");
          } else {
            item.endWarehouseText = "";
          }
          if (isArray(item.goodsTypeList)) {
            item.goodsTypeText = item.goodsTypeList.map(son => son.name).join("/");
          } else {
            item.goodsTypeText = "";
          }
          item.agingText = `${item.agingFrom || ""}-${item.agingTo || ""}${commonAgingUnitMapObject[item.agingUnit] || ""}`
          item.userEnableFlag = userEnableFlag.value;
        })
        dominantProductLineList.value = list;
      })
      .catch(res => {
        dominantProductLineList.value = [];
      })
  }
  return {
    dominantProductLineList,
    getDominantProductLineList,
  }
}

let userEnableFlag = ref(true);

async function getUserEnableFlag() {
  let _tenantId = getTenantId();
  if (!_tenantId) {
    await requestTenantId()
  }
  let url = `oms/user/getUser`;
  axiosMain.get(url)
    .then(res => {
      if (res.returnCode == 200) {
        console.log("用户启用状态", res.data.enableFlag);
        userEnableFlag.value = res.data.enableFlag;
      }
    })
    .catch(res => {
      if (res.message) {
        console.log(res.message);
      }
    })
}