// rgb色值正则
const rgbRegex = /^rgb\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*\)$/
// hex(16进位)色值正则
const hexRegex = /^#[0-9a-zA-Z]{6}$/;

/**
 * 校验 color值是否有效
 * @param color {string}  颜色值
 * @param type  {string}  校验的格式 hex-16进位色彩(#2B62ED) rgb-RGB色彩(rgb(1,1,1))
 */
function checkValidColor(color,type="hex") {
  if(!color || typeof color !== "string") return false;
  if(type === "hex"){
    return hexRegex.test(color);
  }else if(type === "rgb"){
    return rgbRegex.test(color);
  } else{
    return false;
  }
}

/**
 * 传入hex色值移除第一个字符，返回处理后的文本
 * @param color {string}  hex色值（16进位色值）
 * @returns {string}
 */
function hexColorRemoveFirstStr(color) {
  return `${color||""}`.replace('#', '');
}
/**
 * 传入 hex色值，返回对应的红绿蓝16进制值
 * @param color {string}  hex色值（16进位色值）去掉最开头的#
 * @returns {{red: number, green: number, blue: number}}
 */
function hexColorToThreeColorValue(color) {
  color = hexColorRemoveFirstStr(color);
  let red = parseInt(color.slice(0, 2), 16)
  let green = parseInt(color.slice(2, 4), 16)
  let blue = parseInt(color.slice(4, 6), 16)
  return {red,green,blue};
}
/**
 * 传入 红绿蓝16进制值,，返回对应的 hex色值（16进位色值）
 * @param red {number}  hex色值（16进位色值）去掉最开头的#
 * @param green {number}  hex色值（16进位色值）去掉最开头的#
 * @param blue {number}  hex色值（16进位色值）去掉最开头的#
 * @returns {{red: string, green: string, blue: string}}
 */
function threeColorValueToHexColor(red,green,blue) {
  red = red.toString(16)
  green = green.toString(16)
  blue = blue.toString(16)

  red = complement16(red);
  green = complement16(green);
  blue = complement16(blue);
  return  {
    red,
    green,
    blue,
  }
}

/**
 * 通过 hex色值（16进位色值） 得到对应的 rgb色值
 */
function hexColorGetRgb(color,isAll = true) {
  let {red,green,blue} = hexColorToThreeColorValue(color);
  let valueStr = [red, green, blue].join(',');
  if(isAll){
    return `rgb(${valueStr})`;
  }else{
    return valueStr;
  }
}
/**
 * 将指定颜色比例淡化（light模式）
 * @param color {string}  hex色值（16进位色值）去掉最开头的#
 * @param tint  {number}  淡化比例范围0-1，（tint/100）%
 * @returns {string}  hex色值（16进位色值）
 */
export function tintColor(color, tint) {
  color = hexColorRemoveFirstStr(color);
  let {red,green,blue} = hexColorToThreeColorValue(color);
  red += Math.round(tint * (255 - red))
  green += Math.round(tint * (255 - green))
  blue += Math.round(tint * (255 - blue))

  let strRes = threeColorValueToHexColor(red,green,blue);
  return `#${strRes.red}${strRes.green}${strRes.blue}`
}

/**
 * 将指定颜色比例加深（dark模式）
 * @param color {string}  hex色值（16进位色值）去掉最开头的#
 * @param shade  {number}
 * @returns {string}  hex色值（16进位色值）
 */
export function darkColor(color, shade) {
  color = hexColorRemoveFirstStr(color);
  let {red,green,blue} = hexColorToThreeColorValue(color);

  red = Math.round((1 - shade) * red)
  green = Math.round((1 - shade) * green)
  blue = Math.round((1 - shade) * blue)

  let strRes = threeColorValueToHexColor(red,green,blue);
  return `#${strRes.red}${strRes.green}${strRes.blue}`
}

/**
 * 补齐HEX（16进位色值）的单个红绿蓝值
 * @param value
 * @returns {string|*}
 */
function complement16(value) {
  let v = `${value}`;
  if(v.length === 1) {
    return `00`
  }else if(v.length === 1){
    return `0${value}`
  }else{
    return value;
  }
}

// function examples() {
//   let colorPrimary = "#2B62ED";
//   let a = {
//     '--el-color-primary': '#2B62ED',
//     '--el-color-primary-light-3': '#6b91f2',
//     '--el-color-primary-light-5': '#95b1f6',
//     '--el-color-primary-light-7': '#bfd0fa',
//     '--el-color-primary-light-8': '#d5e0fb',
//     '--el-color-primary-light-9': '#eaeffd'
//   }
//   let b =  {
//     '--el-color-primary-shade-0': '#2b62ed',
//     '--el-color-primary-shade-1': '#2758d5',
//     '--el-color-primary-shade-2': '#224ebe',
//     '--el-color-primary-shade-3': '#1e45a6',
//     '--el-color-primary-shade-4': '#1a3b8e',
//     '--el-color-primary-shade-5': '#163177',
//     '--el-color-primary-shade-6': '#11275f',
//     '--el-color-primary-shade-7': '#001d47',
//     '--el-color-primary-shade-8': '#00142f',
//     '--el-color-primary-shade-9': '#000018',
//   };
//
//   let lightsArr = [3,5,7,8,9];
//   lightsArr.forEach(v=>{
//     a[`--el-color-primary-light-${v}`] = tintColor(colorPrimary,v/10);
//   })
//   let darkArr = Array(10).fill(1).map((item,index)=>index);
//   darkArr.forEach(v=>{
//     b[`--el-color-primary-dark-${v}`] = darkColor(colorPrimary,v/10);
//   })
//   console.log("a=",a)
//   console.log("b=",b)
// }
// examples();