const Layout = () => import("@/layout/layout.vue");
const route = [
  {
    path: "/productList",
    name: "productList",
    meta: { title: "物流商城", check: [] },
    component: () => import("@/views/productList/productList.vue"),
    beforeEnter: (to, from, next) => {
      if (to?.query.remember == 'no') {
      }
      else {

        let user_info = JSON.parse(localStorage.getItem("user_info")) || {};
        let use_btn_permission_list =
          JSON.parse(localStorage.getItem("use_btn_permission_list")) || [];
        if (
          Array.isArray(use_btn_permission_list) &&
          use_btn_permission_list.length > 0
        ) {
          api.operate.addSysUserOperateLog({
            customerId: user_info.customerId,
            userId: user_info.id,
            operationButtonCode: "LOGISTICS_SHOPPING_MALL",
            operationContent: "",
            appType: "oms",
            productPriceHeaderId: "",
            loginType: "",
            remark: "",
          });
        }

      }
      next()
    }
  },
  {
    path: "/placeOrder",
    name: "placeOrder",
    meta: { title: "下单页", check: ["login"] },
    component: () => import("@/views/placeOrder/placeOrder.vue"),
  },
  {
    path: "/placeOrderSuccess",
    name: "placeOrderSuccess",
    meta: { title: "下单成功页", check: ["login"] },
    component: () => import("@/views/placeOrder/placeOrderSuccess.vue"),
  },
  {
    path: "/Trajectory",
    name: "Trajectory",
    meta: { title: "轨迹查询", check: [] },
    component: () => import("@/views/Trajectory/Trajectory.vue"),
    beforeEnter: (to, from, next) => {
      let user_info = JSON.parse(localStorage.getItem("user_info")) || {};
      let use_btn_permission_list =
        JSON.parse(localStorage.getItem("use_btn_permission_list")) || [];
      if (
        Array.isArray(use_btn_permission_list) &&
        use_btn_permission_list.length > 0
      ) {
        api.operate.addSysUserOperateLog({
          customerId: user_info.customerId,
          userId: user_info.id,
          operationButtonCode: "TRAJECTORY_QUERY",
          operationContent: "/",
          appType: "oms",
          productPriceHeaderId: "",
          loginType: "",
          remark: "",
        });
      }

      next()
    }
  },
  {
    path: "/userGuide",
    name: "userGuide",
    meta: { title: "用户指南", check: [] },
    component: () => import("@/views/userGuide/userGuide.vue"),
    beforeEnter: (to, from, next) => {
      let user_info = JSON.parse(localStorage.getItem("user_info")) || {};
      let use_btn_permission_list =
        JSON.parse(localStorage.getItem("use_btn_permission_list")) || [];
      if (
        Array.isArray(use_btn_permission_list) &&
        use_btn_permission_list.length > 0
      ) {
        api.operate.addSysUserOperateLog({
          customerId: user_info.customerId,
          userId: user_info.id,
          operationButtonCode: "USER_GUIDE",
          operationContent: "",
          appType: "oms",
          productPriceHeaderId: "",
          loginType: "",
          remark: "",
        });
      }

      next()
    }
  },
  {
    path: "/companyIntroduction",
    name: "companyIntroduction",
    meta: { title: "公司介绍", check: [] },
    component: () =>
      import("@/views/companyIntroduction/companyIntroduction.vue"),
    beforeEnter: (to, from, next) => {
      let user_info = JSON.parse(localStorage.getItem("user_info")) || {};
      let use_btn_permission_list =
        JSON.parse(localStorage.getItem("use_btn_permission_list")) || [];
      if (
        Array.isArray(use_btn_permission_list) &&
        use_btn_permission_list.length > 0
      ) {
        api.operate.addSysUserOperateLog({
          customerId: user_info.customerId,
          userId: user_info.id,
          operationButtonCode: "COMPANY_INTRODUCTION",
          operationContent: "",
          appType: "oms",
          productPriceHeaderId: "",
          loginType: "",
          remark: "",
        });
      }

      next()
    }

  },
  {
    path: "/cityPartner",
    name: 'cityPartner',
    meta: {
      title: "城市合作伙伴"
    },
    component: () => import("@/views/cityPartner/index.vue")
  },
  {
    path: "/news-center",
    name: 'newsCenter',
    meta: {
      title: "新闻中心"
    },
    component: () => import("@/views/news-center/index.vue")
  },
  {
    path: "/news-detail",
    name: 'newsDetail',
    meta: {
      title: "新闻详情"
    },
    component: () => import("@/views/news-center/detail/detail.vue")
  },
  {
    path: "/order",
    name: "order",
    meta: {
      title: "我的订单",
      permissionCode: ["O_myOrder"]
    },
    component: Layout,
    children: [
      {
        path: "/order/specialLineOrder",
        name: "specialLineOrder",
        meta: {
          title: "专线订单",
          check: ["login", "permission"],
          // permissionCode: ["Special line order"],
          permissionCode: ["O_directOrderManage"],
          keepAlive: true,
        },
        component: () => import("@/views/order/specialLineOrder.vue"),
        // children: [
        //   {
        //     path: "/order/specialLineOrder/detail",
        //     name: "specialLineOrderDetail",
        //     meta: { title: "订单详情", check: ["login"] },
        //     component: () => import("@/views/order/specialLineOrderDetail.vue"),
        //   }
        // ]
      },
      {
        path: "/order/specialLineOrder/detail",
        name: "specialLineOrderDetail",
        meta: {
          title: "专线订单详情",
          check: ["login", "permission"],
          // permissionCode: ["Special line order"],
          permissionCode: ["O_directOrderManage"]
        },
        component: () => import("@/views/order/specialLineOrderDetail.vue"),
      },
    ],
  },
  {
    path: "/financialManagement",
    name: "financialManagement",
    meta: {
      title: "财务管理",
      permissionCode: ["O_financialManage"]
    },
    component: Layout,
    children: [
      // {
      //   path: "/financialManagement/rechargeManagement",
      //   name: "rechargeManagement",
      //   meta: {
      //     title: "充值管理",
      //     check: ["login", "permission"],
      //     // permissionCode: ["Recharge management"],
      //     permissionCode: ["O_rechargeManage"],
      //   },
      //   component: () =>
      //     import("@/views/financialManagement/rechargeManagement.vue"),
      // },
      {
        path: "/financialManagement/bill",
        name: "bill",
        meta: {
          title: "账单",
          check: ["login", "permission"],
          // permissionCode: ["Bill"],
          permissionCode: ["O_bill"],
        },
        component: () => import("@/views/financialManagement/bill.vue"),
      },
      // {
      //   path: "/financialManagement/anAccountStatement",
      //   name: "anAccountStatement",
      //   meta: { title: "账户流水", check: ["login", "permission"], permissionCode: ["Account running water"], },
      //   component: () =>
      //     import("@/views/financialManagement/anAccountStatement.vue"),
      // },
    ],
  },
  {
    path: "/dispatcherCenter",
    name: "dispatcherCenter",
    meta: {
      title: "基础资料管理",
      check: ['login']
    },
    component: Layout,
    children: [
      {
        path: "/dispatcherCenter/dispatcherManage",
        name: "dispatcher",
        meta: { title: "地址管理", check: ["login", "permission"], permissionCode: ["Address management"], },
        component: () =>
          import("@/views/dispatcherCenter/dispatcherManage.vue"),
      }
    ]

  },
  {
    path: "/myMessage",
    name: "myMessage",
    meta: {
      title: "我的消息",
      check: ['login']
    },
    component: Layout,
    children: [
      {
        path: "/myMessage/trackManagement",
        name: "trackManagement",
        meta: { title: "轨迹订阅管理", check: ["login", "permission"], permissionCode: ["trackManagement"] },
        component: () =>
          import("@/views/myMessage/trackManagement.vue"),
      }
    ]

  },
  {
    path: "/accountManagement",
    name: "accountManagement",
    meta: {
      title: "账号管理",
      permissionCode: [""]
    },
    component: Layout,
    children: [
      {
        path: "/accountManagement/securityCenter",
        name: "securityCenter",
        meta: { title: "安全中心", check: ["login", "permission"], permissionCode: ["Security center"], },
        component: () =>
          import("@/views/accountManagement/securityCenter.vue"),
      },
      {
        path: "/accountManagement/roleManagement",
        name: "roleManagement",
        meta: {
          title: "角色管理",
          check: ["login", "permission"],
          // permissionCode: ["Role management"],
          permissionCode: ["O_roleManage"],
        },
        component: () => import("@/views/accountManagement/roleManagement.vue"),
      },
      {
        path: "/accountManagement/subAccountManagement",
        name: "subAccountManagement",
        meta: {
          title: "子账号管理",
          check: ["login", "permission"],
          // permissionCode: ["Sub-account management"],
          permissionCode: ["O_subAccountManage"],
        },
        component: () =>
          import("@/views/accountManagement/subAccountManagement.vue"),
      },
    ],
  },
  {
    path: "/openPlatform",
    name: "openPlatform",
    meta: {
      title: "开放平台管理",
    },
    component: Layout,
    children: [
      {
        path: "/openPlatform/apiLinkSetting",
        name: "apiLinkSetting",
        meta: { title: "API对接设置", check: ["login", "permission"], permissionCode: ["Api docking settin"], },
        component: () =>
          import("@/views/openPlatform/apiLinkSetting.vue"),
      }
    ],
  }

];
export default route;
