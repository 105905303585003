<template>
  <div class="search-select-zip-code">
    <div class="search-row">
      <el-input
        v-model="searchText"
        :placeholder="placeholder"
        class="search-text"
        clearable
        @keyup.enter="clickSearch"
        @input="currentTextAlreadySearch = false"
      />
      <el-button
        type="primary"
        class="search-btn"
        :disabled="!searchText"
        @click="clickSearch"
      >
        {{ btnText }}
      </el-button>
    </div>
    <slot name="searchBottom">
      <p style="color: #999;margin-top: 10px;font-size: 12px;">邮编格式：1~5位数字 或 "12345-6789"格式的9位数字</p>
    </slot>
    <template v-if="list.length">
<!--      <p style="color: #333;margin-top: 10px;">查询结果：{{list.length}}条</p>-->
      <div style="margin-top: 12px;"></div>
      <div class="search-result">
        <div
          class="item"
          @click="clickItem(item,index)"
          v-for="(item,index) in list"
          :key="index"
        >{{item.namec}}</div>
      </div>
    </template>
    <template v-else>
      <template v-if="searchText && !listLoading && currentTextAlreadySearch">
        <div class="search-result color-danger" style="margin-top: 12px;">未查找到匹配"{{searchText}}"的邮编</div>
      </template>
    </template>
  </div>
</template>

<script>
import {checkInputZipCode} from "@/utils/utils.selectPlace";

export default {
  name: "searchSelectZipCode",
  emits: [
    'change',
  ],
  props:{
    placeholder:{
      type:String,
      default:"请输入邮编",
    },
    btnText:{
      type:String,
      default:"搜索",
    },
    handlerClickBtn:{
      type:Function,
    },
    countryId:[String,Number],
    isQuery:{ //是否检索输入结果，目的国为非美国时，需要向后台检索，
      type:Boolean,
      default:true,
    },
  },
  data(){
    return {
      searchText:"",
      list:[],
      listLoading:false,
      currentTextAlreadySearch:false,
    }
  },
  methods:{
    /**
     * 确认搜索
     */
    clickSearch(){
      let text = this.searchText||"";
      if(typeof this.handlerClickBtn === "function") {
        this.handlerClickBtn(text);
      } else {
        let result = checkInputZipCode(text);
        if(!result) {
          this.$message.error("邮编只能为1~5位数字或 12345-6789格式的9位邮编");
          return false
        }
        if(this.isQuery){
          return this.getList(text);
        }else{
          let localItem = {
            namec:text,
            id:text,
          }
          this.clickItem(localItem);
        }
      }
    },
    /**
     * 获取邮编列表
     */
    getList(text=""){
      const data = {
        countryId:this.countryId||"",
        zipCode:text || "",
      };
      const url = "/api/datBasicZipCode/getCountryZipCode";
      this.listLoading = true;

      this.listLoading = false;
      // return this.list = this.getTestList(text);
      return axiosMain.get(url, {params:data})
        .then(res=>{
          this.listLoading = false;
          this.currentTextAlreadySearch = true;
          let list = res.data||[];
          list.forEach(item=>item.namec = item.zipCode);
          this.list = list;
          return res
        })
        .catch(res=>{
          this.listLoading = false;
          this.currentTextAlreadySearch = true;
          this.list = [];
          // return Promise.reject(res);
        })
    },
    getTestList(text=""){
      let list = [];
      let x = Math.round(Math.random() * 10);
      if(x>=5) return list;
      list = Array(20).fill(text).map((t,i)=>{
        let n = Math.round(Math.random() * 1000);
        t = `${t}_${n}_${i}`;
        return {namec:t,id:t};
      })
      return list;
    },
    /**
     * 点击选择
     * @param item
     * @param index
     */
    clickItem(item,index){
      let data = Object.assign({},item);
      this.$emit("change",data);
    },
  },
}
</script>

<style scoped lang="scss">
.search-select-zip-code{
}
.search-row{
  display: flex;
  .search-text{
    flex: 1;
  }
  .search-btn{
    margin-left: 12px;
    width: 100px;
  }
}
.search-result{
  max-height: 160px;
  overflow-y: auto;
  .item{
    padding: 0 10px;
    line-height: 40px;
    height: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    //border-top: 1px dashed #eee;
    //border-bottom: 1px dashed #eee;
    & + .item{
      border-top: 1px dashed #eee;
    }
    &:hover{
      background-color: #F7F7F7;
      color: #AB434A;
    }
  }
}
</style>
