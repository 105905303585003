/**
 * 获取导入路径的模块名称
 */
export function getImportPathModuleName(path=""){
  // path = "./modules/login.js";
  let arr = path.split("/");
  let last = arr[arr.length-1]||"";
  return last.replace(/.[a-z]+$/ig,"");
}

/**
 * 处理每个已导入的模块
 * @param modules
 * @param callback  {function(path:string,item)}
 */
export function forEachImportedModules(modules,callback){
  if(typeof callback === "function"){
    for (const path in modules) {
      let item = modules[path];
      callback(path,item);
    }
  }
}