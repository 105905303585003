<template>
  <div class="g-tabs" :class="[styleClass]">
    <div
        class="g-tabs--item"
        :class="{
          'is-active':item.value === modelValue,
          'is-disabled':item.disabled,
        }"
        @click="handleChange(item,index)"
        v-for="(item,index) in list"
        :key="`${ item.value ?  item.value : index}`"
    >
      <slot :item="item" :index="index">
        {{item.label}}
      </slot>
    </div>
  </div>
</template>

<script setup>
const emit = defineEmits(["update:modelValue","change"]);
const props = defineProps({
  list:{
    type:Array,
    default:()=>[],
  },
  modelValue:[String,Number],
  isWhiteStyle:Boolean,
})
const styleClass = computed(()=>{
  let defaultStyle = "is-primary-style";
  if(props.isWhiteStyle){
    return "is-white-style"
  }
  return defaultStyle
})

// let current = ref("");
// watch(() => props.modelValue, newValue => current.value = newValue );

/**
 * 切换tabs回调
 * @param item
 * @param index
 */
function handleChange(item,index) {
  if(item.disabled) return false;
  // current.value = item.value;
  emit("update:modelValue",item.value);
  emit("change",item.value);
}
</script>

<style scoped lang="scss">
.g-tabs{
  display: flex;
  height: 40px;
  &--item{
    position: relative;
    z-index: 1;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.4s ease;
    & + & {
      margin-left: 30px;
    }
    &:before{
      position: absolute;
      z-index: 1;
      bottom: 0;
      left: 10%;
      right: 10%;
      content: "";
      height: 1px;
      transition: 0.4s ease;
    }
    //color: white;
    &.is-active{
      //color: white;
       &:before{
         //background-color: white;
       }
    }
    &.is-disabled{
      cursor: not-allowed;
      color: #999;
    }
  }
}


.is-primary-style{
  .g-tabs{
    &--item{
      color: #333;
      &:not(.is-disabled):hover{
        color: var(--el-color-primary);
      }
      &.is-active{
        color: var(--el-color-primary);
        &:before{
          background-color: var(--el-color-primary);
        }
      }
    }
  }
}
.is-white-style{
  .g-tabs{
    &--item{
      color: white;
      &.is-active{
        &:before{
          background-color: white;
        }
      }
    }
  }
}
</style>