<template>
  <div>
    <el-popover title="" :disabled="showTool" :content="contentInfo" width="auto" :effect="effect" :trigger="trigger"
      popper-class="g-long-popper" placement="bottom">
      <template #reference>
        <span class="contentWidth" :style="{ cursor: showTool ? 'auto' : 'pointer' }">
          <span ref="contentInfoRef">{{ contentInfo }}</span>
        </span>
      </template>
    </el-popover>
  </div>
</template>


<script setup>
const props = defineProps({
  contentInfo: {
    type: String,
    default: ''
  },
  trigger: {
    type: String,
    default: 'hover' // hover | click | focus | manual
  },
  effect: {
    type: String,
    default: 'dark'
  }
})
const showTool = ref(false)
const contentInfoRef = ref(null)
watch(
  () => props.contentInfo,
  val => {
    nextTick(() => {
      if (val) {
        const tag = contentInfoRef.value
        if (!tag) return false
        const parentWidth = tag.parentNode.offsetWidth // 获取元素父级可视宽度
        const parentHeight = tag.parentNode.offsetHeight // 获取元素父级可视宽度
        const contentWidth = tag.offsetWidth // 获取元素可视宽度
        const contentHeight = tag.offsetHeight // 获取元素可视宽度
        console.log(parentWidth, contentWidth)
        // this.showTool = contentWidth <= parentWidth
        showTool.value = contentHeight <= parentHeight && contentWidth <= parentWidth
      } else {
        showTool.value = false
      }
    })
  },
  { immediate: true }
)
</script>

<style>
  .g-long-popper.el-popover {
    max-width: 400px;
		--el-text-color-regular: #333333;
  }
</style>
<style scoped lang="scss">
.contentWidth {
  max-width: 100%;
  line-height: 22px;
  max-height: 66px;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-break: break-word;
}
</style>
