/**
 * 获取短信验证码逻辑
 */
import {ElMessage} from "element-plus";
import {$t} from "@/language";

/**
 * 获取短信验证码相关
 * @param getCodeType {string} login-登录验证码 register-注册验证码
 * @param labelText {string} 提示的label文本
 */
export function useGetPhoneCode(getCodeType,labelText="登录"){
  // 每次获取读秒总计数 单位s
  let timeTotal = 60;
  // 计时器刷新间隔 单位ms
  let timeSpace = 1000;
  // 短信验证码 计时器指针
  let phoneCodeTimeOut = -1;
  // 短信验证码 倒计时间
  let phoneCodeCountDown = ref(0);
  // 记录短信验证码
  let recordCode =[];
  /**
   * 能否发送短信验证码
   * @type {ComputedRef<boolean>}
   */
  let canSendPhoneCode = computed(()=>{
    // let phone = unref() || "";
    return unref(phoneCodeCountDown) <= 0;
  })
  // 获取短信验证码方法
  let getPhoneFunction = (phone) => Promise.resolve();
  if(getCodeType === "login"){
    getPhoneFunction = api.user.getLoginPhoneCode;
  }else if(getCodeType === "register"){
    getPhoneFunction = api.user.getRegisterPhoneCode;
  }else{

  }


  // 清除计时
  function clearPhoneCodeCountDown() {
    clearInterval(phoneCodeTimeOut);
    phoneCodeCountDown.value = 0;
  }
  // 开始计时
  function startPhoneCodeCountDown(){
    phoneCodeCountDown.value = timeTotal;
    return phoneCodeTimeOut = setInterval(()=>{
      phoneCodeCountDown.value --;
      if(unref(phoneCodeCountDown) <= 0) clearPhoneCodeCountDown();
    },timeSpace);
  }

  // 点击获取短信验证码按钮
  let clickPhoneCode = debounce(sendPhoneCode,200);
  /**
   * 发送获取短信验证码请求
   */
  function sendPhoneCode(phone){
    if(!phone) {
      ElMessage.warning(`请传入正确手机号`)
      return false;
    }
    if (unref(phoneCodeCountDown) > 0) {
      ElMessage.warning(`请${unref(phoneCodeCountDown)}秒后再发送`)
      return false;
    }
    return getPhoneFunction(phone)
      .then(res=>{
        ElMessage.success(`发送${labelText}短信成功`)
        clearPhoneCodeCountDown();
        startPhoneCodeCountDown()
        let code = res.data || "";
        if(code) recordCode.unshift(code);
        return res;
      })
      .catch(res=>{
        ElMessage.error(res.message||$t("system.fail"))
      })
  }
  onBeforeUnmount(()=>{
    clearPhoneCodeCountDown();
  })
  return {
    phoneCodeTimeOut,
    phoneCodeCountDown,
    canSendPhoneCode,
    clearPhoneCodeCountDown,
    startPhoneCodeCountDown,
    clickPhoneCode,
    sendPhoneCode,
  }
}