/**
 * 判断传入值是否是有效对象
 * @param value
 * @return boolean
 */
export function isArray(value) {
  if (typeof Array.isArray === "function") {
    return Array.isArray(value);
  } else {
    return Object.prototype.toString.call(value) === "[object Array]";
  }
}
/**
 * 判断传入值是否是有效对象
 * @param value
 * @return boolean
 */
export function isObject(value) {
  return Object.prototype.toString.call(value) === '[object Object]';
}
/**
 * 判断为空
 * @param val
 * @returns {boolean}
 */
export const isEmpty = function(val) {
  // null or undefined
  if (val === null) return true;

  if (typeof val === 'boolean') return false;

  if (typeof val === 'number') return !val;

  if (val instanceof Error) return val.message === '';

  switch (Object.prototype.toString.call(val)) {
    // String or Array
    case '[object String]':
    case '[object Array]':
      return !val.length;

    // Map or Set or File
    case '[object File]':
    case '[object Map]':
    case '[object Set]': {
      return !val.size;
    }
    // Plain Object
    case '[object Object]': {
      return !Object.keys(val).length;
    }
  }

  return false;
};
/**
 * 清空对象中值为空的key
 * @param data
 * @returns {*}
 */
export function objectRemoveEmpty (data) {
  let keys = Object.keys(data).filter(res=>empty(data[res]));
  keys.forEach(key=>{
    delete data[key]
  });
  function empty(res){
    if(res === 0) return false;
    return isEmpty(res)
  }
  return data
}

/**
 * 重复拼接一段文本
 * @param text
 * @param num
 * @param space
 * @return {string}
 */
export function repetitionText(text,num,space) {
  return Array(num).fill(text).join(space);
}


let _local_id_index = 1;
/**
 * 生产本地唯一id
 * @return {string}
 */
export function getLocalId(){
  let i = _local_id_index++;
  return `${i}_${Date.now()}_${Math.round(Math.random() * 100)}`;
}

/**
 * 大洲排序并删除南极洲
 * @param list {Array}
 * @param key {string}
 * @return {*[]}
 */
export function continentListSort(list = [], key = 'continentName') {
  const sortedContinent = ['全部', '亚洲', '欧洲', '非洲', '北美洲', '南美洲', '大洋洲']
  list = list.filter(continentItem => continentItem[key] !== '南极洲')
  list = list.sort((a, b) => {
    const index1 = sortedContinent.findIndex(item => a[key] === item)
    const index2 = sortedContinent.findIndex(item => b[key] === item)
    return index1 - index2
  })
  return list
}
/**
 * 保留几位小数，并且后一位小数四舍五入、向上/向下取整
 * @param num {number|string}  要处理的值
 * @param precisionLength {number}  保留几位小数
 * @param roundOffType {Number?} 取整进位规则 1 四舍五入 2 向上进1 3 向下取整
 * @returns {number}
 */
export function decimalsCeil(num,precisionLength=3,roundOffType){
  if(typeof num === "number" && num) {

  }else if(typeof num === "string" && num){
    let n = parseFloat(num);
    if(!n) return n;
    num = n;
  }else{
    return num;
  }
  let hasDecimals = num % 1 > 0;
  const precision = Math.pow(10, precisionLength);
  if(!hasDecimals) return  num;
  let val = num.toFixed(precisionLength + 1);
  val = val * precision;
  if(roundOffType === 1){
    val = Math.round(val);
  }else if(roundOffType === 2){
    val = Math.ceil(val);
  }else if(roundOffType === 3){
    val = Math.floor(val);
  }
  val = val / precision;
  return val;
}

import {convert} from "html-to-text";
/**
 * 将html文本转为text文本（去除标签）
 */
export function htmlStringToTextString(htmlStr="",options,maxLength=0){
  if(typeof htmlStr !== "string") htmlStr =`${htmlStr||""}`;
  let textStr = "";
  // textStr = htmlStr.replace(/([\s]+)?<\/?[a-zA-Z]+>([\s]+)?/ig,"");
  textStr = convert(htmlStr,Object.assign({
    wordwrap:false,
  },options));
  if(maxLength){
    if(textStr.length > maxLength) textStr = textStr.slice(0,maxLength);
  }
  return textStr;
}