let route = [
  {
    path: '/examplesView',
    name: 'examplesView',
    meta: {
      title: "examplesView",
    },
    component: () => import('../../../examples/examplesView.vue'),
    children:[
      {
        path: '/customTableExamples',
        name: 'customTableExamples',
        meta:{
          title:"自定义列表封装组件——使用示例",
        },
        component: () => import('../../../examples/customTable/customTableExamples.vue')
      },{
        path: '/uploadImageExamples',
        name: 'uploadImageExamples',
        meta:{
          title:"自定义上传图片封装组件——使用示例",
        },
        component: () => import('../../../examples/uploadImage/uploadImageExamples.vue')
      },{
        path: '/listTemplatePage',
        name: 'listTemplatePage',
        meta:{
          title:"列表模板页面——示例",
        },
        component: () => import('../../../examples/listTemplatePage/listTemplatePage.vue')
      },
    ],
  },
]
if(["production"].includes(import.meta.env.MODE)){
  route = [];
}
export default route;