<template>
  <div class="home-news-item" @click="clickMore">
    <div class="title text-over-hide">{{ title }}</div>
    <div class="description">{{ description }}</div>
    <div class="footer">
      <div class="time">{{ time }}</div>
      <el-link class="more" type="primary">MORE&nbsp;></el-link>
    </div>
  </div>
</template>

<script setup>
import { ElMessage } from "element-plus";

const props = defineProps({
  id: [String, Number],
  title: String,
  description: String,
  time: [String, Number],
  // "YYYY-MM-DD HH:mm:ss"
  // format:{
  //   type:String,
  //   default:"YYYY-MM-DD HH:mm",
  // },
});
function clickMore() {
  let user_info = JSON.parse(localStorage.getItem("user_info")) || {};
  let use_btn_permission_list =
    JSON.parse(localStorage.getItem("use_btn_permission_list")) || [];
  if (
    Array.isArray(use_btn_permission_list) &&
    use_btn_permission_list.length > 0
  ) {
    api.operate.addSysUserOperateLog({
      customerId: user_info.customerId,
      userId: user_info.id,
      operationButtonCode: "NEWS_CENTER",
      operationContent: `${props.title}`,
      appType: "oms",
      productPriceHeaderId: "",
      loginType: "",
      remark: "",
    });
  }

  // ElMessage.info("新闻中心开发中...")
  router.push(`/news-detail?id=${props.id}`);
}
</script>

<style scoped lang="scss">
.home-news-item {
  box-sizing: border-box;
  width: 100%;
  height: 150px;
  background-color: #ffffff;
  border-radius: 12px;
  padding: 15px 20px 0;
  cursor: pointer;

  &:hover {

    .title,
    .description {
      color: var(--el-color-primary);
    }
  }
}

.title {
  font-size: 14px;
  font-weight: 600;
  color: #333333;
  margin-bottom: 10px;
}

.description {
  font-size: 14px;
  line-height: 24px;
  // height: 50px;
  font-weight: 500;
  color: #666666;
  margin-bottom: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.time {
  font-size: 16px;
  // font-weight: 500;
  color: #999999;
}

.more {
  font-size: 16px;
  font-weight: 500;
}
</style>