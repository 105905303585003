<template>
  <!--首页：顶部banner-->
  <template v-if="list.length > 1">
    <el-carousel class="banner-swiper" trigger="click" :interval="bannerSpeed * 1000" :height="height"
      :indicator-position="indicatorPosition ? '' : 'none'">
      <el-carousel-item v-for="(item, index) in list" :key="item.id">
        <div class="banner-swiper--item" :class="{ 'is-pointer': !!item.target }" @click="clickBanner(item, index)">
          <img :src="item.bannerUrl" :alt="item.name" />
        </div>
      </el-carousel-item>
    </el-carousel>
  </template>
  <template v-else-if="list.length === 1">
    <div class="banner-swiper" :style="`height:${height};`">
      <div class="banner-swiper--item" v-for="(item, index) in list" :key="item.id"
        :class="{ 'is-pointer': !!item.target }" @click="clickBanner(item, index)">
        <img :src="item.bannerUrl" :alt="item.name" />
      </div>
    </div>
  </template>
</template>

<script setup>
const props = defineProps({
  list: {
    type: Array,
    default: () => { },
  },
  bannerSpeed: {
    type: Number,
    default: 3
  },
  height: {
    type: String,
    default: "100vh",
  },
  // 是否显示指示器
  indicatorPosition: {
    type: Boolean,
    default: false,
  },
})

// 点击banner
function clickBanner(item, index) {
  if (isObject(item) && item.target) {
    router.push(item.target);
  }
}
</script>

<style scoped lang="scss">
.banner-swiper {
  &--item {
    box-sizing: border-box;
    display: block;
    width: 100%;
    height: 100%;

    img {
      box-sizing: border-box;
      display: block;
      border: none;
      width: 100%;
      height: 100%;
      object-fit: cover;
      /* object-fit: none */
    }
  }
}
</style>