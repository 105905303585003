import router from "@/router";
import {ElMessage} from "element-plus";
import {makePy} from "@/utils/pinyin";

/**
 * 校验参数并跳转下单页
 * @param options {{
    startCityName:string,
    startCityId:string|number,
    endCountryName:string,
    endCountryId:string|number,
    endWarehouseName:string,
    endWarehouseId:string|number,
    id:string|number,
  }}
 * @return {boolean}
 */
export function toPlaceOrderPage(options){
  let data = {
    // 物流产品类型
    productType:"",
    // 起运城市参数
    startCityName:"",
    startCityId:"",
    // 目的国家
    endCountryName:"",
    endCountryId:"",
    endCountryCode:"",
    privateAddressType:"",
    // 目的地（目的仓库）
    endWarehouseName:"",
    endWarehouseId:"",
    isPrivateAddress:false,
    // 产品报价id
    id:"",
  }
  Object.assign(data,options);
  if(!data.productType){
    ElMessage.error("缺少物流产品类型");
    return false
  }
  if(!data.startCityName){
    ElMessage.error("缺少起运地名称");
    return false
  }
  if(!data.startCityId){
    ElMessage.error("缺少起运地id");
    return false
  }
  if(!data.endCountryName){
    ElMessage.error("缺少目的国名称");
    return false
  }
  if(!data.endCountryId){
    ElMessage.error("缺少目的国id");
    return false
  }
  if(!data.endCountryCode){
    ElMessage.error("缺少目的国代码");
    return false
  }
  if(!data.privateAddressType){
    ElMessage.error("缺少目的国私人地址类型");
    return false
  }
  // if(!data.endWarehouseId && data.endWarehouseId !== 0){
  //   ElMessage.error("缺少目的地id");
  //   return false
  // }
  if(data.endWarehouseId === undefined || data.endWarehouseId === null || data.endWarehouseId === ""){
    delete data.endWarehouseId;
    delete data.endWarehouseName;
    delete data.isPrivateAddress;
  }
  if(['all',"ALL"].includes(data.endWarehouseId)){
    // data.endWarehouseId = "";
    // data.endWarehouseName = "";
    delete data.endWarehouseId;
    delete data.endWarehouseName;
  }
  if(!data.id){
    ElMessage.error("缺少报价id");
    return false
  }
  // router.push({
  //   path:"/placeOrder",
  //   query:data,
  // })
  // const routerData = router.resolve({
  //     path:"/placeOrder",
  //     query:data,
  // });
  // window.open(routerData.href,"_blank");
  localStorage.setItem('place_order_data', JSON.stringify(data))
  window.open(location.origin + '/placeOrder', '_blank')
}
/**
 * 以首字符将fba或私人地址城市列表分组
 * @param list {array<object>} 原始列表
 * @param matchStringKey  {string}  原始列表中用来匹配字符串的key
 * @param groupLabelKey  {string}   存储为分组的label key
 * @param groupChildrenKey  {string}  存储为分组的children key
 * @return {*[]}
 */
export function firstCharacterGroupingEndWarehouseList(list = [],matchStringKey="namec",groupLabelKey="code",groupChildrenKey="list") {
  let firstRecord = [];
  let result = [
    // {
    //   code:"",
    //   list: [
    //     {id:"",namec:""},
    //   ],
    // },
  ];
  if(isArray(list) && list.length){
    list.forEach(item=>{
      let str = `${item[matchStringKey]||""}`.toUpperCase();
      const firstStr = makePy(str);
      let index = firstRecord.indexOf(firstStr);
      if(index >=0){
        result[index][groupChildrenKey].push(item);
      }else{
        firstRecord.push(firstStr)
        result.push({
          [groupLabelKey]:firstStr,
          [groupChildrenKey]:[
            item
          ],
        })
      }
    })
  }
  return result;
}

/**
 * 根据warehouseType将FBX仓分组
 * @param list {array<object>} 原始列表
 * @param matchStringKey {string}  原始列表中用来匹配字符串的key
 * @param groupLabelKey {string}   存储为分组的label key
 * @param groupChildrenKey {string}  存储为分组的children key
 * @return {*[]}
 */
export function warehouseTypeGroupingEndWarehouseList(list = [],matchStringKey="warehouseType",groupLabelKey="warehouseType",groupChildrenKey="list") {
  let result = []
  let typeRecord = []
  if (isArray(list) && list.length) {
    list.forEach(item => {
      let warehouseType = item[matchStringKey]
      let warehouseTypeName = item.warehouseTypeName
      const index = typeRecord.indexOf(warehouseType)
      if (index >= 0) {
        result[index][groupChildrenKey].push(item)
      } else {
        typeRecord.push(warehouseType)
        result.push({
          [groupLabelKey]: warehouseType,
          [groupChildrenKey]: [item],
          warehouseTypeName
        })
      }
    })
  }
  result.forEach(item => {
    item[groupChildrenKey] = firstCharacterGroupingEndWarehouseList(item[groupChildrenKey])
  })
  console.log(result)
  return result
}
