const system = {
  zh:{
    systemName:"XXX应用系统",
    success:`请求成功`,
    fail:`请求失败`,
    confirmOperation:"确认操作",
  },
  en:{
    systemName:"XXX system",
    success:`request success`,
    fail:`request fail`,
    confirmOperation:"confirm operation",
  }
}
export default system