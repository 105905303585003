import { commonAgingUnitMapObject } from "@/utils/utils.type";

/**
 * 处理物流产品详情
 * @param resData
 */
export function disposeProductDetails(resData) {
  console.log(resData, '777777777');

  // 头程/全包 报价
  let head = disposeTheadList(resData.headPriceSectionList || [], 'head', resData.setRatioByPartitionFlag, resData.productPriceFlag);
  if (!resData.productPriceFlag) {
    resData.headPriceSectionList.forEach(item => {
      item.startCityName = item.cityList.map(c => c.name).join('/')
    })
  }
  // 派送
  let delivery = disposeTheadList(resData.deliveryPriceSectionList || [], "delivery");
  resData.headThead = head.thead;
  resData.deliveryThead = delivery.thead;

}

/**
 * 处理报价详情的 头程/派送表头 配置项
 * @param list
 * @param type  {string} head/delivery
 * @param setRatioByPartitionFlag
 * @param productPriceFlag {boolean}
 * @returns {{thead: {prop: string, label: string}[]}}
 */
export function disposeTheadList(list, type = "head", setRatioByPartitionFlag, productPriceFlag = true
) {
  // debugger
  let priceThead = [];
  list.forEach((item, index) => {
    disposeItem(item, index);
    if (!isArray(item.priceList)) item.priceList = [];
    let priceList = item.priceList;

    if (index === 0) {
      let chargeType = item.chargeType;
      priceThead = disposePriceRegionThead(priceList, chargeType);
    }
    priceList.forEach((son, sonIndex) => {
      if (item.needInquiryFlag) {
        item[`price${sonIndex}`] = "单询";
      } else if (!son.price && sonIndex === 0) {
        item[`price${sonIndex}`] = "/";
      } else {
        item[`price${sonIndex}`] = son.price;
      }
    })
  })
  let thead = [];
  if (type === "head") {
    const setDataByPartition = setRatioByPartitionFlag ? [{ label: `${list[0].chargeType === 'KG' ? '体积重比率' : '重量转换比率'}`, prop: "volumeWeightRatio" }] : []
    thead = [
      { label: productPriceFlag ? "目的国分区" : "起运城市", prop: productPriceFlag ? "priceSectionName" : "startCityName", },
      { label: "拆货城市", prop: "dismantlingCity", },
      { label: "可走货物类型", prop: "goodsType", },
      ...priceThead,
      ...setDataByPartition,
      { label: "币种", prop: "currency", },
      { label: "提取时效", prop: "extractionAgingText", },
      { label: "整体时效", prop: "agingText", },
    ];
  } else if (type === "delivery") {
    thead = [
      { label: "目的分区", prop: "priceSectionName", },
      ...priceThead,
      { label: "币种", prop: "currency", },
      { label: "派送时效", prop: "agingText", },
    ];
  } else {
    thead = [
      ...priceThead,
    ];
  }
  return {
    thead
  };
}

function disposeItem(item, index) {
  console.log(item, index)
  if (isObject(item)) {
    if (isArray(item.goodsTypeList)) {
      // 可走货物类型
      item.goodsType = item.goodsTypeList.map(item => item.name).join(",");
    } else {
      item.goodsType = "";
    }
    // 整体时效
    item.agingText = `${item.agingFrom || ""}-${item.agingTo || ""} ${commonAgingUnitMapObject[item.agingUnit] || ""}`;
    if (item.agingShowFlag !== true) {
      item.agingText = "/";
    }
    // 提取时效
    item.extractionAgingText = `${item.extractionAgingFrom || ""}-${item.extractionAgingTo || ""} ${commonAgingUnitMapObject[item.extractionAgingUnit] || ""}`;
    if (item.extractionAgingShowFlag !== true) {
      item.extractionAgingText = "/";
    }
  }
}

/**
 * 获取价格分区表头列表
 */
export function disposePriceRegionThead(priceList, chargeType) {
  return priceList.map((son, sonIndex) => {
    let start = son.sectionStartNum;
    if (start === 0) start = "0";
    let end = son.sectionEndNum;
    let label = "";
    if (end) {
      label = `${start || ""}-${end || ""}${chargeType || ""}`;
    } else {
      label = `${start || ""}${chargeType || ""}`;
    }
    return {
      label,
      isPrice: true,
      prop: `price${sonIndex}`,
      // width:160,
      flag: true,
      calculateMethod: calculateMethodOption(son.calculateMethod)
    }
  })
}
// 1 首重金额 2 续重单价 3 单价 4 总价
function calculateMethodOption(val) {
  switch (val) {
    case 1:
      return '首重金额';
    case 2:
      return '续重单价';
    case 3:
      return '单价';
    case 4:
      return '总价';
  }
}
