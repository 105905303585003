import axiosMain from "@/axios";
import {huoDaiPinTargetName} from "../../../config/server";
import queryString from "query-string";

/**
 * 获取 新闻分类列表
 */
export function getNewsClassifyList(){
  let url = huoDaiPinTargetName + "/cms/findCategoryList"
  let data = {
    // 新闻模块的代码
    appCode:"huodainews",
  };
  return axiosMain.post(url,data,headersContentTypeFormData)
    .then(res=>{
      let list = res.data || [];
      list.forEach(item=>{
        item.label = item.categoryName||"";
        item.value = item.id||"";
      })
      res.datas = list;
      return res;
    })
}
/**
 *
 */
/**
 * 获取 新闻（分页）列表
 * @param classifyId  {number|string}
 * @param options {{pageNum?:number,pageSize?:number}?}
 * @return {Promise<AxiosResponse<any>>}
 */
export function getNewsPagingList(classifyId,options){
  let url = huoDaiPinTargetName + "/cms/findNewsList"
  let data = Object.assign({
    // 新闻模块的代码
    appCode:"huodainews",
    // 分类id
    categoryId:classifyId,
    // 页码 默认1
    pageNum: 1,
    // 条数 默认10
    pageSize: 10,
    // 是否包裹外层公告参数
    noCommonParams:false,
  },options);
  return axiosMain.post(url,data)
}