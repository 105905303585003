const login = {
  zh:{
    loginButton:"登录",
    resetButton:"重置",
    accountNumber:"账号",
    password:"密码",
    verificationCode:"验证码",
    accountPasswordError:"账号密码错误",
    systemException:"系统异常",
    loginSuccess:"登录成功",
    logout:"退出登录",
    confirmLogout:"确认退出登录？",
    registerSuccess:"注册成功",
    personalCenter:"个人中心"
  },
  en:{
    loginButton:"login",
    resetButton:"reset",
    accountNumber:"account",
    password:"password",
    verificationCode:"verification code",
    accountPasswordError:"Account password error",
    systemException:"System exception",
    loginSuccess:"Login successful",
    logout:"logout",
    confirmLogout:"Confirm logout？",
    registerSuccess:"register success",
    personalCenter:"Personal center"
  }
}
export default login