/**
 * 生成随机：私人地址列表数据
 */
export function baseDataPrivateAddressList(){
  return Array(10).fill({}).map((res,index)=>{
    return {
      label:index,
      value:index,
      namec:index,
      id:index,
      isPrivateAddress:true,
      quoteStatus:undefined,
    }
  })
}
