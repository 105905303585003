<template>
  <g-wrap class="news-wrap">
    <template v-slot:before>
      <!-- <div class="before-bg">
        <img :src="bgUrl" alt="" class="">
      </div> -->
    </template>
    <div class="home-news">
      <div class="home-news-header">
        <slot name="tabs">
          <g-tabs
            :list="tabs"
            v-model="current"
            @change="handleChange"
            class="tabs"
          />
        </slot>
        <el-link class="look-more" :underline="false" @click="clickAll"
          >查看全部&nbsp;></el-link
        >
      </div>
      <div class="news-content">
        <div class="left-box">
					<video v-if="isVideo && leftUrl" :src="leftUrl" controls preload="metadata"></video>
          <img :src="leftUrl || defaultCover" alt="" class="" v-else />
        </div>
        <div class="right-box">
          <template v-for="(item, index) in list" :key="index">
            <slot :item="item" :index="index">
              <home-news-item
                class="news-item"
                :id="item.id"
                :title="item.nameCn || '-'"
                :time="item.releaseTimeText"
                :description="item.description"
              />
            </slot>
          </template>
        </div>
      </div>
    </div>
  </g-wrap>
</template>

<script setup>
import GWrap from "@/components/gWrap/gWrap.vue";
import HomeNewsItem from "@/views/home/components/homeNews/homeNewsItem.vue";
import GTabs from "@/components/gTabs/gTabs.vue";
import { ElMessage } from "element-plus";
const emit = defineEmits(["update:modelValue", "change"]);
const props = defineProps({
  modelValue: [String, Number],
  tabs: {
    type: Array,
    default: () => [],
    // default:()=>[
    //   {label:"公司新闻",value:"1"},
    //   {label:"行业资讯",value:"2"},
    // ],
  },
  list: {
    type: Array,
    default: () => [],
  },
  bgUrl: {
    type: String,
    default: ossPath + "/pages/home/news_bg.png",
  },
  defaultCover: {
    type: String,
    default: ossPath + "/pages/home/news_default_cover.png",
  },
	leftUrl: {
		type: String,
		default: ''
	}
});
let current = ref("");
watch(
  () => props.modelValue,
  (newValue) => (current.value = newValue)
);
const isVideo = computed(() => {
	const url = props.leftUrl
	if (!url) {
		return false
	}
	const arr = url.split('.')
	const videoTypeList = ['mp4','avi','mov','wmv']
	return videoTypeList.includes(arr[arr.length - 1])
})
/**
 * tabs 切换回调
 * @param value
 */
function handleChange(value) {
  current.value = value;
  emit("update:modelValue", value);
  emit("change", value);
}

function clickAll() {
  let user_info = JSON.parse(localStorage.getItem("user_info")) || {};
  let use_btn_permission_list =
    JSON.parse(localStorage.getItem("use_btn_permission_list")) || [];
  if (
    Array.isArray(use_btn_permission_list) &&
    use_btn_permission_list.length > 0
  ) {
    api.operate.addSysUserOperateLog({
      customerId: user_info.customerId,
      userId: user_info.id,
      operationButtonCode: "NEWS_CENTER",
      operationContent: "查看全部",
      appType: "oms",
      productPriceHeaderId: "",
      loginType: "",
      remark: "",
    });
  }

  // ElMessage.info("新闻中心开发中...")
  router.push("/news-center");
}
</script>

<style scoped lang="scss">
.news-wrap {
  position: relative;
  background: #f8f8f8;
  z-index: 1;
  :deep(.page-size-container) {
    position: relative;
    z-index: 1;
  }
}
.before-bg {
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  right: 0;
  width: 73%;
  background-color: var(--el-color-primary);
  //clip-path: polygon(20px 0%,100% 0%,100% 100%,230px 100%);
  clip-path: polygon(0px 0%, 100% 0%, 100% 100%, 225px 100%);
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.home-news {
  height: 600px;
}
.home-news-header {
  width: 100%;
  height: 96px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
}
.look-more {
  color: #999999;
  position: absolute;
  z-index: 2;
  top: 48px;
  right: 0;
  line-height: 24px;
  margin-top: -12px;
  &:hover {
    color: var(--el-color-primary);
    text-decoration: underline;
  }
  &:not(:hover):not(.disabled) {
  }
}
.news-content {
  display: flex;
}
.left-box {
  min-width: 608px;
  width: 608px;
  height: 400px;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 12px;
  background-color: white;
  // box-shadow: 0 0 57px 10px rgba(116, 123, 145, 0.18);
  margin-right: 24px;
  img {
    display: block;
    border: none;
    border-radius: 10px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
	video {
		display: block;
		border: none;
		border-radius: 10px;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}
.right-box {
  flex: 1;
  overflow: hidden;
}
.news-item {
  height: 120px;
  & + & {
    margin-top: 20px;
  }
}
:deep(.g-tabs--item) {
  color: #999 !important;
  &::before {
    margin: 0 auto;
    width: 30px;
    height: 2px;
  }
}
:deep(.g-tabs--item.is-active) {
  font-weight: 600;
  color: #141414 !important;
}
.tabs {
  height: 30px;
}
</style>
