/**
 * 手填邮编回调函数
 * @param item
 * @param endCountry
 * @returns {{isPrivateAddressCity: boolean, country: string, namee: string, namec: string, privateAddressType: number, city: string, countryCode: string, cityId: string, id, isPrivateAddress: boolean, _description: string, countryId: string}}
 */
export function handlerInputZipCode(item,endCountry={}){
  // let result = checkInputZipCode(text);
  // if(!result) {
  //   this.$message.error("邮编只能为1~5位数字或 12345-6789格式的9位邮编");
  //   return false
  // }
  let newItem = {
    // 目的国家
    country: endCountry.namec||"",
    countryCode: endCountry.code||"",
    countryId: endCountry.id||"",
    privateAddressType:1,// 目的国家对应 私人地址派送类型 1:邮编 2：城市
    // 对应城市
    city: "",
    cityId: "",
    // fba 邮编
    id: item.id,
    namec: `${item.namec}`,
    namee: `${item.namec}`,
    isPrivateAddress:true,
    isPrivateAddressCity:false,
    _description:`私人地址(邮编)${item.namec}`,
  }
  return newItem;
}

/**
 * 截取手填的邮编开头
 * @param zipCode
 * @param isPrivateAddress
 * @param privateAddressType
 * @returns {string}
 */
export function sliceInputZipCode(zipCode="",isPrivateAddress,privateAddressType){
  if(isPrivateAddress && `${zipCode}`.length && privateAddressType !== 2){
    return `${zipCode}`.slice(0,1);
  }
  return zipCode;
}