<template>
  <div class="single-advantage">
    <div class="single-advantage-content" :style="[wrapStyle]">
      <div class="index-text"><span class="index">{{indexText}}</span></div>
      <div class="label text-over-hide">
        <i class="iconfont m-icon-youshi" />
        {{ label }}
      </div>
      <div class="description text-line-3">{{ description }}</div>
    </div>
  </div>
</template>

<script setup>
const emit = defineEmits(["placeOrder", "collected"]);
const props = defineProps({
  index: Number,
  label: String,
  description: String,
  // bgUrl: {
  //   type: String,
  //   default: ossPath + "/pages/home/my_superiority_item_bg.png",
  // },
})
let indexText = computed(() => {
  let index = parseInt(props.index) || 0;
  if (index < 10 && index > -10) {
    return `0${index}`;
  } else {
    return `${index}`;
  }
})
let wrapStyle = computed(() => {
  let obj = {};
  // if (props.bgUrl) {
  //   obj["backgroundImage"] = `url(${props.bgUrl})`
  // }
  return obj;
})
</script>

<style scoped lang="scss">
.single-advantage {
  box-sizing: border-box;
  //width: 378px;
  //height: 227px;
  // background-color: #303133;
  // padding: 1px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
  border-radius: 12px;
  border: 1px solid #DDDDDD;
  transition: 0.4s ease;

  &-content {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 12px 30px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: inherit;
    //transition: 0.4s ease;
    overflow: hidden;
  }

  //&:not(:hover){
  //.label{
  //  margin-top: 27px;
  //}
  //}
  //&:hover &-content{
  //justify-content: space-around;
  //}
  // &:hover {
  //   background-color: var(--el-color-primary);
  //   .index-text {
  //     height: 0;
  //     opacity: 0;
  //   }
  //   .label {
  //     margin-top: 18px;
  //   }
  //   // transform: scale(1.05);
  //   // background: linear-gradient(-30deg, rgba(255, 255, 255, 0) 0%, rgba(255, 238, 228, 0.6) 50%, rgba(255, 255, 255, 0) 100%);
  //   //.index{
  //   //  background: linear-gradient(90deg, var(--el-color-primary-light-5), var(--el-color-primary));
  //   //  //background: linear-gradient(0deg, var(--el-color-primary-light-5), var(--el-color-primary));
  //   //  -webkit-background-clip: text;
  //   //  -webkit-text-fill-color: transparent;
  //   //}
  //   //.description{
  //   //  display: -webkit-box;
  //   //}
  // }
  &:hover {
    border-bottom-width: 10px;
    border-color: var(--el-color-primary);
    background: var(--el-color-primary-light-9);
    .label {
      color: var(--el-color-primary);
    }
  }
}
.index-text {
  // height: 69px;
  transition: 0.4s ease;
}
.index {
  display: inline-block;
  font-size: 56px;
  line-height: 78px;
  font-weight: 600;
  color: var(--el-color-primary-light-7\.5);
  opacity: .2;
  transition: 0.4s ease;
}

.label {
  font-size: 18px;
  font-weight: bold;
  color: #333333;
  margin-bottom: 7px;
  //transition: 0.4s ease;
  .iconfont {
    font-size: 18px;
    color: var(--el-color-primary);
  }
}

.description {
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  //display: none;
  min-height: 50px;
  line-height: 24px;
  // max-height: 75px;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>