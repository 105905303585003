<template>
  <el-dialog v-model="visibleDialog" title="" :width="width" destroy-on-close @open="handleOpen" @opened="handleOpened"
    @close="handleClose" @closed="handleClosed" class="login-popover" style="z-index: 9999;">
    <template #default>
      <div class="login-register">
        <div class="close-btn" @click="clickClear">
          <el-icon class="icon el-icon-circle-close">
            <Close />
          </el-icon>
        </div>
        <!-- <div class="left-banner" :style="[`background-image:url('${banner}')`]">
          <div class="system-name">
            <div class="">{{ systemStore.webFooterInfo.omsSystemName || "-" }}</div>
          </div>
        </div> -->
        <div class="right-container">
          <common-login-and-register ref="loginBox" v-model:default-page-type="pageType"
            v-model:default-login-type="loginType" v-model:default-register-type="registerType"
            @loginSuccess="handleLoginSuccess" @registerSuccess="handleRegisterSuccess" />
        </div>
      </div>
    </template>
    <template #header="{ close, titleId, titleClass }">
      <span class=""></span>
      <!--<el-icon @click="close"><CircleCloseFilled /></el-icon>-->
    </template>
  </el-dialog>
</template>

<script setup>
import CommonLoginAndRegister from "@/components/loginPopover/commonLoginAndRegister.vue";
import { removeParamsBeforeTheJumpLogin } from "@/utils/utils.router";
import { useSystemStore } from "@/stores";
let systemStore = useSystemStore();
const emit = defineEmits([])
const props = defineProps({
  width: {
    type: [String, Number],
    // default: "820px",
    default: "460px",
  },
  // Dialog 自身是否插入至 body 元素上。
  appendToBody: {
    type: Boolean,
    default: true,
  },
  // 是否在 Dialog 出现时将 body 滚动锁定
  lockScroll: {
    type: Boolean,
    default: false,
  },
  // 是否显示关闭按钮
  showClose: {
    type: Boolean,
    default: false,
  },
  // 是否可以通过点击 modal 关闭 Dialog
  closeOnClickModal: {
    type: Boolean,
    default: false,
  },
  // 是否可以通过按下 ESC 关闭 Dialog
  closeOnPressEscape: {
    type: Boolean,
    default: false,
  },
})
let banner = ossPath + "/pages/login/login_banner.png"
let visibleDialog = ref(false);
let pageType = ref(1);
let loginType = ref(1);
let registerType = ref("G");
let loginBox = ref(null);
function handleOpen() {
  console.log("handleOpen")
}
function handleOpened() {
  console.log("handleOpened")
}
function handleClose() {
  console.log("handleClose")
  let clear = unref(loginBox).clearValidate;
  if (clear) clear();
}
function handleClosed() {
  console.log("handleClosed")
  removeParamsBeforeTheJumpLogin();
}
function clickClear() {
  visibleDialog.value = false;
}

function handleLoginSuccess() {
  clickClear();
}
function handleRegisterSuccess() {

}
onMounted(() => {
  bus.on("showLoginPopover", params => {
    console.log("showLoginPopover", params)
    visibleDialog.value = true;
  })
  bus.on("switchLoginPopoverParams", params => {
    console.log("switchLoginPopoverParams", params)
    if (params.pageType) pageType.value = params.pageType;
    if (params.loginType) loginType.value = params.loginType;
    if (params.registerType) registerType.value = params.registerType;
  })
})
</script>

<style lang="scss">
.login-popover.el-dialog {
  border-radius: 20px;

  .el-dialog__header {
    display: none;
    //padding: 0;
    //position: relative;
    //z-index: 2;
    //margin-right: 0;
    //.el-dialog__headerbtn{
    //  top: 0;
    //  z-index: 1;
    //}
  }

  .el-dialog__body {
    padding: 0;
    border-radius: inherit;
    position: relative;
    z-index: 1;
  }
}
</style>
<style scoped lang="scss">
.login-register {
  display: flex;
  // height: 600px;
  // height: 640px;
  border-radius: inherit;
  position: relative;
}

.close-btn {
  position: absolute;
  z-index: 1000000;
  right: 0;
  top: 0;
  width: 54px;
  height: 54px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--el-color-info);
  font-size: 14px;

  &:hover {
    color: var(--el-color-primary);
  }
}

.left-banner {
  width: 342px;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  .system-name {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding-top: 137px;
    text-align: center;
    font-size: 24px;
    font-weight: 800;
    color: #FFFFFF;
    line-height: 33px;
  }

  &,
  img {
    border-radius: inherit;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: none;
  }
}

.right-container {
  box-sizing: border-box;
  border-radius: inherit;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  flex: 1;
  overflow: hidden;
  height: 100%;
  padding: 20px 40px;
}
</style>