/**
 * 重置该<formEl>表单项，将其值重置为初始值，并移除校验结果
 * @param formEl
 */
export function resetForm(formEl){
  if(!formEl) return;
  // resetFields	重置该表单项，将其值重置为初始值，并移除校验结果
  if(typeof formEl.resetFields === "function") formEl.resetFields();
}
/**
 * 不能为空
 * @param name  {string|undefined?}  <name>不能为空
 * @return {{trigger: string[], message: string, required: boolean}}
 */
export function getRulesNotEmpty(name){
  return {trigger: ['blur','change'],required:true,message:`${name||''}不能为空`, }
}
export const getRulesRequired = getRulesNotEmpty;
export const rulesNotEmpty = getRulesNotEmpty();
/**
 * 校验手机号
 * @param msg {string}  提示信息
 * @return {{pattern: RegExp, trigger: string, message: string}}
 */
export function getRulesPhone(msg) {
  return { pattern: /^1[3-9][0-9]{9}$/, trigger: "blur",message: "手机号码格式有误"}
}