import { huoDaiPinTargetName } from "../../../../config/server";
import { continentListSort, getLocalId, htmlStringToTextString } from "@/utils/utils";
import { isPROD } from "@/axios";
import { getTenantId } from "@/utils/utils.commonTenantParams";
import {
  createPrivateAddressZipCodeList,
  disposeDestinationList,
  disposePrivateCityList
} from "@/utils/utils.selectPlace";
import { productTypeList as _productTypeList } from "@/utils/utils.type";


/**
 * 将 目的国家历史查询记录 保存在浏览器本地缓存
 * @param list {Array<any>}
 */
export function setLocalEndCountryHistory(list) {
  let str = "[]";
  if (isArray(list)) {
    try {
      str = JSON.stringify(list);
    } catch (e) {
      console.error(e);
    }
  }
  localStorage.setItem("end_country_search_history", str);
}
/**
 * 从浏览器本地缓存获取 目的国家历史查询记录
 */
export function getLocalEndCountryHistory() {
  let list = null;
  try {
    list = localStorage.getItem('end_country_search_history');
    if (list) list = JSON.parse(list);
  } catch (e) {
    console.error(e);
  }
  if (!isArray(list)) list = [];
  return list;
}

/**
 * 添加一条 目的国家历史查询记录
 * @param value {Object}
 */
export function pushLocalEndCountryHistory(value) {
  if (!isObject(value)) {
    return false;
  }
  let list = getLocalEndCountryHistory();
  // 校验是否重复的key
  let repetitionKeys = ["namec", "code", "id"];
  let target = list.find(item => {
    return repetitionKeys.some(key => {
      if (!isObject(item)) {
        return false;
      }
      return item[key] === value[key];
    })
  })
  if (target) {
    if (target.historyNumber && typeof target.historyNumber === "number") {
      target.historyNumber++;
    } else {
      target.historyNumber = 1;
    }
  } else {
    target = Object.assign({}, value);
    target.historyNumber = 1;
    list.push(target);
  }
  list.sort((r1, r2) => r2.historyNumber - r1.historyNumber);
  setLocalEndCountryHistory(list);
  return true;
}

export function useHomeSearch() {
  // 物流产品类型 列表
  let productTypeList = ref([
    // ...cloneDeep(_productTypeList.filter(item=>!!item.visible))
  ])
  // 物流产品类型 当前值
  let productTypeCurrent = ref("FBX");
  /**
   * 切换物流产品类型
   * @param value
   */
  function changeProductType(value) {
    // 清空 选中的目的地
    resetEndWarehouse()
    if (value === 'FBX') {
      endWarehouseLabel.value = 'FBX仓库'
    } else {
      endWarehouseLabel.value = '目的地'
    }
    handleSetEndWarehouseTab(endWarehouseGroupTabData.fba)
  }
  /**
   * 获取首页物流产品列表
   * @returns {*}
   */
  function getProductType() {
    return api.home.getHomeInfoList("PriceInquiry")
      .then(res => {
        let result = res.data || {};
        let list = result.contentList || [];
        if (list.length) {
          list = list.filter(item => !item.deleteFlag);
          list = list.map(item => {
            let _son = _productTypeList.find(son => son.value === item.bannerName);
            return {
              label: item.bannerUrl,
              value: item.bannerName,
              icon: _son?.icon,
              iconType: _son?.iconType,
            }
          })
        } else {
          list = cloneDeep(_productTypeList.filter((item, index) => index === 0));
        }
        productTypeList.value = list;
        return res;
      })
      .catch(res => {
        console.error(res);
        let list = cloneDeep(_productTypeList.filter((item, index) => index === 0));
        productTypeList.value = list;
      })
  }
  // 获取的默认起运地目的国
  let defaultCityCountry = ref({
    // 是否是用户配置的默认数据
    defaultFlag: false,
  });
  /**
   * 获取默认起运城市合目的地国家
   */
  function getDefaultCityAndCounty() {
    let url = "/oms/quotedPrice/findFromCityAndToCountry";
    let data = {
      "tenantId": getTenantId(),
      // 业务类型代码
      "businessType": productTypeCurrent.value || "",
    }
    return axiosMain.post(url, data)
      .catch(res => {

      })
  }
  // 当前起运地
  let startCity = ref({
    // label:"东莞",
    // value:19510585000
  });
  // 起运地列表
  let startCityList = ref([]);
  let startCityLoading = ref(false);
  // 获取起运地列表
  function getStartCityList() {
    const data = {
    };
    let tenantId = getTenantId();
    const url = `/oms/data/findFbxAllFromCity/${tenantId}`;
    startCityLoading.value = true;
    return axiosMain.get(url, data)
      .then(res => {
        startCityLoading.value = false;
        let list = res.data || [];
        // list = disposeStartCityList(list);
        list = disposeStartCityList2(list);
        startCityList.value = list;
        return res
      })
      .catch(res => {
        startCityLoading.value = false;
        startCityList.value = [];
      })
  }

  /**
   * 处理获取到起运地列表数据（按 selectPopover.vue 处理）
   */
  function disposeStartCityList(list = []) {
    return list.map(res => {
      if (!isObject(res)) return undefined;
      let children = res.fromCityDTOList || [];
      children = children.map(item => {
        return Object.assign({}, item, {
          label: item.namec || "",
          value: item.id || "",
        })
      })
      return {
        label: res.code || "",
        value: res.code || "",
        children: children,
      }
    })
  }
  /**
   * 处理获取到起运地列表数据（按 selectPlaceTemplate.vue 处理）
   */
  function disposeStartCityList2(list = []) {
    return list.map(res => {
      if (!isObject(res)) return undefined;
      // if(["%C2%A0","%20"].includes(encodeURIComponent(res.code))) res.code = "";
      return {
        code: res.code || "-",
        list: res.fromCityDTOList || [],
      }
    });
  }
  /**
   * 变更 起始地
   */
  function changeStartCity(value) {
    console.log("变更 起始地", value);
  }

  // 当前目的国
  let endCountry = ref({
    // namec:"阿联酋", id:"1389823333335552",
    // namec:"美国", id:"1390756428496896",
    // namec:"未知国家", id:"1389823113977856",privateAddressType:1,
  })

  // 目的国家（按七大洲分组）数据集
  let endCountryGroupTabData = ref({});
  // 目的国家（按七大洲分组）分组配置项
  let endCountryGroupTabConfig = ref([]);
  // 目的国选择弹窗列表是否正在加载中
  let endCountryLoading = ref(false);
  // 目的国选择弹窗默认选中的tab
  let endCountryGroupTabCurrentValue = ref("");

  /**
   * 根据七大洲分类获取所有国家列表
   */
  function getEndCountryContinentList() {
    const data = {};
    let tenantId = getTenantId();
    const url = `/oms/data/findCountry/${tenantId}`;
    endCountryLoading.value = true;
    return axiosMain.get(url)
      .then(res => {
        endCountryLoading.value = false;
        let list = res.data || [];
        // namec:"未知国家", id:"1389823113977856",privateAddressType:1,
        // if(!isPROD) list.unshift({
        //   continentNameCn:"未知洲",
        //   children:[
        //     {
        //       "id": 1389823113977856,
        //       "code": "KR",
        //       "namec": "未知国家",
        //       "namee": "wzgj",
        //       "privateAddressType": 1,
        //       "continentId": 13898231139778516,
        //       "continentNameCn": "未知洲",
        //       "children": null
        //     },
        //   ]
        // })
        // 加载本地缓存
        let historyList = getLocalEndCountryHistory();
        let historyIds = historyList.map(item => `${item.id || 2}`);
        // 有改动
        let hasAlter = false;
        list.forEach(item => {
          let childList = item.children || [];
          childList.forEach(twoItem => {
            let twoId = `${twoItem.id || 1}`;
            let index = historyIds.indexOf(twoId);
            if (index >= 0) {
              let target = historyList[index];
              if (target.privateAddressType !== twoItem.privateAddressType) {
                hasAlter = true;
                target.privateAddressType = twoItem.privateAddressType;
              }
            }
          })
        })
        if (hasAlter) {
          setLocalEndCountryHistory(historyList);
        }
        list.unshift(initEndCountryHistoryData());
        let { defaultValue, tabHeadList, dataset } = disposeContinentEndCountryTabHeadList(list);
        endCountryGroupTabCurrentValue.value = defaultValue;
        endCountryGroupTabConfig.value = tabHeadList;
        endCountryGroupTabData.value = dataset;
        return res
      })
      .catch(res => {
        endCountryLoading.value = false;
        // 不加载本地缓存
        // endCountryGroupTabCurrentValue.value = "";
        // endCountryGroupTabConfig.value = [];
        // endCountryGroupTabData.value = {};
        // 加载本地缓存
        let list = [];
        list.unshift(initEndCountryHistoryData());
        let { defaultValue, tabHeadList, dataset } = disposeContinentEndCountryTabHeadList(list);
        endCountryGroupTabCurrentValue.value = defaultValue;
        endCountryGroupTabConfig.value = tabHeadList;
        endCountryGroupTabData.value = dataset;
        // return Promise.reject(res);
      })
  }

  /**
   * 初始化本地缓存的目的地国家历史查询记录
   * @returns {{children: Array<object>, continentNameCn: string}}
   */
  function initEndCountryHistoryData() {
    let list = getLocalEndCountryHistory();
    if (list.length > 6) {
      list = list.slice(0, 6);
    }
    return {
      continentNameCn: "历史记录",
      children: list,
    };
  }
  /**
   * 处理获取到的目的国列表列表数据（按七大洲分组）
   * @param topList
   * @return {{defaultValue: string, dataset: {}, tabHeadList: *[]}}
   */
  function disposeContinentEndCountryTabHeadList(topList = []) {
    topList = continentListSort(topList, 'continentNameCn')
    let tabHeadList = [];
    let defaultValue = "";
    let dataset = {};
    topList.forEach((item, index) => {
      let id = `${item.continentId || ""}`;
      if (!id) id = getLocalId();
      let namec = item.continentNameCn || "";
      let childList = item.children || [];
      childList.forEach(twoItem => {
        twoItem.continentNameCn = item.continentNameCn || "";
        twoItem.continentNameEn = item.continentNameEn || "";
        twoItem.privateAddressType = twoItem.privateAddressType || 1;
      })
      if (index === 0) defaultValue = id;
      let code = `${item.code || ""}`;
      let opt = {
        label: namec,
        value: id,// tab自身标识
        listLevel: 1,// 列表的层级，只支持1和2
        showLeftLabel: false,// 2级列表是否显示左边的分组标签（展示首字母开头）
        openSelectAll: false,// 是否展示全选按钮
      };
      if (!dataset[id]) {
        dataset[id] = childList;
        tabHeadList.push(opt);
      }
    })
    return {
      defaultValue,
      tabHeadList,
      dataset,
    }
  }

  /**
   * 变更 目的国
   * @param value
   * @param options {{autoNext?:boolean}}
   */
  function changeEndCountry(value = {}, options = {}) {
    console.log("变更 目的国", Object.assign({}, value));
    resetDataAfterChangeEndCountry();

    let countryId = value.id;
    let { autoNext = true } = options;
    if (countryId) {
      getGoodsTypeList(countryId);
      if (autoNext) {
        getEndWarehouseFbaList(countryId)
          .then((list) => {
            handleSetEndWarehouseTab(list)
          })
      }
      if (productTypeCurrent.value === 'FBX') {
        endWarehouseLabel.value = 'FBX仓库'
      } else {
        endWarehouseLabel.value = '目的地'
      }
    }
    if (value.privateAddressType === 2) {
      if (countryId) getPrivateCityList(countryId);
    } else {
      // getZipCodeList({
      //   country: value.namec||"",
      //   countryCode: value.code||"",
      //   countryId: countryId||"",
      //   privateAddressType:value.privateAddressType,
      // })
    }
  }

  /**
   * 变更 目的国 时，重置相关的数据
   */
  function resetDataAfterChangeEndCountry() {
    // 清空 选中的目的地
    // endWarehouse.value = {};
    // endWarehouseSelectList.value = [];
    resetEndWarehouse();

    // 清空目的地相关数据
    resetEndWarehouseList()

    // 货物类型
    // resetGoodsType()
    resetGoodsTypeList()

    // 产品名称（商品名）
    goodsName.value = '';
    goodsNameList.value = [];

  }

  // 目的地(仓库)
  let endWarehouse = ref({
    ...getEndWarehouseDefaultItem()
  });
  let endWarehouseSelectList = ref([
    getEndWarehouseDefaultItem()
  ]);
  // 目的地(仓库) （默认FBA）是否在请求状态
  let endWarehouseLoading = ref(false);
  // 目的地(仓库) （私人地址(城市)列表）是否在请求状态
  let privateCityListLoading = ref(false);
  // 目的地(仓库)选择弹窗当前(默认)选中的tab fba privateCity zipCode inputZipCode
  let endWarehouseGroupTabCurrentValue = ref("fba")
  // 目的地 下拉弹窗数据 tab分组配置项
  /*let endWarehouseGroupTabConfig = computed(() => {
    let fba = {
      label: "FBX仓库",
      // tab自身标识
      value: "fba",
      // 列表的层级，只支持1和2
      listLevel: 3,
      // 2级列表是否显示左边的分组标签（展示首字母开头）
      showLeftLabel: true,
      // 是否展示全选按钮
      openSelectAll: false,
    };
    let privateCity = { label: "私人地址(城市)", value: "privateCity", listLevel: 2, showLeftLabel: true, openSelectAll: false, isMultiLine: true, };
    let zipCode = { label: "私人地址(邮编)", value: "zipCode", listLevel: 1, showLeftLabel: false, openSelectAll: false, };
    let inputZipCode = { label: "私人地址(邮编)", value: "inputZipCode", listLevel: 1, };
    let {
      id: countryId,
      // 私人地址派送类型 1:邮编 2：城市 3:邮编首字母（输入）
      privateAddressType,
    } = endCountry.value;
    let defaultTab = "";
    let list = [];
    list.push(fba);
    if (countryId) {
      // if (privateAddressType === 2) {
      //   list.push(privateCity);
      // } else {
      //   list.push(inputZipCode);
      // }
      if (String(productTypeCurrent.value) === "FBX") {
        defaultTab = "fba";
        if (privateAddressType === 2) {
          list.push(privateCity);
        } else {
          list.push(inputZipCode);
        }
      } else {
        if (privateAddressType === 2) {
          list = [privateCity];
        } else {
          list = [inputZipCode];
        }
        if (privateAddressType === 2) {
          defaultTab = "privateCity";
        } else {
          defaultTab = "inputZipCode";
        }
      }
    } else {
      defaultTab = "fba";
    }
    endWarehouseGroupTabCurrentValue.value = defaultTab;
    return list;

  });*/
  let endWarehouseGroupTabConfig = ref([])
  const handleSetEndWarehouseTab = (endFbaWarehouseList) => {
    let fba = {
      label: "FBX仓库",
      // tab自身标识
      value: "fba",
      // 列表的层级，只支持1和2
      listLevel: 3,
      // 2级列表是否显示左边的分组标签（展示首字母开头）
      showLeftLabel: true,
      // 是否展示全选按钮
      openSelectAll: false,
    };
    let privateCity = { label: "私人地址(城市)", value: "privateCity", listLevel: 2, showLeftLabel: true, openSelectAll: false, isMultiLine: true, };
    let zipCode = { label: "私人地址(邮编)", value: "zipCode", listLevel: 1, showLeftLabel: false, openSelectAll: false, };
    let inputZipCode = { label: "私人地址(邮编)", value: "inputZipCode", listLevel: 1, };
    let {
      id: countryId,
      // 私人地址派送类型 1:邮编 2：城市 3:邮编首字母（输入）
      privateAddressType,
    } = endCountry.value;
    let defaultTab = "";
    let list = [];
    if (countryId) {
      // if (privateAddressType === 2) {
      //   list.push(privateCity);
      // } else {
      //   list.push(inputZipCode);
      // }
      // #852 【UI/UX】OMS端FBX仓展示的逻辑调整-前端
      // https://ones.cn/project/#/team/5DaDCDqw/task/L5QTpaqBIOVyYI42
      if (String(productTypeCurrent.value) === "FBX" && endFbaWarehouseList.length) {
        defaultTab = "fba";
        list.push(fba);
        if (endFbaWarehouseList.length === 1) {
          fba.label = endFbaWarehouseList[0].warehouseTypeName || 'FBX仓库'
          endWarehouseLabel.value = endFbaWarehouseList[0].warehouseTypeName
        }
        if (endWarehouse.value.isPrivateAddress && endWarehouse.value.namec !== '全部') {
          endWarehouseLabel.value = '目的地'
        }
        if (privateAddressType === 2) {
          list.push(privateCity);
        } else {
          list.push(inputZipCode);
        }
      } else {
        endWarehouseLabel.value = '目的地'
        if (privateAddressType === 2) {
          list = [privateCity];
        } else {
          list = [inputZipCode];
        }
        if (privateAddressType === 2) {
          defaultTab = "privateCity";
        } else {
          defaultTab = "inputZipCode";
        }
      }
    } else {
      defaultTab = "fba";
    }
    endWarehouseGroupTabCurrentValue.value = defaultTab;
    endWarehouseGroupTabConfig.value = list
  }
  // 目的地 下拉弹窗数据 tab分组对应的数据
  let endWarehouseGroupTabData = reactive({
    // FBA
    fba: [],
    // 右边
    zipCode: [],
    // 私人地址
    privateCity: [],
  });
  // 目的地label文本
  const endWarehouseChange = (value) => {
    if (isObject(value)) {
      console.log('end',value);
      if (value.hasOwnProperty('isPrivateAddress') && value.isPrivateAddress) {
        endWarehouseLabel.value = '目的地'
      } else {
        endWarehouseLabel.value = value.warehouseTypeName || "FBX仓库"
      }
    } else {
      if (productTypeCurrent.value === "FBX") {
        endWarehouseLabel.value = "FBX仓库";
      } else {
        endWarehouseLabel.value = "目的地";
      }
    }
  }
  let endWarehouseLabel = ref("FBX仓库")
  watch(endWarehouseLabel, (newVal, oldValue) => {
    console.log('endWarehouseLabel',newVal, oldValue)
  })
  // let endWarehouseLabel = computed(() => {
  //   if (endWarehouseGroupTabCurrentValue.value === "fba") {
	// 		return "FBX仓库"
	// 	} else {
	// 		return "目的地"
	// 	}
  //   // let country = unref(endCountry);
  //   // if (country.id) {
  //   //   if (unref(productTypeCurrent) === "FBX") {
  //   //     return "FBA仓";
  //   //   }
  //   //   // 私人地址派送类型 1:邮编 2：城市 3:邮编首字母（输入）
  //   //   let privateAddressType = country.privateAddressType;
  //   //   if (privateAddressType === 2) {
  //   //     return "目的城市";
  //   //     // }else if(privateAddressType === 3) {
  //   //   } else {
  //   //     return "目的邮编";
  //   //   }
  //   //   // }else {
  //   //   //   return `目的地(${privateAddressType||"-"})`;
  //   //   // }
  //   // }
  //   // return "目的地";
  // })
  // 的地选择框展示的 placeholder 文本内容
  let endWarehousePlaceholder = computed(() => {
    return endCountry.value.id ? '请选择目的地' : '请先选择国家'
  })

  // 目的地(仓库) FBA列表
  let fbaList = [];
  // 目的地(仓库) 私人地址(城市)列表
  let privateCityList = [];
  // 目的地(仓库) 私人地址(邮编)列表
  let zipCodeList = [];

  /**
   * 获取 目的仓库 FBA列表
   */
  function getEndWarehouseFbaList(countryId = "") {
    const data = {
      countryId,
    };
    const url = "/oms/data/getFbxWarehouseList";
    endWarehouseLoading.value = true;
    return axiosMain.get(url, { params: data })
      .then(res => {
        endWarehouseLoading.value = false;
        let list = res.data || [];
        list = disposeDestinationList(list);
        // fbaList = list;
        endWarehouseGroupTabData.fba = list;
        return list;
      })
      .catch(res => {
        endWarehouseLoading.value = false;
        // fbaList = [];
        endWarehouseGroupTabData.fba = [];
        return Promise.reject(res);
      })
  }
  /**
   * 获取 私人地址（城市）列表
   */
  function getPrivateCityList(countryId = "") {
    const data = {
      countryId,
    };
    let url = `/oms/data/getPrivateWarehouseList`;
    privateCityListLoading.value = true;
    return axiosMain.get(url, { params: data })
      .then(res => {
        privateCityListLoading.value = false;
        let result = res.data || {};
        let list = result.privateCityList || [];
        list = disposePrivateCityList(list, {
          countryId: result.countryId || "",
          countryName: result.nameCn || "",
          countryCode: result.countryCode || "",
        });
        endWarehouseGroupTabData.privateCity = list;
        return res;
      })
      .catch(res => {
        privateCityListLoading.value = false;
        // privateCityList = [];
        endWarehouseGroupTabData.privateCity = [];
        return Promise.reject(res);
      })
  }
  /**
   * 获取0-9邮编列表
   */
  function getZipCodeList(options = {}) {
    let list = createPrivateAddressZipCodeList(options);
    // zipCodeList = list;
    endWarehouseGroupTabData.zipCode = list;
  }
  /**
   * 获取目的地默认的item(全部)
   * @return {{namec: string, id: number}}
   */
  function getEndWarehouseDefaultItem() {
    return { namec: "全部", id: 0, }
  }

  /**
   * 重置 目的地
   */
  function resetEndWarehouse() {
    endWarehouse.value = getEndWarehouseDefaultItem();
    endWarehouseSelectList.value = [
      getEndWarehouseDefaultItem(),
    ];
  }
  /**
   * 重置 目的地列表相关数据
   */
  function resetEndWarehouseList() {
    // fbaList = [];
    // privateCityList = [];
    // zipCodeList = [];
    endWarehouseGroupTabData.fba = [];
    endWarehouseGroupTabData.privateCity = [];
    endWarehouseGroupTabData.zipCode = [];
  }
  /**
   * 清空 目的地 回调
   */
  function clearEndWarehouse() {
    resetEndWarehouse();
  }

  /**
   * 变更 目的仓库
   */
  function changeEndWarehouse(value) {
    console.log("目的仓库", value)
  }
  // 货物类型
  let goodsType = ref({
    ...getGoodsTypeDefaultItem()
  });
  // 货物类型 是否在请求状态
  let goodsTypeLoading = ref(false);
  // 货物类型 列表
  let goodsTypeList = ref([
    getGoodsTypeDefaultItem()
  ]);
  // 货物信息选择框展示的 placeholder 文本内容
  let goodsTypePlaceholder = computed(() => {
    return endCountry.value.id ? '请选择货物信息' : '请先选择国家'
  })
  /**
   * 获取货物类型默认的item(全部)
   * @return {{goodsTypeId: number, goodsTypeName: string}}
   */
  function getGoodsTypeDefaultItem() {
    return { goodsTypeName: "全部", goodsTypeId: 0, }
  }
  /**
   * 获取 货物类型下拉列表
   */
  function getGoodsTypeList(countryId = "") {
    const data = {
      countryId,
    };
    if (!data.countryId || data.countryId === -1) {
      // 2024-01-22调整国家与货物类型有关联
      // data.countryId = -1;
      return;
    }
    let url = "/oms/data/getGoodsTypeByCountryId";
    goodsTypeLoading.value = true;
    return axiosMain.get(url, { params: data })
      .then(res => {
        goodsTypeLoading.value = false;
        let list = res.data || [];
        list.forEach(item => {
          if (item.goodsTypeName === "全部") item.goodsTypeId = 0;
        })
        // list = list.map(item=>{
        //   return {
        //     label: item.goodsTypeName,
        //     value: item.goodsTypeId,
        //   }
        // })
        if (list.length) {
          goodsTypeList.value = list;
        } else {
          resetGoodsTypeList()
        }
        return res;
      })
      .catch(res => {
        goodsTypeLoading.value = false;
        resetGoodsTypeList()
        return Promise.reject(res);
      })
  }

  /**
   * 重置 货物类型
   */
  function resetGoodsType() {
    goodsType.value = getGoodsTypeDefaultItem();
  }
  /**
   * 重置 货物类型 列表数据
   */
  function resetGoodsTypeList() {
    // goodsType.value.goodsTypeId = null;
    // goodsType.value.goodsTypeName = null;
    goodsType.value = { ...getGoodsTypeDefaultItem() }
    goodsTypeList.value = [
      getGoodsTypeDefaultItem(),
    ];
  }
  /**
   * 清空 货物类型 回调
   */
  function clearGoodsType() {
    resetGoodsType();
  }
  /**
   * 切换 货物类型
   * @param value
   */
  function changeGoodsType(value) {
    // if(!isObject(value)) value = {};
    // goodsType.value = value

  }

  // 产品(商品)
  let goodsName = ref({});
  // 产品(商品) 是否在请求状态
  let goodsNameLoading = ref(false);
  // 产品(商品) 列表
  let goodsNameList = ref([]);

  // 体积
  let cbm = ref("");
  // 重量 kg
  let weight = ref("");
  // 箱数
  let boxNumber = ref("");

  return {
    // 物流产品类型
    productTypeList,
    productTypeCurrent,
    changeProductType,
    getProductType,
    // 获取的默认起运地目的国
    defaultCityCountry,
    getDefaultCityAndCounty,
    // 起运地
    startCity,
    startCityList,
    startCityLoading,
    getStartCityList,
    changeStartCity,
    // 目的国
    endCountry,
    endCountryGroupTabData,
    endCountryGroupTabConfig,
    endCountryLoading,
    endCountryGroupTabCurrentValue,
    getEndCountryContinentList,
    changeEndCountry,
    resetDataAfterChangeEndCountry,
    resetEndWarehouse,
    resetEndWarehouseList,
    // 目的地
    endWarehouse,
    endWarehouseChange,
    endWarehouseSelectList,
    endWarehouseLoading,
    privateCityListLoading,
    endWarehouseLabel,
    endWarehousePlaceholder,
    endWarehouseGroupTabCurrentValue,
    endWarehouseGroupTabConfig,
    endWarehouseGroupTabData,
    getEndWarehouseFbaList,
    getPrivateCityList,
    getZipCodeList,
    clearEndWarehouse,
    changeEndWarehouse,
    handleSetEndWarehouseTab,
    // 货物类型
    goodsType,
    goodsTypeLoading,
    goodsTypeList,
    goodsTypePlaceholder,
    getGoodsTypeList,
    clearGoodsType,
    resetGoodsType,
    changeGoodsType,
    // 产品(商品)
    goodsName,
    goodsNameLoading,
    goodsNameList,
    // 体积
    cbm,
    weight,
    boxNumber,
  }
}
