import { huoDaiPinTargetName } from "../../../../../config/server";
import axiosMain from "@/axios";
import { getTenantId } from "@/utils/utils.commonTenantParams";
import queryString from "query-string";
import { getHomeInfoList } from "@/api/modules/home";

/**
 * 首页新闻相关逻辑
 */
export function useHomeBannerList() {
  // 默认banner
  const defaultBanner = [
    {
      id: "local_default_banner",
      // banner名称
      bannerName: "测试banner",
      // banner图片URL
      bannerUrl: "https://saastms-test.oss-cn-shenzhen.aliyuncs.com/oms/v1.0.0/pages/home/banner1.png",
      // banner格式
      bannerFormat: "png",
      // 排序
      "sort": 1,
    },
    // {
    //   id:"23122",
    //   name:"测试banner2",
    //   imgUrl:"https://saastms-test.oss-cn-shenzhen.aliyuncs.com/oms/v1.0.0/pages/home/news_bg.png",
    //   target:{
    //     path:"/testLink?haha=wrnm"
    //   }
    // },
    // {
    //   id:"23124",
    //   name:"测试banner3",
    //   imgUrl:"https://saastms-test.oss-cn-shenzhen.aliyuncs.com/oms/v1.0.0/pages/home/my_superiority_bg.png",
    //   target:{
    //     path:"/test?type=1"
    //   }
    // },
  ]
  // banner列表
  let bannerList = ref([
    ...defaultBanner
  ])
  // banner列表 是否正在加载
  let bannerListLoading = ref(false);

  // 轮播时长
  let bannerSpeed = ref()

  /**
   * 获取 新闻分类tabs列表
   * @return {Promise<AxiosResponse<any>>}
   */
  function getBannerList() {
    bannerListLoading.value = true;
    return getHomeInfoList("BannerPicture")
      .then(res => {
        bannerListLoading.value = false;
        let result = res.data || {};
        let list = result.contentList || [];
        bannerSpeed.value = res.data.bannerSpeed
        if (!list.length) list.push(...defaultBanner);
        console.log('banner列表', list);
        bannerList.value = list;

        // console.log("bannerSpeed.value", bannerSpeed.value)
        return res;
      })
      .catch(res => {
        console.error(res);
        bannerListLoading.value = false;
        bannerList.value = [
          ...defaultBanner
        ];
      })
  }
  // function getBannerList() {
  //   const tenantId = getTenantId();
  //   let url = `/oms/data/banner/${tenantId}`
  //   let data = {
  //
  //   };
  //   bannerListLoading.value = true;
  //   return axiosMain.get(url)
  //     .then(res=>{
  //       bannerListLoading.value = false;
  //       let list = res.data || [];
  //       if(!list.length) list.push(...defaultBanner);
  //       console.log('banner列表',list);
  //       bannerList.value = list;
  //       return res;
  //     })
  //     .catch(res=>{
  //       bannerListLoading.value = false;
  //       bannerList.value = [
  //         ...defaultBanner
  //       ];
  //     })
  // }


  return {
    bannerList,
    getBannerList,
    bannerSpeed
  }
}