<template>
  <div class="main-footer">
    <div class="main-footer--content page-size-container">
      <div class="contact-row">
        <div class="contact-info">
          <div class="title">{{ systemStore.webFooterInfo.websitePrincipal }}</div>
          <div class="text-line-3 font-14">
            <tooltip :content-info="systemStore.webFooterInfo.companyOverview" effect="light"></tooltip>
          </div>
        </div>


        <div class="contact-info contactUs">
          <div class="title">联系我们</div>
          <!-- <div class="info">公司名称：{{ systemStore.webFooterInfo.websitePrincipal || "-" }}</div> -->
          <div class="info-phone">咨询电话：{{ systemStore.webFooterInfo.telephone || "-" }}</div>
          <div class="info text-line-2">
            <tooltip :content-info="systemStore.webFooterInfo.address ? '公司地址：' + systemStore.webFooterInfo.address : ''"
              effect="light"></tooltip>
          </div>
          <!--          <div class="info" v-for="(item,index) in companyInfo">{{item}}</div>-->
        </div>
        <div class="flex justify-content-end">
          <div class="qr-box" style="margin-left: auto;" v-if="systemStore.systemConfig.openWechatFlag">
            <div class="qr-image">
              <img :src="systemStore.webFooterInfo.weChatQrCode" alt="" class="">
            </div>
            <span class="qr-text">微信公众号</span>
          </div>
          <div class="qr-box" v-if="systemStore.systemConfig.miniProgramUrlFlag">
            <div class="qr-image">
              <img :src="systemStore.webFooterInfo.weChatAppletQrCode" alt="" class="">
            </div>
            <span class="qr-text">微信小程序</span>
          </div>
          <!-- <div class="logo-box">
            <img :src="systemStore.webFooterInfo.websiteLogoUrl" alt="" class=""
              v-if="systemStore.webFooterInfo.websiteLogoUrl">
          </div> -->
        </div>
      </div>
      <div class="AQ-row" v-if="systemStore.webFooterInfo.filingNumberFlag">
        <el-link :href="systemStore.webFooterInfo.filingNumberUrl || ''" :underline="false" target="_blank">备案号：{{
          systemStore.webFooterInfo.filingNumber || "-" }}</el-link>
        <span v-if="systemStore.systemConfig.omsTechniqueSupportFlag">
          该网站由<span class="QH-company" @click="goYiMing">一明信息科技</span>提供技术支持
        </span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { huoDaiPinTargetName } from "../../../config/server";
import axiosMain from "@/axios";
import { getTenantId } from "@/utils/utils.commonTenantParams";
import { useSystemStore } from "@/stores";
import tooltip from '@/components/tooltip.vue'

let systemStore = useSystemStore();

// let companyName = ref("");
// let companyTel = ref("");
// let companyAddress = ref("");
let companyInfo = ref([]);

function splitCompanyInfo(text = "") {
  if (typeof text === "string" && !!text) {
    companyInfo.value = text.split("\n");
  } else {
    companyInfo.value = [];
  }
}

function goYiMing() {
  window.open('https://www.yimingsaas.com/')
}




// watch(
//   () => systemStore.webFooterInfo.telephone,
//   newValue => {
//     splitCompanyInfo(newValue);
//   }
// )
onMounted(() => {
  // systemStore.autoGetWebFooterInfo();
  // if(systemStore.webFooterInfo.telephone) {
  //   splitCompanyInfo(systemStore.webFooterInfo.telephone);
  // }
})
</script>

<style scoped lang="scss">
.main-footer {
  box-sizing: border-box;
  width: 100%;
  //height: 251px;
  background-color: #1D1E1F;
  color: white;

  &--content {
    box-sizing: border-box;
  }
}

.qr-box {
  &+& {
    margin-left: 40px;
  }
}

.contact-row {
  display: flex;
  min-height: 100px;
  width: 1200px;
  padding-top: 60px;
  padding-bottom: 30px;

  &>div {
    flex: 1;
  }

  .qr-box {
    display: flex;
    flex-direction: column;
    //justify-content: center;
    align-items: center;
  }

  .qr-image {
    width: 90px;
    height: 90px;

    //background-color: var(--el-color-primary);
    img {
      display: block;
      border: none;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: inherit;
    }
  }

  .qr-text {
    margin-top: 10px;
    text-align: center;
    color: #FFFFFF;
    font-size: 12px;
  }

  .logo-box {
    flex: 1;
    display: flex;
    justify-content: center;
    color: var(--el-color-primary);
    font-weight: bold;
    font-size: 55px;

    img {
      box-sizing: border-box;
      display: block;
      border: none;
      width: auto;
      max-width: 200px;
      height: auto;
    }
  }

  .contact-info {
    min-width: 300px;
    max-width: 400px;



    .title {
      font-size: 20px;
      font-weight: 500;
      line-height: 30px;
      color: #FFFFFF;
      margin-bottom: 12px;
    }

    .info {
      color: #FFFFFF;
      line-height: 20px;
      font-size: 14px;
      // margin-top: 10px;
    }

    .info-phone {
      // font-weight: 600;
      font-size: 14px;
      line-height: 22px;
    }

    .font-14 {
      font-size: 14px;
    }
  }
}

.AQ-row {
  box-sizing: border-box;
  color: #DDDDDD;
  height: 99px;
  border-top: 1px solid #525252;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;

  :deep(.el-link) {
    color: inherit;
    margin-right: 20px;
    font-size: 12px;

    &:hover {
      color: var(--el-color-primary);
    }
  }

  span {
    text-align: center;
    display: inline-block;
    // font-weight: 500;
  }

  span+span {
    margin-left: 40px;
  }
}

.QH-company {
  $qh_primary_color: #2B62ED;
  color: $qh_primary_color;
  cursor: pointer;

  &:hover {
    color: lighten($color: $qh_primary_color, $amount: 10%);
  }
}

.contactUs {
  padding-left: 100px;
}

.websitePrincipal {
  height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  overflow: hidden;
  //div:first-child {
  //  margin-top: 43px;
  //}
}
</style>
