import {ossCommonFile} from "../../../config/variables";

/**
 * 获取 装箱单模板文件并下载
 */
export function getBoxTemplateFile(fileName="装箱单导入模板V2.0.xls"){
  let url = ossCommonFile + "/resources/template/PACKING-LIST-TEMPLATE-V1.0.xlsx"
  return window.open(url,"_blank");
  let data = {};
  return axiosMain.get(url)
    .then(res=>{
      let fileData = res.data;
      if(fileData && fileName && typeof fileName === "string"){
        let a = document.createElement("a")
        let blob = new Blob([fileData])
        let objectUrl = URL.createObjectURL(blob)
        a.setAttribute("href", objectUrl)
        a.setAttribute("download", fileName)
        a.click()
        // 释放
        URL.revokeObjectURL(objectUrl)
      }
      return res
    })
}