<template>
  <div class="single-product" :style="[wrapStyle]" @click="handleDetail">
    <img v-if="preferentialFlag && !needInquiryFlag" class="charge-price-ico" :src="ossPath + '/common/charge-price-r.png'" />
    <div class="top-row">
      <img :src="ossPath + '/pages/home/better_line.png'" alt="" />
      <div class="product-name text-over-hide">
        {{ productName }}
      </div>
      <!--  收藏按钮    -->
      <!--      <div class="collected-box" :class="{'is-active':isCollected}" @click="emit('collected',!isCollected)">-->
      <!--        <template v-if="isCollected">-->
      <!--          <el-icon><StarFilled /></el-icon>-->
      <!--        </template>-->
      <!--        <template v-else>-->
      <!--          <el-icon><Star /></el-icon>-->
      <!--        </template>-->
      <!--      </div>-->
    </div>
    <!-- <div class="info-row">
      <div class="single-info">
        <div class="icon-box">
          <i class="iconfont m-icon-address"></i>
        </div>
        <div class="label">起运地 </div>
        <div class="text" :title="startCityName||''">{{startCityName}}</div>
      </div>
      <div class="single-info">
        <div class="icon-box">
          <i class="iconfont m-icon-clock"></i>
        </div>
        <div class="label">整体时效</div>
        <div class="text" :title="aging||''">{{aging}}</div>
      </div>
      <div class="single-info">
        <div class="icon-box">
          <i class="iconfont m-icon-earth"></i>
        </div>
        <div class="label">目的地</div>
        <div class="text" :title="`${endCountryName||''} ${endWarehouseName||''}`">{{endCountryName}}
          {{endWarehouseName}}</div>
      </div>
      <div class="single-info">
        <div class="icon-box">
          <i class="iconfont m-icon-huowu"></i>
        </div>
        <div class="label">可接货物</div>
        <div class="text" :title="goodsTypeText||''">{{goodsTypeText}}</div>
      </div>
    </div> -->
    <div class="main-info">
      <div class="poi-box">
        <p class="poi-name" :title="startCityName">{{ startCityName }}</p>
        <p>起运地</p>
      </div>
      <div class="center-info">
        <div>{{aging}}</div>
        <img :src="ossPath + '/pages/home/line.png'" alt="">
        <div class="ell" :title="goodsTypeText||''">{{goodsTypeText}}</div>
      </div>
      <div class="poi-box end">
        <p
          class="poi-name"
          :title="`${endCountryName || ''}${endWarehouseName || ''}`"
        >
          {{ endCountryName }}{{ endWarehouseName }}
        </p>
        <p>目的地</p>
      </div>
    </div>
    <div
      class="bottom-row"
      :style="{ '--btn-bg': `url(${ossPath}/pages/home/better_line_btn.png)` }"
    >
      <div>
        <div class="price-row" v-if="!!needInquiryFlag">
          {{ currency || "" }} <span class="Inquiry">单询</span>
        </div>
        <price-blurring
          v-else
          :price="price"
          :currency="currency"
          :unit="chargeUnit"
          class="price-row"
        />
      </div>
      <div class="submit-btn" @click="emit('viewDetail')">查看详情</div>
    </div>
    <!-- <div class="bottom-row"> -->
    <!--      <div class="price-row">-->
    <!--        <span class="currency">{{currency}}&nbsp;</span>-->
    <!--        <span class="price">{{price}}</span>-->
    <!--        <span class="unit">/{{chargeUnit}}</span>-->
    <!--      </div>-->
    <!-- <div class="price-row" v-if="!!needInquiryFlag">{{currency || ''}} <span style="font-size: 24px">单询</span></div> -->
    <!-- <price-blurring v-else :price="price" :currency="currency" :unit="chargeUnit" class="price-row" /> -->
    <!--      <el-button class="submit-btn" type="primary">立即下单</el-button>-->
    <!-- <div class="submit-btn" @click="emit('placeOrder')">立即下单</div> -->
    <!-- </div> -->
  </div>
</template>

<script setup>
import { ossPath } from "../../../../../config/variables";
import PriceBlurring from "@/components/priceBlurring/priceBlurring.vue";

const emit = defineEmits(["placeOrder", "collected", "viewDetail"]);
const props = defineProps({
  index: Number,
  productName: String,
  startCityName: String,
  endCountryName: String,
  endWarehouseName: String,
  aging: String,
  goodsTypeText: String,
  currency: String,
  chargeUnit: String,
  price: [String, Number],
  needInquiryFlag: Boolean,
  // 已收藏
  isCollected: Boolean,
  preferentialFlag: Boolean
  // bgUrl:{
  //   type:String,
  //   default: ossPath + "/pages/home/product_line_item_bg.png",
  // }
});
let wrapStyle = computed(() => {
  let obj = {};
  // if(props.bgUrl){
  //   obj["backgroundImage"] = `url(${props.bgUrl})`
  // }
  return obj;
});

const handleDetail = () => {
	emit('viewDetail')
}
</script>

<style scoped lang="scss">
.single-product {
  box-sizing: border-box;
  //width: 378px;
  //height: 230px;
  background-color: #f9f9f9;
  padding: 12px 20px 20px;
  position: relative;
  // border-radius: 12px;
  // background-position: center;
  // background-size: cover;
  // background-repeat: no-repeat;
  // border: 2px solid #F9F9F9;
  // &:hover {
  //   border-color: var(--el-color-primary);
  // }
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  border: 1px solid #ffffff;
  filter: blur(0px);
  position: relative;
  top: 0;
  transition: all 0.3s;
  &:hover {
    top: -10px;
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);
  }
  .charge-price-ico {
    position: absolute;
    width: 67px;
    height: 67px;
    top: -14px;
    right: -14px;
  }
}
.top-row {
  display: flex;
  align-content: center;
  align-items: center;
  margin-bottom: 17px;
  img {
    margin-right: 4px;
    width: 56px;
    height: 18px;
  }
}
.product-name {
  font-size: 16px;
  font-weight: bold;
  color: #0c0c0c;
  line-height: 24px;
  flex: 1;
	&:hover {
		color: var(--el-color-primary);
	}
}
.collected-box {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0c0c0c;
  font-size: 20px;
  cursor: pointer;
  &:hover,
  &.is-active {
    color: #ff9900;
  }
  &:hover {
    opacity: 0.8;
  }
}

.info-row {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: 30px;
}
.single-info {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  width: 160px;
  //margin-right: 10px;
  overflow: hidden;
  box-sizing: border-box;
  &:nth-child(n + 3) {
    margin-top: 9px;
  }
  .icon-box {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    width: 20px;
    height: 20px;
    color: var(--el-color-primary);
    margin-right: 6px;
    .iconfont {
      font-size: 18px;
    }
  }
  .label {
    font-size: 14px;
    font-weight: 500;
    color: #999999;
    margin-right: 6px;
  }
  .text {
    font-size: 14px;
    font-weight: 500;
    color: #111;
    flex: 1;
    //word-wrap: break-word;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    //-webkit-line-clamp: 2;
    //overflow: hidden;
    //text-overflow: ellipsis;
    //display: -webkit-box;
    //-webkit-box-orient: vertical;
    //word-break: break-word;
  }
  &:nth-child(2n) {
    //width: 180px;
    flex: 1 1 160px;
    margin-left: 10px;
  }
}
.main-info {
  display: flex;
  box-sizing: border-box;
  padding: 13px 20px;
  justify-content: space-between;
  width: 340px;
  height: 70px;
  font-size: 12px;
  color: #555555;
  background: #ffffff;
  border-radius: 6px;
  filter: blur(0px);
  background: #ffffff;
  * {
    margin: 0;
    font-size: 12px;
  }
  .poi-box {
    width: 70px;
    min-width: 70px;
    p {
      line-height: 17px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .poi-name {
      line-height: 20px;
      font-size: 14px;
      font-weight: bold;
      color: #333333;
    }
    &.end {
      // text-align: right;
    }
  }
  .center-info {
    font-size: 0;
    text-align: center;
    margin: 0 10px;
    width: 126px;
    img {
      margin: 3px 0;
      width: 126px;
      height: 4px;
    }
  }
}
$bottomRadius: 25px;
.bottom-row {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 50px;
  // border-radius: $bottomRadius;
  padding: 3px;
  box-sizing: border-box;
  // background: var(--el-color-primary);
  background: var(--btn-bg);
  background-size: 100% 100%;
}
.price-row {
  --pb-price-font-size: 24px;
  $priceRowRadius: 22px;
  // flex: 1;
  flex-grow: 0;
  padding-left: 18px;
  max-width: 200px;
  height: 100%;
  border-radius: $priceRowRadius;
  // background-color: #F7F8FB;
  // padding: 0 10px;
  line-height: 44px;
  // text-align: center;
  //.price-row--content{
  //  display: flex;
  //  justify-content: center;
  //  align-items: center;
  //  height: 100%;
  //  background-color: #F7F8FB;
  //  border-radius: inherit;
  //}
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  //.currency{}
  //.price{
  //  font-size: 24px;
  //}
  //.unit{}
  &.price-blurring {
    --pb-price-color: #ffffff;
  }
  .Inquiry {
    font-size: 24px;
  }
}
.submit-btn {
  border-radius: 0 $bottomRadius $bottomRadius 0;
  width: 140px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  color: #521900;
  cursor: pointer;
  transition: 0.2s ease;
  user-select: none;
  &:hover {
    color: #c06840;
    // background-color: rgba(0,0,0,0.1);
    //background: var(--el-color-primary);
  }
  &:active {
    //background: var(--el-color-primary);
    //filter: contrast(130%);
    //font-size: 19px;
    // background-color: rgba(0,0,0,0.3);
    color: #c06840;
  }
}
.ell{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
