<template>
  <div class="single-cooperative-partner">
    <div class="border-container">
      <div class="content">
        <slot/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "cooperativePartnerItem"
}
</script>

<style scoped lang="scss">
$boxWidth:194px;
$boxHeight:78px;
$borderWidth:1px;
$leftSpace:18px;
.single-cooperative-partner{
  width: $boxWidth;
  height: $boxHeight;
  box-sizing: border-box;
  padding: $borderWidth;
  box-shadow: 0 0 57px 0 rgba(116,123,145,0.18);
  clip-path: polygon($leftSpace 0%,100% 0%,$boxWidth - $leftSpace 100%,0% 100%);
  background-color: #E5E5E5;
  cursor: pointer;
  &:hover{
    background-color: var(--el-color-primary);
  }
}
.border-container{
  $containerWidth: $boxWidth - $borderWidth * 2;
  width: 100%;
  height: 100%;
  clip-path: polygon($leftSpace 0%,100% 0%,$containerWidth - $leftSpace 100%,0% 100%);
  background-color: #fff;
  padding: 0 $leftSpace;
  box-sizing: border-box;
}
.content{
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
</style>