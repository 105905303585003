export function userPriceBlurring() {
  const systemStore = useSystemStore();
  const userStore = useUserStore();
  // 是否需要模糊价格
  let isBlurring = computed(()=>{
    if(!!userStore.token) {
      if(systemStore?.systemConfig?.uncheckedShowPriceFlag || userStore.userInfo?.checkStatus === 1){
        return false
      } else if (!systemStore?.systemConfig?.uncheckedShowPriceFlag && userStore.userInfo?.checkStatus !== 1) {
        return true
      }
      return false;
    }else if(!!systemStore?.systemConfig?.omsOpenShowQuotation){
      return false
    }
    return true;
  });
  const bluringType = computed(() => {
    if (userStore.userInfo?.checkStatus !== 1 && !systemStore?.systemConfig?.uncheckedShowPriceFlag && !!userStore.token){
      return 'auth'
    } else {
      return 'login'
    }
  })
  return {
    isBlurring,
    bluringType
  }
}

