import {firstCharacterGroupingEndWarehouseList} from "@/utils/utils.placeOrder";
/**
 * 处理接口获取的 FBA分组目的地(目的仓库) 列表数据
 */
export function disposeDestinationList(list = []){
  if(!isArray(list)) return [];
  // list = list.map(res=>{
  //   if(!isObject(res)) return undefined;
  //   let twoList = res.codeWarehouseList;
  //   if(!isArray(twoList) || !twoList.length) return undefined;
  //   const target = twoList[0];
  //   if(!isObject(target)) return undefined;
  //   const son = target.warehouseDTOList||[];
  //   // 三级列表转2级列表
  //   return {
  //     code:target.code||"",
  //     list:son,
  //   }
  // });
  // list = list.filter(res=>isObject(res));
  // return list;
  list = list.map(item => {
    if(!isObject(item)) return undefined;
    let firstList = []
    item.warehouseList.forEach(res => {
      if(!isObject(res)) return undefined;
      let twoList = res.codeWarehouseList;
      if(!isArray(twoList) || !twoList.length) return undefined;
      const target = twoList[0];
      if(!isObject(target)) return undefined;
      const son = target.warehouseDTOList||[];
      firstList.push({
        code:target.code||"",
        list:son,
      })
    })
    firstList = firstList.filter(res => isObject(res))
    return {
      warehouseType: item.warehouseType,
      warehouseTypeName: item.warehouseTypeName,
      list: firstList
    }
  })
  return list
}

/**
 * 从分组目的地列表中取出 有效（报价） 的item，并返回 [item1,item2....]列表
 */
export function extractValidWarehouseItemList(list = []){
  let itemList = [];
  list.forEach(one=>{
    one.list.forEach(item=>{
      if(item.quoteStatus === 1) itemList.push(item);
    });
  });
  return itemList;
}
/**
 * 从分组目的地中取出可选（不含已选）的item，并返回 [item1,item2....]列表
 */
export function extractCanSelectList(list = [],alreadySelectList= []){
  let alreadySelectIds = alreadySelectList.map(res=>res.id);
  return list.filter(res=>!alreadySelectIds.includes(res.id));
}

/**
 * 将阿联酋私人地址处理为按首字母分组的二级列表
 */
export function disposeAePrivateDestinationInitialGroup(list=[],nameKey="name"){
  let initialData = {};
  list.forEach(item=>{
    let name = item[nameKey];
    if(typeof name === "string" && name){
      let initial = name.slice(0,1);
      initial = initial.toUpperCase();
      if(initialData[initial]){
        initialData[initial].push(item);
      }else{
        initialData[initial] = [item];
      }
    }
  })
  let keys = Object.keys(initialData);
  keys.sort();
  return keys.map(key=>{
    return {
      code:key,
      list: initialData[key]||[],
    }
  });
}


/**
 * element组件弹出层弹出时，关闭自定义弹出层
 * @param value
 * @returns {boolean}
 */
export function handlerSelectDown(value){
  if(!value) return false;
  closeOtherDownMask();
}
/**
 * 模拟点击关闭其他弹窗（）
 */
export function closeOtherDownMask(){
  const _event = document.createEvent("MouseEvents");
  _event.initEvent("click",true,true);
  document.body.dispatchEvent(_event);
}


/**
 * 处理接口获取的 私人地址(城市) 列表数据
 * @param list  {Array<{}>}
 * @param country {{countryId?:number|string,countryName?:string,countryCode?:string,}}
 * @returns {Object[]}
 */
export function disposePrivateCityList(list = [],country={}){
  list = list.map(item=>{
    return Object.assign(item,{
      privateAddressType:2,// 目的国家对应 私人地址派送类型 1:邮编 2：城市
      isPrivateAddress:true,
      isPrivateAddressCity:true,
      _description:`私人地址(城市)${item.nameCn}`,

      namec: `${item.nameCn}`,
      namee: `${item.nameEn}`,

      country: country.countryName||"",
      countryCode: country.countryCode||"",
      countryId: country.countryId||"",
    })
  })
  list = firstCharacterGroupingEndWarehouseList(list);
  list = list.filter(res=>isObject(res));
  return list;
}

/**
 * 生成 私人地址0-9邮编开头列表
 */
export function createPrivateAddressZipCodeList(options){
  return Array(10).fill({}).map((res,index)=>{
    return Object.assign({
      // 目的国家
      country: "",
      countryCode: "",
      countryId: "",
      privateAddressType:1,// 目的国家对应 私人地址派送类型 1:邮编 2：城市
      // 对应城市
      city: "",
      cityId: "",
      // fba 邮编
      id: index,
      namec: `${index}`,
      namee: `${index}`,
      isPrivateAddress:true,
      isPrivateAddressCity:false,
      _description:`私人地址(邮编)${index}`,
    },options);
  })
}

/**
 * 可以输1-5位数字邮编, 或者输入5-4位 ,9位邮编
 * @param text
 */
export function checkInputZipCode(text = ""){
  if(typeof text !== "string") text = String(Text);
  if(!text) return false;
  let reg = /(^\d{1,5}$)|(^\d{5}-\d{4}$)/;
  return reg.test(text);
}
