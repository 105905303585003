<template>
  <div class="page">
    <main-top-header :class="offsetTop > 0 ? 'home-top-w' : 'home-top'" is-transparent-style is-collapse is-absolute />
    <div class="first-screen">
      <home-banner-box :list="bannerList" :bannerSpeed="bannerSpeed" />
      <!--      <div class="next-screen">-->
      <!--        <screen-down-arrows/>-->
      <!--      </div>-->
      <div class="search-wrap over-hide">
        <div class="page-size-container search-container">
          <home-transportation-type-tabs v-model="productTypeCurrent" :list="productTypeList"
            @change="changeProductType" class="margin-bottom-10" />
          <div class="search-row">
            <product-search-item-box label="起运地">
              <select-place-template v-model="startCity" :list="startCityList" :is-loading="startCityLoading"
                :is-default-style="false" filter-hot @change="changeStartCity" class="flex-1" key="homeStartPlace" />
            </product-search-item-box>
            <product-search-item-box label="目的国">
              <select-place-template3 ref="targetEndCountry" v-model="endCountry"
                :default-tab-value="endCountryGroupTabCurrentValue" :tab-head-list="endCountryGroupTabConfig"
                :data="endCountryGroupTabData" :is-loading="endCountryLoading" @change="changeEndCountry"
                filter-other-tabs popper-class="is-end-country" class="flex-1" key="homeEndCountry" />
            </product-search-item-box>
            <product-search-item-box :label="endWarehouseLabel">
              <select-place-template3 v-model="endWarehouse" @selectItem="endWarehouseChange"
                v-model:default-tab-value="endWarehouseGroupTabCurrentValue" :tab-head-list="endWarehouseGroupTabConfig"
                :data="endWarehouseGroupTabData" :is-loading="endWarehouseLoading || privateCityListLoading"
                :placeholder="endWarehousePlaceholder" :disabled="!endCountry.id" class="flex-1"
                :private-address-type="endCountry.privateAddressType" @change="endWarehouseChange"
                slice-zip-code-first-str key="homeEndWarehouse" />
            </product-search-item-box>
            <product-search-item-box label="货物信息">
              <select-goods-info :country-id="endCountry.id" :goods-type-list="goodsTypeList"
                :goods-type-loading="goodsTypeLoading" v-model:goods-type="goodsType" v-model:goods-name="goodsName"
                @selectGoodsType="changeGoodsType" :placeholder="goodsTypePlaceholder" key="homeGoodsInfo"
                class="flex-1" clearable />
            </product-search-item-box>
            <product-search-item-box class="flex-1 margin-right-15 is-cbm-box">
              <template #label>
                精准查价
                <el-tooltip placement="top" content="">
                  <template #content>
                    <div style="max-width: 300px">
                      输入件毛体运价查询可查出具体的单价及运费！
                    </div>
                  </template>
                  <span><el-icon color="#999999" class="font-14">
                      <QuestionFilled />
                    </el-icon></span>
                </el-tooltip>
              </template>
              <template #default>
                <div class="flex">
                  <div class="margin-top-10 padding-left-15" style="display: inline-flex">
                    <el-input v-model="cbm" placeholder="体积" class="volume-weight-input"><template
                        #suffix>CBM</template></el-input>
                  </div>
                  <div class="margin-top-10 padding-left-15" style="display: inline-flex">
                    <el-input v-model="weight" placeholder="重量" class="volume-weight-input"><template
                        #suffix>KG</template></el-input>
                  </div>
                  <div class="margin-top-10 padding-left-15" style="display: inline-flex">
                    <el-input v-model="boxNumber" placeholder="箱数" class="volume-weight-input"><template
                        #suffix>箱</template></el-input>
                  </div>
                </div>
              </template>
            </product-search-item-box>
            <!--            <div class="flex-1"></div>-->
            <el-button type="primary" class="search-btn" @click="clickHomeSearch"><el-icon class="margin-right-20">
                <Search />
              </el-icon>查询</el-button>
          </div>
        </div>
      </div>
      <screen-down-arrows class="next-screen-box" @click="toNextScreen" />
    </div>
    <template v-for="(moduleItem, i) in homeVisibleModuleNames" :key="i">
      <!-- {{moduleItem}} -->
      <template v-if="moduleItem === 'CoverageCountry'">
        <g-wrap :bg-url="ossPath + '/pages/home/coverage_country_bg.png'" class="coverage-country-box">
          <title-row class="" :titletip="homeModuleOb ? homeModuleOb[moduleItem]?.secondTitle : ''
            ">覆盖国家</title-row>
          <coverage-country :data="coverageCountryList" v-model:current-value="coverageCountryCurrentValue" />
        </g-wrap>
      </template>
      <template v-if="moduleItem === 'DominantLine'">
        <title-row class="" style="margin-bottom: 60px"
          :titletip="homeModuleOb ? homeModuleOb[moduleItem]?.secondTitle : ''">{{codeMapName[moduleItem]}}</title-row>
        <dominant-product-line :list="dominantProductLineList" :omsUncheckedCustomerOrderFlag="omsUncheckedCustomerOrderFlag"/>
      </template>
      <template v-if="moduleItem === 'MyAdvantage'">
        <my-advantage :list="myAdvantageList">
          <title-row :titletip="homeModuleOb ? homeModuleOb[moduleItem]?.secondTitle : ''
            ">{{codeMapName[moduleItem]}}</title-row>
        </my-advantage>
      </template>
      <template v-if="moduleItem === 'CooperativeClient'">
        <title-row class="cooperation"
          :titletip="homeModuleOb ? homeModuleOb[moduleItem]?.secondTitle : ''">{{codeMapName[moduleItem]}}</title-row>
        <cooperative-partner :list="cooperativePartnerList" />
      </template>
      <div v-if="moduleItem === 'PressCenter'" class="home-news">
        <title-row class="" style="margin-bottom: 30px"
          :titletip="homeModuleOb ? homeModuleOb[moduleItem]?.secondTitle : ''">{{codeMapName[moduleItem]}}</title-row>
        <home-news class="" :tabs="newsTabs" v-model="newsTabsCurrent" :list="newsList" @change="changeNewsTab" :left-url="newsFileUrl" />
      </div>

      <div v-if="moduleItem === 'CustomDefinitionHome'" class="home-news">
        <title-row class="" style="margin-bottom: 30px"
          :titletip="homeModuleOb ? homeModuleOb[moduleItem]?.secondTitle : ''">{{codeMapName[moduleItem]}}</title-row>
        <CustomerDefination :customDefinition="CustomDefinitionHomeList"></CustomerDefination>
      </div>
    </template>

    <!-- <template v-if="homeVisibleModuleNames.includes('DominantLine')">
        <title-row class="" style="margin-bottom: 60px">
          优势产品线路
        </title-row>
        <dominant-product-line :list="dominantProductLineList" />
      </template>
      <template v-if="homeVisibleModuleNames.includes('CoverageCountry')">
      <g-wrap
        :bg-url="ossPath + '/pages/home/coverage_country_bg.png'"
        class="coverage-country-box"
      >
        <title-row class="">覆盖国家</title-row>
        <coverage-country
          :data="coverageCountryList"
          v-model:current-value="coverageCountryCurrentValue"
        />
      </g-wrap>
    </template>
    <template v-if="homeVisibleModuleNames.includes('MyAdvantage')">
      <my-advantage :list="myAdvantageList">
        <title-row is-white-style>我们的优势</title-row>
      </my-advantage>
    </template>
    <template v-if="homeVisibleModuleNames.includes('CooperativeClient')">
      <title-row class="cooperation">合作伙伴</title-row>
      <cooperative-partner :list="cooperativePartnerList" />
    </template>
    <div
      v-if="homeVisibleModuleNames.includes('PressCenter')"
      class="home-news"
    >
      <title-row class="" style="margin-bottom: 30px">新闻中心</title-row>
      <home-news
        class=""
        :tabs="newsTabs"
        v-model="newsTabsCurrent"
        :list="newsList"
        @change="changeNewsTab"
      />
    </div> -->

    <!--    <div style="height: 1000px;background-color: #eee;"></div>-->
    <main-footer />
    <fixed-tools :visible-top="scrollY >= 100" :top-callback="toTop" />
  </div>
</template>

<script setup>
import MainTopHeader from "@/components/mainTopHeader/mainTopHeader.vue";
import TitleRow from "@/components/titleRow/titleRow.vue";
import MainFooter from "@/components/mainFooter/mainFooter.vue";
import HomeBannerBox from "@/views/home/components/homeBannerBox/homeBannerBox.vue";
import { useScrollFirstScreen } from "@/views/home/utils/homeScroll";
import ScreenDownArrows from "@/views/home/components/screenDownArrows/screenDownArrows.vue";
import CoverageCountry from "@/views/home/components/coverageCountry/coverageCountry.vue";
import { useCoverageCountry } from "@/views/home/components/coverageCountry/coverageCountry.utils";
import { useDominantProductLine } from "@/views/home/components/dominantProductLine/dominantProductLine.utils";
import GWrap from "@/components/gWrap/gWrap.vue";
import DominantProductLine from "@/views/home/components/dominantProductLine/dominantProductLine.vue";
import MyAdvantage from "@/views/home/components/myAdvantage/myAdvantage.vue";
import CooperativePartner from "@/views/home/components/cooperativePartner/cooperativePartner.vue";
import HomeNews from "@/views/home/components/homeNews/homeNews.vue";
import CustomerDefination from "@/views/home/components/CustomDefinitionHome/CustomDefinitionHome.vue";
import HomeTransportationTypeTabs from "@/views/home/components/homeTransportationTypeTabs/homeTransportationTypeTabs.vue";
import ProductSearchItemBox from "@/components/productSearch/productSearchItemBox.vue";
import SelectPopover from "@/views/home/components/selectPopover/selectPopover.vue";
import SelectScrollView from "@/views/home/components/selectScrollView/selectScrollView.vue";
import { useHomeSearch } from "@/views/home/utils/homeSearch";
import SelectPopover2 from "@/views/home/components/selectPopover/selectPopover2.vue";
import { useHomeBannerList } from "@/views/home/components/homeBannerBox/homeBanner.utils";
import SelectPlaceTemplate from "@/components/newSelectPlace/selectPlaceTemplate.vue";
import SelectPlaceTemplate3 from "@/components/newSelectPlace/selectPlaceTemplate3.vue";
import { ElMessage } from "element-plus";
import FixedTools from "@/components/fixedTools/fixedTools.vue";
import { useFixedToolsScroll } from "@/components/fixedTools/fixedTools.utils";
import SelectGoodsInfo from "@/components/newSelectPlace/selectGoodsInfo.vue";
import { useHomeOtherModule } from "@/views/home/utils/home.utils";
import { ossPath } from "../../config/variables";
import { useHead } from "@unhead/vue";
import { getTenantId } from "@/utils/utils.commonTenantParams";
const router = useRouter();
const systemStore = useSystemStore();
// const userStore = useUserStore();

let { bannerList, getBannerList, bannerSpeed } = useHomeBannerList();

console.log("首页的bannerSpeed", bannerSpeed);

// 首屏滚动
const { toNextScreen, toTop, openFirstScreenScroll } = useScrollFirstScreen();

const { scrollY } = useFixedToolsScroll();

// 筛选
let {
  // 物流产品类型
  productTypeList,
  productTypeCurrent,
  changeProductType,
  getProductType,
  // 获取的默认起运地目的国
  defaultCityCountry,
  getDefaultCityAndCounty,
  // 起运地
  startCity,
  startCityList,
  startCityLoading,
  getStartCityList,
  // changeStartCity,
  // 目的国
  endCountry,
  endCountryGroupTabData,
  endCountryGroupTabConfig,
  endCountryLoading,
  endCountryGroupTabCurrentValue,
  getEndCountryContinentList,
  changeEndCountry,
  // 目的地
  endWarehouse,
  endWarehouseChange,
  endWarehouseSelectList,
  endWarehouseLoading,
  privateCityListLoading,
  endWarehouseLabel,
  endWarehousePlaceholder,
  endWarehouseGroupTabCurrentValue,
  endWarehouseGroupTabCurrentValueChange,
  endWarehouseGroupTabConfig,
  endWarehouseGroupTabData,
  getEndWarehouseFbaList,
  changeEndWarehouse,
  resetEndWarehouse,
  // 货物类型
  goodsType,
  goodsTypeLoading,
  goodsTypeList,
  goodsTypePlaceholder,
  getGoodsTypeList,
  changeGoodsType,
  // 产品(商品)
  goodsName,
  goodsNameLoading,
  goodsNameList,
  // 体积
  cbm,
  weight,
  boxNumber,
} = useHomeSearch();

let targetEndCountry = ref(null);

function changeStartCity() {
  let $input = unref(targetEndCountry)?.$refs?.selectStartInput;
  console.log($input);
  if ($input?.focus) {
    nextTick(() => {
      setTimeout(() => {
        $input.focus();
      }, 200);
    });
  }
}

// async function changeProductType() {
//   // 清空 选中的目的地
//   // resetEndWarehouse()
// }
// 初始化默认起运地和目的地
async function initDefaultStartAndDestination() {
  // if(!defaultCityCountry.value.defaultFlag){}
  let defaultCity;
  let defaultCountry;
  let defaultResult = await getDefaultCityAndCounty();
  if (defaultResult && defaultResult.returnCode === "200") {
    let data = defaultResult.data || {};
    defaultCityCountry.value = data;
    if (data.fromCityId) {
      defaultCity = {
        namec: data.fromCityName,
        namee: "",
        id: data.fromCityId,
      };
      startCity.value = defaultCity || {};
    }
    if (data.toCountryId) {
      defaultCountry = {
        id: data.toCountryId,
        code: data.toCountryCode,
        namec: data.toCountryName,
        namee: "",
        privateAddressType: data.privateAddressType,
      };
      endCountry.value = defaultCountry || {};
      changeEndCountry(defaultCountry || {}, {
        autoNext: true,
        autoGetProduct: false,
      });
    }
  }
}

/**
 * 点击 首页查询
 */
let clickHomeSearch = throttle(homeSearch, 1000);

/**
 * 判断搜索条件并跳转物流商城
 * @return {MessageHandler}
 */
function homeSearch() {
  // 起运城市
  let _startCity = startCity.value;
  // 目的国
  let _endCountry = endCountry.value;
  // 目的地
  let _endWarehouse = endWarehouse.value || {};
  // 货物类型
  let _goodsType = goodsType.value;

  if (!_startCity.id) return ElMessage.info("请先选择起运地");
  if (!_endCountry.id) return ElMessage.info("请先选择目的国");
  if (!_endWarehouse.id && _endWarehouse.id !== 0)
    return ElMessage.info("请先选择目的地");
  if (!_endWarehouse.id || _endWarehouse.id === "all")
    _endWarehouse = {
      id: "",
      namec: "",
    };
  if (!_goodsType.goodsTypeId && _goodsType.goodsTypeId !== 0)
    return ElMessage.info("请先选择货物信息");
  let _goodsName = goodsName.value;
  let searchData = {
    // 物流产品类型
    transportationType: productTypeCurrent.value,
    // 起运城市
    startCityId: _startCity.id,
    startCityName: _startCity.namec,
    // 目的国
    endCountryId: _endCountry.id,
    endCountryName: _endCountry.namec,
    endCountryCode: _endCountry.code,
    privateAddressType: _endCountry.privateAddressType,
    // 目的地（目的仓）
    endWarehouseId: _endWarehouse.id,
    endWarehouseName: _endWarehouse.namec,
    isPrivateAddress: _endWarehouse.isPrivateAddress,
    endWarehouseIsFba: _endWarehouse.isFba,
    endWarehouseIsFbx: _endWarehouse.isFbx,
    warehouseType: _endWarehouse.warehouseType,
    warehouseTypeName: _endWarehouse.warehouseTypeName,
    // 货物类型
    goodsTypeId: _goodsType.goodsTypeId,
    goodsTypeName: _goodsType.goodsTypeName,
    // 商品名称
    goodsNameId: _goodsName.id,
    goodsNameName: _goodsName.label,
    // cbm
    cbm: cbm.value,
    weight: weight.value,
    boxNumber: boxNumber.value,
  };
  Object.keys(searchData).forEach((key) => {
    let value = searchData[key];
    if (typeof value === "undefined") searchData[key] = "";
  });
  console.log("productTypeList", productTypeList, productTypeCurrent.value);
  // 操作内容
  // 业务类型
  let data1 =
    "业务类型:" +
    productTypeList.value.find((item) => {
      return item.value == productTypeCurrent.value;
    }).label;
  // 起运地
  let data2 = "起运地：" + startCity.value.namec;
  //目的国
  let data3 = "目的国：" + endCountry.value.namec;
  // 目的地
  let data4 = "目的地:" + endWarehouse.value.namec || {};
  // 货物类型
  let data5 = "货物类型:" + goodsType.value.goodsTypeName;
  let info1 = cbm.value ? cbm.value : "/";
  let info2 = weight.value ? weight.value : "/";
  let info3 = boxNumber.value ? boxNumber.value : "/";
  // 货物信息
  let data6 =
    "货物信息:" + info1 + "CBM" + "," + info2 + "KG" + "," + info3 + "箱";
  let user_info = JSON.parse(localStorage.getItem("user_info")) || {};
  let use_btn_permission_list =
    JSON.parse(localStorage.getItem("use_btn_permission_list")) || [];
  if (
    Array.isArray(use_btn_permission_list) &&
    use_btn_permission_list.length > 0
  ) {
    api.operate.addSysUserOperateLog({
      customerId: user_info.customerId,
      userId: user_info.id,
      operationButtonCode: "HOME_PAGE_QUERY",
      operationContent:
        data1 +
        "," +
        data2 +
        "," +
        data3 +
        "," +
        data4 +
        "," +
        data5 +
        "," +
        data6,
      appType: "oms",
      productPriceHeaderId: "",
      loginType: "",
      remark: "",
    });
  }

  localStorage.setItem("search_product_list_data", JSON.stringify(searchData));
  router.push({
    path: "/productList",
    query: {
      remember: "no",
    },
  });
}
// 覆盖国家
let {
  coverageCountryList,
  coverageCountryCurrentValue,
  getCoverageCountryList,
} = useCoverageCountry();
let { dominantProductLineList, getDominantProductLineList } =
  useDominantProductLine();

let {
  homeModuleOb,
  homeVisibleModuleNames,
  getHomeModuleVisibleSetting,

  myAdvantageList,
  getMyAdvantageList,

  cooperativePartnerList,
  getCooperativePartnerList,

  newsDataCache,
  newsTabs,
  newsTabsCurrent,
  newsList,
  newsLoading,
  getHomeNews,
  changeNewsTab,
  getHomeCustomerDefination,
  CustomDefinitionHomeList,
	newsFileUrl
} = useHomeOtherModule();
// 新闻中心展示与否
let newsCenterVisible = ref(false)
/**
 * 首页加载初始化
 * @returns {Promise<void>}
 */
// 模块代码映射模块名称
let codeMapName = ref({})
async function initHomeModule() {
  getBannerList();
  initDefaultStartAndDestination();
  getStartCityList();
  getEndCountryContinentList();
  systemStore.autoGetSystemConfig();
  // 物流产品类型
  getProductType();
  getGoodsTypeList();
  let resultModuleSetting = await getHomeModuleVisibleSetting();


  if (resultModuleSetting && resultModuleSetting.returnCode === "200") {
    let names = homeVisibleModuleNames.value;
    resultModuleSetting.data.forEach((item) => {
      codeMapName.value[item.blockCode] = item.blockName;
    });
    if (names.includes("CoverageCountry")) {
      // 覆盖国家
      getCoverageCountryList();
    }
    if (names.includes("DominantLine")) {
      // 优势线路
      getDominantProductLineList();
    }
    if (names.includes("MyAdvantage")) {
      // 我的优势
      getMyAdvantageList();
    }
    if (names.includes("CooperativeClient")) {
      // 合作伙伴
      getCooperativePartnerList();
    }
    if (names.includes("PressCenter")) {
      // 新闻中心
      getHomeNews();
    }
    if (names.includes("CustomDefinitionHome")) {
      // 自定义
      getHomeCustomerDefination();
    }
  }
  await nextTick(() => {
    openFirstScreenScroll();
  });
}

/**
 * 动态获取百度配置代码
 * */
// 未审核客户是否可以下单
let omsUncheckedCustomerOrderFlag = ref(false)
let getSystemInfo = () => {
  let tenantId = getTenantId();
  if (!!tenantId) {
    api.operate.getSysConfig(tenantId).then((res) => {
      if (res.returnCode == 200) {
        omsUncheckedCustomerOrderFlag.value = res.data.omsUncheckedCustomerOrderFlag;
        let head = document.querySelector("head");
        let findScript = document.querySelector("#baiduCode");
        if (findScript) {
          findScript.innerHTML = res.data.baiduStatisticCode;
        } else {
          let script = document.createElement("script");
          script.id = "baiduCode";
          script.type = "text/javascript";
          script.innerHTML = res.data.baiduStatisticCode;
          head.appendChild(script);
        }
      }
    });
  }
};

const offsetTop = ref(0);
onMounted(() => {
  let public_footer_info = JSON.parse(
    localStorage.getItem("public_footer_info") || {}
  );
  if (Array.isArray(public_footer_info.pageConfigList)) {
    let item = public_footer_info.pageConfigList.find((findItem) => {
      return findItem.pageCode == "Home_Page";
    });
    useHead({
      title: public_footer_info?.omsWebsiteName || '首页',
      meta: [
        {
          name: "description",
          content: item?.description || '',
        },
      ],
    });
  }

  initHomeModule();
  window.addEventListener("scroll", () => {
    offsetTop.value = window.pageYOffset;
  });
  // 动态配置百度统计代码
  getSystemInfo();
});
</script>

<style scoped lang="scss">
h3 {
  border-bottom: 1px solid #eee;
}

.first-screen {
  height: 100vh;
  position: relative;
  z-index: 1;
  background: #eee;

  .next-screen-box {}

  .search-wrap {
    position: absolute;
    z-index: 2;
    left: 0;
    right: 0;
    bottom: 17.5%;
  }

  .search-container {
    box-sizing: border-box;
    // height: 226px;
    padding: 20px;
    //background-color: var(--el-color-primary-light-9);
    border: 1px solid rgba(255, 255, 255, 1);
    border-radius: 12px;
    position: relative;
    z-index: 1;

    &:before {
      $--border-width: 0px;
      content: "";
      z-index: 0;
      position: absolute;
      top: $--border-width;
      bottom: $--border-width;
      left: $--border-width;
      right: $--border-width;
      background-color: var(--el-color-primary-light-9);
      opacity: 0.6;
      //border:  $--border-width solid rgba(255, 255, 255, 0.6);
      border-radius: inherit;
    }
  }

  .transportation-type-tabs,
  .search-row {
    position: relative;
    z-index: 1;
  }

  .search-row {
    box-sizing: border-box;
    height: 88px;
    background-color: #ffffff;
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding-right: 5px;
  }

  :deep(.product-search-item-box) {}

  .is-cbm-box {
    min-width: 400px;
  }

  .search-btn {
    flex: 1;
    box-sizing: border-box;
    width: 153px;
    height: 79px;
    border-radius: 10px;
    font-size: 18px;
    font-weight: bold;
  }
}

.home-top {
  :deep(.main-top-header--container) {
    background-color: rgba(255, 255, 255, 0.4);
  }
}

.home-top-w {
  :deep(.main-top-header--container) {
    background-color: #ffffff;
  }
}

.volume-weight-input {
  background-color: #f6f6f6;
  border-radius: 15px;
  height: 30px;
  padding: 0 10px;
  font-size: 18px;
  color: #333;
  font-weight: 600;

  :deep(.el-input__wrapper),
  :deep(input) {
    background-color: transparent;
    box-shadow: none;
    padding: 0;
    color: inherit;
    font-weight: inherit;
  }

  :deep(input) {
    min-width: 0;
    width: 100%;
    height: 100%;
    border: none;
  }

  ::-webkit-input-placeholder {
    font-weight: 400;
    color: #999;
  }

  :deep(.el-input__suffix) {
    margin-left: 6px;
    font-size: 14px;
    font-weight: 500;
    color: #333333;
  }
}

.coverage-country-box {
  overflow: hidden;

  .title-row {
    margin-bottom: 60px;
  }
}

.cooperation {
  margin-bottom: 60px;
}

.home-news {
  padding-top: 1px;
  background-color: #f8f8f8;
}
</style>
