


export function useScrollFirstScreen() {
  // 记录滚动条历史位置
  let originY = 0;
  // 滚动计时器目标
  let scrollTimeout = -1;
  // 是否处于滚动状态
  let scrollStatus = false;

  // onActivated(()=>{})
  onMounted(()=>{
    // openFirstScreenScroll()
  })
  // onDeactivated(()=>{})
  onBeforeUnmount(()=>{
    closeFirstScreenScroll()
  })
  /**
   * 滚动到指定位置
   * @param y {number} 要滚动到的位置
   * @param currentY {number} 当前位置
   * @param spaceTime {number?} 每个滚动频率的间隔 ms （值越小约顺滑）
   * @param n {number?} 完成滚动的频率（值越大约顺滑）
   * @type {DebouncedFunc<function(*, *, *=, *=): number>}
   */
  const scrollValue = throttle(function(y,currentY,spaceTime=5,n=60){
    scrollStatus = true;
    // return false;
    clearInterval(scrollTimeout);
    let space = (y - currentY)/n;
    space = Math.ceil(space);
    let i = 1;
    scrollTimeout = setInterval(()=>{
      let nextY = currentY + space * i;
      if(i++ >= n || (y > currentY ? nextY >= y : nextY < y)) {
        clearInterval(scrollTimeout);
        nextY = y;
        document.documentElement.scrollTop = nextY;
        setTimeout(()=>{
          scrollStatus = false;
          // console.log("滚动完成");
        },100);
      }else{
        document.documentElement.scrollTop = nextY;
      }
    },spaceTime);
    return scrollTimeout;
  },100,{trailing:false})
  /**
   * 滚动监听
   */
  function scrollCallback() {
    let y = getScrollY();
    if(scrollStatus){
      originY = y;
    }else{
      // 是否向下
      let isDownward = y > originY;
      // 是否向上
      let isUp = y < originY;
      let text = "";
      if(isDownward) text = "向下";
      if(isUp) text = "向上";
      // console.log("滚动监听",text,"y = ",y,"originY = ",originY)
      originY = y;
      if(isDownward || isUp){
        let screenHeight = document.documentElement.clientHeight;
        // console.log("screenHeight = ",screenHeight);
        if(isDownward){
          if( y < screenHeight){
            // console.log("下一屏")
            scrollStatus = true;
            scrollValue(screenHeight,y);
          }
        }else if(isUp){
          if(y < screenHeight){
            // console.log("第一屏")
            scrollStatus = true;
            scrollValue(0,y);
          }
        }
      }
    }
  }

  /**
   * 获取当前滚动条位置
   * @return {number}
   */
  function getScrollY() {
    return window.pageYOffset || document.documentElement.scrollTop;
  }

  /**
   * 手动滑动到第二屏
   * @type {DebouncedFunc<(function(): void)|*>}
   */
  const toNextScreen = throttle(()=>{
    let y = getScrollY();
    let screenHeight = document.documentElement.clientHeight;
    scrollValue(screenHeight,y);
  },200,{trailing:false});
  /**
   * 滚动到顶部
   */
  function toTop(){
    let y = getScrollY();
    scrollStatus = true;
    scrollValue(0,y);
  }

  /**
   * 开启首屏滚动
   */
  function openFirstScreenScroll() {
    originY = getScrollY();
    console.log("%c 开启首屏滚动","color:#e87414");
    window.addEventListener("scroll",scrollCallback)
  }
  /**
   * 关闭首屏滚动
   */
  function closeFirstScreenScroll() {
    clearInterval(scrollTimeout);
    console.log("%c 关闭首屏滚动","color:#e87414");
    window.removeEventListener("scroll",scrollCallback);
  }
  return {
    toNextScreen,
    toTop,
    openFirstScreenScroll,
    closeFirstScreenScroll,
  }
}
