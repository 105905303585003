import axiosMain from "@/axios";
import { disposeProductDetails } from "@/utils/product.utils";

export const useProductDetail = () => {
  const productDetailData = ref({})
  const visibleProductDetail = ref(false)
  const startCity = ref({})
  const endCountry = ref({})
  const endWarehouse = ref({})
  const productType = ref('FBX')

  const getProductDetail = (id, item) => {
    let url = `/oms/quotedPrice/queryQuotedPriceInfoDetail/${id}`;
    return axiosMain.post(url)
      .then(res => {
        let details = res.data || {};
        if (details.effectiveEndTime)
          details.effectiveEndTimeText = dayjs(details.effectiveEndTime).format(
            "YYYY-MM-DD HH:mm:ss"
          );
        if (details.effectiveStartTime)
          details.effectiveStartTimeText = dayjs(
            details.effectiveStartTime
          ).format("YYYY-MM-DD HH:mm:ss");
        disposeProductDetails(res.data || {});
        productDetailData.value = details;
        productDetailData.value.needInquiryFlag = item?.needInquiryFlag;
        productType.value = res.data.businessType
        visibleProductDetail.value = true;
      })
      .catch(err => {
        console.log(err)
        productDetailData.value = {};
        visibleProductDetail.value = false;
        return err
      })
  }
  return {
    productDetailData,
    visibleProductDetail,
    startCity,
    endCountry,
    endWarehouse,
    productType,
    getProductDetail
  }
}
