import mitt from "mitt";

export const bus = mitt();

/*
 * 登录注册弹窗相关
 */
// 显示登录弹窗
const showLoginPopover = () => bus.emit("showLoginPopover");
// 切换登录弹窗的相关参数
const switchLoginPopoverParams = () => bus.emit("switchLoginPopoverParams",{
// 页面类型 1:登录 2:注册
  pageType:1,
  // 登录类型,1 账号密码登录；2 手机验证码登录；3 扫描登录（暂时没有）
  loginType:1,
  // 注册类型
  registerType:1,
})

/**
 * 显示登录弹窗
 */
export function busShowLoginPopover (){
  bus.emit("showLoginPopover",{})
  bus.emit("switchLoginPopoverParams",{
    pageType:1,
    loginType:1,
  })
}
/**
 * 显示注册弹窗
 */
export function busShowRegisterPopover (){
  bus.emit("showLoginPopover",{})
  bus.emit("switchLoginPopoverParams",{
    pageType:2,
    registerType:1,
  })
}