<template>
  <div class="transportation-type-tabs" :class="[styleClass]">
    <home-transportation-type-tabs-item
      class="item"
      v-for="(item, index) in list"
      :Key="`${item.value || index}`"
      :label="item.label"
      :value="item.value"
      :icon="item.icon"
      :iconType="item.iconType"
      :is-active="modelValue === item.value"
      @click="clickItem(item, index)"
    />
  </div>
</template>

<script setup>
import HomeTransportationTypeTabsItem from "@/views/home/components/homeTransportationTypeTabs/homeTransportationTypeTabsItem.vue";
const emit = defineEmits(["update:modelValue", "change"]);
const props = defineProps({
  list: {
    type: Array,
    default: () => [],
  },
  modelValue: [String, Number],
  isWhiteStyle: Boolean,
  isDefaultStyle: Boolean,
  isBlackStyle: Boolean,
});
const styleClass = computed(() => {
  let defaultStyle = "";
  if (props.isDefaultStyle) {
    return "is-default-style";
  } else if (props.isBlackStyle) {
    return "is-black-style";
  } else if (props.isWhiteStyle) {
    return "is-white-style";
  }
  return defaultStyle;
});
function clickItem(item, index) {
  if (props.modelValue !== item.value) {
    emit("update:modelValue", item.value);
    emit("change", item.value, item);
  }
}
</script>

<style scoped lang="scss">

.transportation-type-tabs {
  display: flex;
  align-items: center;
}
.is-default-style{
  //--pt-font-color:var(--el-color-primary);
  //--pt-item-background-color: transparent;
  //--pt-item-active-background-color:var(--el-color-primary);
  //--pt-font-active-color:white;
}
.is-white-style{
  --pt-font-color: white;
  //--pt-item-background-color: transparent;
  //--pt-item-active-background-color:var(--el-color-primary);
  //--pt-font-active-color:white;
}
.is-black-style{
  --pt-font-color: #333333;
  //--pt-item-background-color: transparent;
  //--pt-item-active-background-color:var(--el-color-primary);
  //--pt-font-active-color:white;
}
//.is-white-style :deep(.item) {
//  color: white;
//  &:not(.is-active):hover {
//    color: var(--el-color-primary);
//  }
//}
</style>
