
import {useUserStore} from "@/stores/user";

export const btnPermise = {
  mounted: function (el, binding) {
    setTimeout(() => {
      const display = el.style.display
      const userStore = useUserStore();
      el.style.display = 'none'
      const btns = userStore.btnPermission
      console.log(el)

        if (btns === undefined) {
          el.parentNode.removeChild(el)
          return
        }
        if (userStore.checkBtnPermission(binding.value.k, btns) || !binding.value.k) {
          el.style.display = display
        } else {
          el.parentNode.removeChild(el)
        }
    }, 0)
  }
}
