import {darkColor, tintColor} from "@/utils/utils.color";
/**
 * 改变网站主题色
 * @param color
 */
export function alterWebPrimaryColor(color){
  setLocalPrimaryColor(color);
  let cssParams = {};
  if(color){
    cssParams = {
      "--el-color-primary":color,
    }
    let lightArr = [3, 5, 7, 7.5, 8, 9];
    lightArr.forEach(v => {
      cssParams[`--el-color-primary-light-${v}`] = tintColor(color, v / 10);
    })
    let darkArr = [2];
    darkArr.forEach(v => {
      cssParams[`--el-color-primary-dark-${v}`] = darkColor(color, v / 10);
    })
    console.log(cssParams);
    let $body = document.querySelector("body");
    Object.keys(cssParams).forEach(key=>{
      let value = cssParams[key];
      console.log(key,value);
      $body.style.setProperty(key, value);
    })
  }
  // document.querySelector("body").style.setProperty("--el-color-primary", color);
}

/**
 * 在本地缓存网站主题色
 * @param val
 */
export function setLocalPrimaryColor(val) {
  localStorage.setItem("primary_color",val);
}

/**
 * 获取缓存的网站主题色
 * @return {string|string}
 */
export function getLocalPrimaryColor() {
  // "#2B62ED"
  return localStorage.getItem("primary_color") || "";
}