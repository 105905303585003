import {ref, computed, reactive} from 'vue'
import { defineStore } from 'pinia'

export const useLayoutStore = defineStore('layout', () => {
  // 侧边栏（菜单）是否收起
  const isCollapse = ref(false);

  // 展开/收起侧边栏
  function switchIsCollapse(value){
    if(typeof value === "boolean"){
      isCollapse.value = value;
    }else{
      isCollapse.value = !isCollapse.value;
    }
    console.log(isCollapse.value);
  }

  // 需要缓存的组件名称列表
  const keepAliveComponents = ref([]);
  // 设置需要缓存的组件列表
  function setKeepAliveComponents(list = []) {
    keepAliveComponents.value = list;
  }
  // 从需要缓存的组件列表中追加某项
  function pushKeepAliveComponents(name) {
    keepAliveComponents.value.push(name);
  }
  // 从需要缓存的组件列表中移除某项
  function deleteKeepAliveComponents(name) {
    const index = keepAliveComponents.value.indexOf(name)
    if (index !== -1) {
      keepAliveComponents.value.splice(index, 1)
    }
  }
  return {
    isCollapse,
    switchIsCollapse,
    keepAliveComponents,
    setKeepAliveComponents,
    pushKeepAliveComponents,
    deleteKeepAliveComponents,
  }
})