import { ref, computed, reactive } from "vue";
import { defineStore } from "pinia";
import { isArray } from "@/utils/utils";
import router from "@/router";
import {setLocalSourceType} from "@/utils/utils.commonTenantParams";

/**
 * 将token保存在浏览器本地缓存
 * @param token
 */
export function setLocalToken(token) {
  localStorage.setItem("token", token);
}

/**
 * 从浏览器本地缓存获取token
 * @return {string}
 */
export function getLocalToken() {
  return localStorage.getItem("token");
}

/**
 * 将userInfo保存在浏览器本地缓存
 * @param params
 */
export function setLocalUserInfo(params) {
  let str = "";
  if (isObject(params)) {
    try {
      str = JSON.stringify(params);
    } catch (e) {
      console.error(e);
    }
  }
  localStorage.setItem("user_info", str);
}
/**
 * 从浏览器本地缓存获取token
 * @param key
 */
export function getLocalUserInfo(key) {
  let user = null;
  try {
    user = localStorage.getItem("user_info");
    if (user) user = JSON.parse(user);
  } catch (e) {
    // console.warn("getLocalUserInfo 解析用户信息异常!");
    console.error(e);
  }
  if (!isObject(user)) user = {};
  if (!(typeof key === "string" && key)) return user;
  return user[key];
}
/**
 * 获取缓存的用户按钮权限列表
 * @return {Array<string>}
 */
function getLocalBtnPermission() {
  let value = localStorage.getItem("use_btn_permission_list");
  if (value) {
    try {
      let list = JSON.parse(value);
      if (!isArray(list)) list = [];
      return list;
    } catch (e) {
      console.error(e);
      return [];
    }
  }
  return [];
}

/**
 * 缓存用户按钮权限列表
 * @param list  {Array<string>?} 页面权限列表 [btnKey1,btnKey2,btnKey3....]
 */
function setLocalBtnPermission(list) {
  if (!isArray(list)) list = [];
  localStorage.setItem("use_btn_permission_list", JSON.stringify(list));
}

/**
 * 获取缓存的用户页面权限列表
 * @return {Array<string>}
 */
export function getLocalPagePermission() {
  let value = localStorage.getItem("use_page_permission_list");
  if (value) {
    try {
      let list = JSON.parse(value);
      if (!isArray(list)) list = [];
      return list;
    } catch (e) {
      console.error(e);
      return [];
    }
  }
  return [];
}

/**
 * 缓存用户页面权限列表
 * @param list  {Array<string>?} 页面权限列表 [path1,path2,path3....]
 */
export function setLocalPagePermission(list) {
  if (!isArray(list)) list = [];
  localStorage.setItem("use_page_permission_list", JSON.stringify(list));
}
// 所有权限code集合
let menuAuthorityCodes = disposeMenuAuthorityList(getLocalPagePermission());
/**
 * 判单是否有页面访问权限
 * @param toCode  {string|Array<string>}  页面对应的权限代码
 */
/**
 * 判单是否有页面访问权限
 * @param toCode  {string|Array<string>}  页面对应的权限代码
 * @param list  {Array<string>?} 所有权限code集合
 * @returns {boolean|*}
 */
export function checkPagePermission(toCode, list = menuAuthorityCodes) {
  // return true;
  // console.log("toCode", toCode);
  // console.log("list", list);
  // 各页面对应的权限代码
  // My order	我的订单
  // Special line order	专线订单
  // My fee	我的费用
  // Recharge management	充值管理
  // Bill	账单
  // Account running water	账号流水
  // Account management	账号管理
  // Security center	安全中心
  // Authority management	权限管理
  // Sub-account management	子账号管理
  // Basic data management	基础资料管理
  // Recipient and sender management	收发件人管理
  // Work order management	工单管理
  // Work order management	工单管理
  // Open platform management	开放平台管理
  // Api docking settin	api对接设置

  if (isArray(toCode)) {
    return toCode.some((code) => list.includes(code));
  } else if (typeof toCode === "string") {
    return list.includes(toCode);
  }

  // if(menuAuthorityCodes){
  //
  // }
  // let pagePermissionList = getLocalPagePermission();
  // let defaultList = [
  //   "/",
  //   "/login",
  //   "/testLink",
  //   "/NotFound",
  //   "/notPermission",
  // ];
  // return defaultList.includes(toPath) || pagePermissionList.includes(toPath);
}

/**
 * 请求用户菜单权限列表
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getMenuAuthorityList() {
  return api.user
    .getUserPagePermissionList()
    .then((res) => {
      console.log("获取权限菜单", res);
      let result = res.data || {};
      let list = result.omsAuthorityList || [];
      const menus = list.filter(v => v.authorityType === 1)
      const btns = list.filter(v => v.authorityType === 4)
      const userStore = useUserStore()
      menuAuthorityCodes = disposeMenuAuthorityList(menus);
      setLocalPagePermission(menus);
      userStore.setBtnPermission(btns.map(v => v.code))
      return res;
    })
    .catch((res) => {
      console.error(res);
      console.log("获取权限列表失败" + (res.message || res.returnMsg));
      const userStore = useUserStore()
      let list = [];
      setLocalPagePermission(list);
      userStore.setBtnPermission([])
    });
}

/**
 * 处理获取的权限菜单列表数据
 * @param list
 * @returns {*[]}
 */
export function disposeMenuAuthorityList(list = []) {
  // code集合
  let codes = [];
  list.forEach((item) => {
    codes.push(item.code);
  });
  return codes;
}

export const useUserStore = defineStore("user", () => {
  const token = ref(getLocalToken() || "");
  const userInfo = ref(getLocalUserInfo() || {});
  let userName = computed(() => {
    if (isObject(unref(userInfo))) {
      return unref(userInfo).nameCn || "";
    } else {
      return "";
    }
  });
  let userId = computed(() => {
    if (isObject(unref(userInfo))) {
      return unref(userInfo).id || "";
    } else {
      return "";
    }
  });
  let customerId = computed(() => {
    if (isObject(unref(userInfo))) {
      return unref(userInfo).customerId || "";
    } else {
      return "";
    }
  });
  // 设置token
  function setToken(value) {
    token.value = value;
    setLocalToken(value);
  }
  // 设置userInfo
  function setUserInfo(value) {
    if (!isObject(value)) value = {};
    userInfo.value = value;
    setLocalUserInfo(value);
  }
  // 清除用户登录相关信息
  function clearUserLoginInfo() {
    // localStorage.removeItem('user_info')
    setLocalSourceType();
    setToken("");
    setUserInfo();
    setBtnPermission();
    setLocalPagePermission();
  }

  // 用户按钮权限列表
  const btnPermission = ref(getLocalBtnPermission());
  /**
   * 设置用户按钮权限列表
   * @param list {Array<string>?}
   */
  function setBtnPermission(list) {
    if (!isArray(list)) list = [];
    btnPermission.value = list;
    setLocalBtnPermission(list);
  }
  /**
   * 检查是否有指定的按钮权限
   * @param keys  {string|Array<string>|(function(Array<string>):boolean)}  指定的按钮key，如果类型为function,则回调的第一个参数为已有按钮权限列表
   * @param alreadyHaveList {Array<string>} 已有按钮权限列表
   * @return {boolean}
   */
  function checkBtnPermission(keys, alreadyHaveList = btnPermission.value) {
    if (!isArray(alreadyHaveList) || !alreadyHaveList.length) return false;
    if (isArray(keys))
      return keys.every((key) => alreadyHaveList.includes(key));
    if (typeof keys === "function") return keys(alreadyHaveList);
    return alreadyHaveList.includes(keys);
  }

  /**
   * 获取用户信息
   * @param userId
   * @returns {*}
   */
  function requestUserInfo(userId) {
    return api.user
      .getUserInfo(userId)
      .then((res) => {
        let result = res.data || {};
        setUserInfo(result);
        return res;
      })
      .catch((res) => {
        console.error(res);
      });
  }

  /**
   * 快捷登录
   * @param token
   * @param userId
   */
  function quickLogin(token, userId) {
    clearUserLoginInfo(false);
    setToken(token);
    setLocalSourceType(4);
    return requestUserInfo(userId);
  }

  return {
    token,
    userInfo,
    userName,
    userId,
    customerId,
    setToken,
    setUserInfo,
    clearUserLoginInfo,
    btnPermission,
    setBtnPermission,
    checkBtnPermission,
    quickLogin,
    requestUserInfo,
  };
});
