<template>
  <div class="g-tabs2" :class="{ 'is-plain': plain }">
    <div class="g-tabs2--item" :class="{
      'is-active': item.value === modelValue,
      'is-plain': plain,
    }" @click="handleChange(item, index)" v-for="(item, index) in list" :key="`${item.value ? item.value : index}`">
      <slot :item="item" :index="index">
        <span>
          {{ item.label }}
        </span>
      </slot>
    </div>
    <slot name="innerAfter"></slot>
  </div>
</template>

<script setup>
const emit = defineEmits(["update:modelValue", "change"]);
const props = defineProps({
  list: {
    type: Array,
    default: () => [],
  },
  modelValue: [String, Number],
  plain: Boolean,
})

// let current = ref("");
// watch(() => props.modelValue, newValue => current.value = newValue );

/**
 * 切换tabs回调
 * @param item
 * @param index
 */
function handleChange(item, index) {
  // current.value = item.value;
  emit("update:modelValue", item.value);
  emit("change", item.value);
}
</script>

<style scoped lang="scss">
.g-tabs2 {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  height: 50px;
  border-bottom: 2px solid var(--el-color-primary);

  &--item {
    box-sizing: border-box;
    position: relative;
    z-index: 1;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    // transition: 0.4s ease;
    min-width: 101px;
    border-radius: 10px 10px 0px 0px;
    // border: 1px solid transparent;
    border-bottom: none;

    span {
      height: 100%;
      width: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: 3px solid transparent;
    }

    &:not(.is-plain).is-active {
      color: white;

      span {

        // height: 30px;
        border-bottom: 3px solid var(--el-color-primary);
      }

      // border-bottom: 3px solid var(--el-color-primary);
      // background-color: var(--el-color-primary);
      // border-color: var(--el-color-primary);
    }

    &.is-plain.is-active {
      color: var(--el-color-primary);
      border-bottom: 3px solid var(--el-color-primary);
      // background-color: var(--el-color-primary-light-9);
      // border-color: var(--el-color-primary);
    }

    &:not(.is-active) {
      //color: var(--el-color-primary);
      color: #333333;
    }
  }
}
</style>