import axiosMain from "@/axios";

/**
 * 用户操作日志-新增日志
 * @param customerId {integer}  	     客户id
 * @param userId {integer}  		     用户id
 * @param operationButtonCode {string}   操作按钮代码
 * @param operationContent {string}      操作内容
 * @param appType {string}  	         来源：oms: 客户OMS（官网）, applet: 小程序,	       
 * @param productPriceHeaderId {integer} 报价id，操作按钮为查看方案详情、优势线路产品时记录
 * @param loginType {integer}  		     登录类型，操作按钮为登录时记录：1 免密 2 密码 3 手机号验证码
 * @param remark {string}  			     备注
 * @returns {Promise<AxiosResponse<any>>}
 */
export function addSysUserOperateLog(data) {
    let url = '/oms/sysUserOperateLog/add'
    return axiosMain.post(url, data);
}

// 获取租户oms系统配置
export function getSysConfig(tenantId) {
    let url = `/oms/sysConfig/${tenantId}`
    return axiosMain.get(url)
}