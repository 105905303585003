<template>
  <!--首页：优势产品线路-->
  <g-wrap>
    <div class="dominant-product-line">
      <dominant-product-line-item
        class="dominant-product-line--item"
        v-for="(item, index) in list"
        :key="index"
        :index="index"
        :product-name="item.productName"
        :is-collected="item.concernSign"
        :start-city-name="item.startCityText"
        :end-country-name="item.destinationCountry"
        :end-warehouse-name="item.endWarehouseText"
        :aging="item.agingText"
        :goods-type-text="item.goodsTypeText"
        :currency="item.currency"
        :price="item.price"
        :charge-unit="item.chargeType"
        :need-inquiry-flag="item.needInquiryFlag"
        :preferential-flag="item.preferentialFlag"
        @placeOrder="handlePlaceOrder(item, index)"
        @collected="submitCollected(item, index)"
				@view-detail="handleDetail(item)"
      />
      <template v-if="list.length % 3 !== 0">
        <div
          class="dominant-product-line--item"
          v-for="(item, index) in Array(3 - (list.length % 3)).fill(1)"
          :key="`product_${index}`"
        />
      </template>
    </div>
  </g-wrap>

	<product-details v-model:visible="visibleProductDetail" :product-details="productDetailData"
									 :product-type="productType" :start-city-id="startCity.id" :start-city-name="startCity.name"
									 :end-country-id="endCountry.id" :end-country-name="endCountry.name" :end-country-code="endCountry.code"
									 :private-address-type="endCountry.privateAddressType" :end-warehouse-name="endWarehouse.name"
									 :end-warehouse-id="endWarehouse.id" :is-private-address="endWarehouse.isPrivateAddress"></product-details>
</template>

<script setup>
import GWrap from "@/components/gWrap/gWrap.vue";
import DominantProductLineItem from "@/views/home/components/dominantProductLine/dominantProductLineItem.vue";
import ProductDetails from "@/components/productDetails/productDetails.vue";
import { ElMessage } from "element-plus";
import { toPlaceOrderPage } from "@/utils/utils.placeOrder";
import { busShowLoginPopover } from "@/utils/bus";
import { useUserStore } from "@/stores";
import { useProductDetail } from "@/components/productDetails/useProductDetail";

const userStore = useUserStore();
const props = defineProps({
  list: {
    type: Array,
    default: () => [],
  },
  omsUncheckedCustomerOrderFlag:{
    type: Boolean,
    default: false
  }
});

// 货物类型数组
let productsList = ref([]);

function handlePlaceOrder(item, index) {
  // ElMessage.info("跳转下单页");
  if (item.userEnableFlag === false) {
    return ElMessage.warning("用户已禁用");
  }
  // console.log(userStore,'uuuuuuuuuuuuuuuuuuuu');

  if (!userStore.token) return busShowLoginPopover();
  if(userStore.userInfo?.checkStatus!==1 && !props.omsUncheckedCustomerOrderFlag){
    return ElMessage.warning("您的账户仍在审核中，如需下单请联系客服人员");
  }
  let _startCity = {};
  let multipleCityName = "";
  if (isArray(item.cityList) && item.cityList.length) {
    _startCity = item.cityList[0];
    multipleCityName = item.cityList.map((item) => item.name).join(",");
  }
  let _endWarehouse = {};
  if (isArray(item.destinationList) && item.destinationList.length) {
    _endWarehouse = item.destinationList[0];
  }
  // 起运地
  let data1 = "起运地:" + _startCity.name;
  //目的国
  let data2 = "目的国:" + item.destinationCountry;
  //目的地
  let data3 = "目的地：" + item.endWarehouseText;
  // 货物类型
  let data4 = "货物类型:" + "全部";
  // 产品名称
  let data5 = "产品名称：" + item.productName;
  let user_info = JSON.parse(localStorage.getItem("user_info")) || {};
  let use_btn_permission_list =
    JSON.parse(localStorage.getItem("use_btn_permission_list")) || [];
  if (
    Array.isArray(use_btn_permission_list) &&
    use_btn_permission_list.length > 0
  ) {
    api.operate.addSysUserOperateLog({
      customerId: user_info.customerId,
      userId: user_info.id,
      operationButtonCode: "ADVANTAGEOUS_LINE_PRODUCT",
      operationContent:
        data1 + "," + data2 + "," + data3 + "," + data4 + "," + data5,
      appType: "oms",
      productPriceHeaderId: "",
      loginType: "",
      remark: "",
    });
  }

  toPlaceOrderPage({
    // 物流产品类型
    productType: item.businessType,
    // 起运城市参数
    startCityName: _startCity.name,
    startCityId: _startCity.id,
    multipleCityName,
    // 目的国家
    endCountryName: item.destinationCountry,
    endCountryId: item.destinationId,
    endCountryCode: item.destinationCode,
    privateAddressType: item.privateAddressType,
    // 目的地（目的仓库）
    // endWarehouseName: _endWarehouse.name,
    // endWarehouseId: _endWarehouse.id,
    // 未返回"是否私人地址"暂不带目的地跳下单页
    // isPrivateAddress: _endWarehouse.isPrivateAddress,
    // 产品报价id
    id: item.id,
  });
}
function submitCollected(item, index) {
  ElMessage.info("模拟 收藏/取消收藏");
  item.concernSign = !item.concernSign;
}

const {
	productDetailData,
	visibleProductDetail,
	startCity,
	endCountry,
	endWarehouse,
	productType,
	getProductDetail
} = useProductDetail()
const handleDetail = (item) => {
	startCity.value = item.cityList ? item.cityList[0] : null
	endCountry.value = {
		id: item.destinationId,
		code: item.destinationCode,
		name: item.destinationCountry,
		privateAddressType: item.privateAddressType
	}
	if (item.destinationList && item.destinationList.length === 1) {
		endWarehouse.value = {
			id: item.destinationList[0].id,
			name: item.destinationList[0].name,
			isPrivateAddress: item.businessType !== 'FBX'
		}
	} else {
		endWarehouse.value = {
			id: '',
			name: '全部'
		}
	}
	getProductDetail(item.id, item)
}
</script>

<style scoped lang="scss">
.dominant-product-line {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-between;
  padding-bottom: 75px;
  gap: 30px;

  &--item {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 378px;
    min-height: 197px;
		cursor: pointer;
  }
}
</style>
