<template>
  <template v-if="userStore.token && userStore.userInfo">
    <el-dropdown trigger="hover" class="function-dropdown" v-bind="$attrs">
      <div class="function-item">
        <!--          <el-image class="user-portrait" src="/src/img" fit="cover" />-->
        <span class="">{{
          sourceType == 4 ? '匿名用户' : userStore.userInfo.customerName || userStore.userInfo.nameCn || "匿名用户"
        }}</span>
        <el-icon class="iconfont m-icon-nav-avater"></el-icon>
        <!-- <el-icon style="margin-right: 6px;"><Avatar/></el-icon> -->
        <!--          <span class="user-portrait"></span>-->
        <!--          <el-icon class="el-icon&#45;&#45;right">-->
        <!--            <arrow-down />-->
        <!--          </el-icon>-->
      </div>
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item>
            <el-button type="primary" @click="clickUser">{{
              $t("login.personalCenter")
            }}</el-button>
          </el-dropdown-item>
          <el-dropdown-item>
            <el-button type="primary" @click="outLogin">{{
              $t("login.logout")
            }}</el-button>
          </el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
  </template>
  <template v-else>
    <!--    <el-button type="primary" @click="goLogin">去登录<el-icon><DArrowRight /></el-icon></el-button>-->
    <!-- <el-button class="to-login-button" type="primary" size="large" @click="goLogin" v-bind="$attrs">去登录 -->
    <!--<el-icon><DArrowRight /></el-icon>-->
    <!-- </el-button> -->

    <div class="to-login">
      <span @click="goLogin">登录</span>
      <el-divider direction="vertical" />
      <span @click="goRegister">注册</span>
    </div>
    <el-icon class="iconfont m-icon-nav-avater"></el-icon>
  </template>
</template>

<script setup>
import { useSystemStore, useUserStore } from "@/stores";
import { reactive } from "vue";
import { useRouter } from "vue-router";
import { ElMessageBox } from "element-plus";
import { useI18n } from "vue-i18n";
import { post } from "@/axios";
import { busShowLoginPopover, busShowRegisterPopover } from "@/utils/bus";
import {
  getSourceType
} from "@/utils/utils.commonTenantParams";
const sourceType = ref(getSourceType())
const { t: $t } = useI18n();
const userStore = useUserStore();
// console.log('登录用户信息：', JSON.stringify(userStore));
let systemStore = useSystemStore();

// console.log('系统信息：', JSON.stringify(systemStore));
const router = useRouter();
defineOptions({
  inheritAttrs: false,
});
const props = defineProps({
  // 退出登录后是否跳转登录页
  toLoginPage: {
    type: Boolean,
    default: true,
  },
});

function goLogin() {
  // router.push({
  //   path:"/login"
  // })
  busShowLoginPopover();
}
function goRegister() {
  busShowRegisterPopover();
}
function clickUser() {
  router.push({
    path: "/system/systemHome",
  });
}
function outLogin() {
  let user_info = JSON.parse(localStorage.getItem("user_info")) || {};
  let use_btn_permission_list =
    JSON.parse(localStorage.getItem("use_btn_permission_list")) || [];
  if (
    Array.isArray(use_btn_permission_list) &&
    use_btn_permission_list.length > 0
  ) {
    api.operate.addSysUserOperateLog({
      customerId: user_info.customerId,
      userId: user_info.id,
      operationButtonCode: "USER_LOGIN_OUT",
      operationContent: "",
      appType: "oms",
      productPriceHeaderId: "",
      loginType: "",
      remark: "",
    });
  }

  const description = $t("login.confirmLogout");
  const title = $t("system.confirmOperation");
  ElMessageBox.confirm(description, title, {})
    .then(() => {
      userStore.clearUserLoginInfo();
      if (props.toLoginPage) {
        router.push({
          path: "/",
        });
      }
    })
    .catch(() => {});
}
</script>

<style scoped lang="scss">
.function-dropdown {
  color: inherit;
  //height: var(--layout-header-height);
  height: 100%;
}

.function-item {
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: inherit;
  outline: none;
}
div.user-portrait,
span.user-portrait {
  background-color: #eaeaea;
}
.user-portrait {
  width: 23px;
  height: 23px;
  border-radius: 50%;
  margin-right: 6px;
}
.to-login-button {
  width: 89px;
  //height: 38px;
  padding: 0;
  border-radius: 5px;
  border: none;
  //transition: 0.4s ease;
  &:not(:hover):not(:active):not(focus) {
    background: linear-gradient(
      90deg,
      var(--el-color-primary-light-5),
      var(--el-color-primary)
    );
  }
  &:hover {
    background: linear-gradient(
      90deg,
      var(--el-color-primary),
      var(--el-color-primary)
    );
    //background: var(--el-color-primary);
  }
  &:active {
    background: var(--el-color-primary);
    filter: grayscale(30%);
  }
}
.to-login {
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  cursor: pointer;
  .el-divider--vertical {
    margin: 0 10px;
    margin-bottom: 2px;
    border-color: #333333;
    vertical-align: middle;
  }
}
.m-icon-nav-avater {
  margin-left: 6px;
  color: #eeeeee;
  font-size: 34px;
  background: #ffffff;
  border-radius: 100px;
}
</style>