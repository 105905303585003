import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import NProgress from 'nprogress'
import { $t } from "@/language";
import { checkPagePermission, getMenuAuthorityList, useUserStore } from "@/stores/user";
import system from "@/router/modules/system";
import pages from "@/router/modules/pages";
import examples from "@/router/modules/examples";
import {
  checkLoginTimeoutNeedGoLoginPage, getRouteMeta,
  getRouterMetaTitle, goLoginPage,
  isLoginPage,
  isNeedCheckRouteProperty,
  mergeCurrentPagePathParams, removeParamsBeforeTheJumpLogin, setParamsBeforeTheJumpLogin
} from "@/utils/utils.router";
import { autoGetTenantId, getTenantId, requestTenantId, setLocalSourceType } from "@/utils/utils.commonTenantParams";
import { isPROD } from "@/axios";
import { useSystemStore } from "@/stores";
import { busShowLoginPopover } from "@/utils/bus";

// 配置NProgress
NProgress.configure({ showSpinner: false, })

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,

  },
  {
    path: '/test',
    name: 'test',
    component: () => import("@/views/testLink/test.vue")
  },
  ...pages,
  ...system,
  ...examples,
];
const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0, left: 0 }
    }
    // return new Promise((resolve, reject) => {
    //   setTimeout(() => {
    //     if (savedPosition) {
    //       resolve(savedPosition)
    //     } else {
    //       resolve({ top: 0, left: 0 })
    //     }
    //   }, 500)
    // })
  },
})
// 是否需要初始化页面菜单访问权限
let needInitMenuAuthority = true;
//挂载路由导航守卫
router.beforeEach(async (to, from, next) => {
  NProgress.start()
  let userStore = useUserStore();
  let query = to.query;
  const titleText = `从【${from.path}】页面跳转【${to.path}】时：`;
  // 租户id
  await autoGetTenantId();
  // 快捷登录
  if (query.type === "quickLogin") {
    if (query.token && query.userId) {
      userStore.quickLogin(query.token, query.userId);
      removeParamsBeforeTheJumpLogin();
      next({
        path: "/system/systemHome"
      })
    }
  }
  // 公共底部配置
  let systemStore = useSystemStore();
  await systemStore.autoGetWebFooterInfo();
  // 用户权限
  // let userStore = useUserStore();
  if (needInitMenuAuthority) {
    needInitMenuAuthority = false;
    if (userStore.token) {
      userStore.requestUserInfo(userStore.userId)
      await getMenuAuthorityList();
    }
  }

  if (!isLoginPage(to.path)) {
    // 需要登录
    let needLogin = isNeedCheckRouteProperty(to, "login");
    if (needLogin) {
      let userStore = useUserStore();
      if (!userStore.token) {
        console.log(titleText + "需要先登录");
        const record = mergeCurrentPagePathParams(to);
        setParamsBeforeTheJumpLogin(record);
        busShowLoginPopover();
        if (checkLoginTimeoutNeedGoLoginPage(to.path)) {
          goLoginPage();
        } else {
          // return next({path:"/",});
          return false
        }
      }
    }
  }
  // 需要页面访问权限
  let needPermission = isNeedCheckRouteProperty(to, "permission");
  if (needPermission) {
    let permissionCode = getRouteMeta(to).permissionCode;
    console.log(titleText + "需要页面访问权限", permissionCode);
    let hasPermission = checkPagePermission(permissionCode);
    if (!hasPermission) {
      console.log(titleText + "访问页面权限不足");
      console.error(Error(`访问页面权限不足:${titleText};${to.fullPath}`,));
      return next({
        name: "notPermission",
        query: {
          path: to.path,
          query: JSON.stringify(to.query),
          params: JSON.stringify(to.params),
        },
      });
    }
  }
  console.log(titleText)
  next();
});
router.afterEach((to, form) => {
  NProgress.done()
  // let title = getRouterMetaTitle(to);
  // if(title) document.title = title;
  // else document.title = $t("system.systemName");
})

export default router
