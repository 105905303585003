<template>
  <div class="select-place-template"
       ref="selectPlaceTemplate"
       :class="[styleClass,{'is-left-padding':isLeftPadding}]">
    <div class="input-box">
      <el-input class="search-text is-input"
                :class="{'is-clear':clearable,'is-temporary-disabled':isTemporaryDisabled}"
                ref="selectStartInput"
                :placeholder="isHasSelectValue?'':placeholder"
                v-model="searchText"
                :disabled="disabled || isTemporaryDisabled"
                @click="noEvent"
                @blur="handlerBlur"
                @focus="handlerFocus"
                @keyup.enter.native="handlerEnter"
                @keydown.tab.native="handlerTab"
      />
      <div class="value-text"
           :class="[showTextStatus,clearable?'is-clear':'']"
      >
        <slot name="valueText" :value-text="multipleShowName">
          <div class="text-row">
            {{ multipleShowName }}
          </div>
        </slot>
      </div>
    </div>
    <div class="btn-delete" v-if="!disabled && clearable && isHasSelectValue">
      <el-icon class="icon el-icon-circle-close"  @click="clickClear"><CloseBold /></el-icon>
    </div>
    <div class="btn-arrow-down" v-if="arrowDownIcon" @click.stop="changeArrowDown">
      <span class="icon-arrow-down" :class="{'is-rotate-z-180':visiblePopover}"/>
    </div>
    <el-popover
      :popper-class="`select-place-template-popper-3 ${popperClass}`"
      :show-arrow="true"
      ref="startPopover"
      trigger="contextmenu"
      placement="bottom-start"
      title=""
      :offset="offset"
      :width="popoverWidth"
      :visible="visiblePopover"
      virtual-triggering
      :virtual-ref="$refs.selectPlaceTemplate"
      @show="handlerShow"
      @after-enter="handlerAfterEnter"
      @hide="handlerHide"
      @after-leave="handlerAfterLeave"
    >
      <slot name="header">
        <template v-if="selectAllButton">
          <el-button
            :type="isSelectedAll? 'primary' : ''"
            @click="clickSelectAll"
            style="margin-left: 10px;width: 70px;"
            size="small"
            round
          >全部</el-button>
        </template>
      </slot>
      <div class="alter-place-bpx">
        <div class="alter-place-bpx--left" v-if="isVisibleTab">
          <div class="left-tabs">
            <div
                class="left-tabs--item"
                :class="{'is-active': currentTabValue === item.value,'is-multi-line': item.description||item.isMultiLine}"
                v-for="(item,index) in tabHeadList"
                :key="item.value"
                @click="changeTabs(item.value,item)"
            >
              {{item.label}}
              <template v-if="item.description">
                <span class="description">{{item.description}}</span>
              </template>
            </div>
          </div>
<!--          <el-tabs
              class="left-tabs"
              v-model="currentTabValue"
              tab-position="left"
              @tab-click="changeTabs"
          >
            <el-tab-pane
                v-for="(item,index) in tabHeadList"
                :label="item.label"
                :name="item.value"
                :key="item.value"
            />
          </el-tabs>-->
        </div>
        <div class="alter-place-bpx--right">
          <slot name="content">
            <template v-if="currentTabValue === 'inputZipCode'">
              <search-select-zip-code
                  :is-query="privateAddressType !== 3 && false"
                  :btn-text="privateAddressType === 3 && false ? '确认' : '搜索'"
                  :country-id="privateAddressType"
                  @change="changeInputZipCode"
              />
            </template>
            <template v-else-if="isLoading">
              <div class="hint-row"><i class="el-icon-loading"></i> {{ loadingText }} </div>
            </template>
            <template v-else-if="searchLoading">
              <div class="hint-row"><i class="el-icon-loading"></i> 正在搜索中... </div>
            </template>
            <template v-else-if="currentList && currentList.length">
              <template v-if="currentTabItem && currentTabItem.listLevel >= 2">
                <div class="groups-type-wrap" ref="wTabs" v-if="currentTabItem.listLevel === 3 && currentList.length > 1">
                  <div class="groups-type align-left">
                    <div v-for="(item,index) in currentList" :key="item[zeroLabelKey]" class="groups-type__item m-r-20-i"
                         :class="{ 'is-active': zeroGroupTypeIndex === index,'is-undefined': !item }" @click="clickZeroType(index,item)">
                      {{ item[zeroLabelKey] }}
                    </div>
                  </div>
                </div>
                <div class="groups-type-wrap" ref="tabs">
                  <div class="groups-type" :class="{'align-left':currentGroupList.length<=10}">
                    <div
                        class="groups-type__item"
                        :class="{'is-active': groupsTypeCurrent === index,'is-undefined': !item,}"
                        @click="clickAlertListType(index,item,$event)"
                        v-for="(item,index) in currentGroupList"
                        :key="index"
                    >
                      <template v-if="item">
                        {{ item[oneLabelKey] }}
                      </template>
                    </div>
                  </div>
                </div>
                <div class="groups-list-wrap" ref="list">
                  <div class="groups-list">
                    <div class="groups-li" v-if="currentTabItem.openSelectAll">
                      <div class="groups-li__left" v-if="currentTabItem.showLeftLabel"></div>
                      <div class="groups-li__right">
                        <div class="groups-li__item"
                             :class="{'is-active':getCurrentListValidList.length && !getCurrentListCanSelectList.length,'is-disabled':!getCurrentListValidList.length}"
                             @click="clickSelectAllItem">全选</div>
                      </div>
                    </div>
                    <div class="groups-li"
                         v-for="(item,index) in currentGroupList"
                         :key="index">
                      <template v-if="item">
                        <div class="groups-li__left" v-if="currentTabItem.showLeftLabel">{{item[oneLabelKey]}}</div>
                        <div class="groups-li__right">
                          <template v-if="item[oneChildrenKey] && item[oneChildrenKey].length">
                            <div class="groups-li__item"
                                 :title="d[itemLabelKey] + (d.disabled ? d.disabledText : '') "
                                 :class="{'is-active':judgeItemIsSelect(d),'is-disabled':d.disabled}"
                                 @click="selectItem(d)"
                                 v-for="(d,i) in item[oneChildrenKey]"
                                 :key="i">
                              {{d[itemLabelKey]}}
                            </div>
                          </template>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="groups-list-wrap is-allow-scroll">
                  <div class="groups-list">
                    <div class="groups-li">
                      <div class="groups-li__left" v-if="currentTabItem.showLeftLabel">{{ currentTabItem.label }}</div>
                      <div class="groups-li__right">
                        <div class="groups-li__item"
                             :class="{'is-active':getCurrentListValidList.length && !getCurrentListCanSelectList.length,'is-disabled':!getCurrentListValidList.length}"
                             @click="clickSelectAllItem"
                             v-if="currentTabItem.openSelectAll">全选</div>
                        <div class="groups-li__item"
                             :title="d[itemLabelKey] + (d.disabled ? d.disabledText : '') "
                             :class="{'is-active':judgeItemIsSelect(d),'is-disabled':d.disabled}"
                             @click="selectItem(d)"
                             v-for="(d,i) in currentList"
                             :key="i">
                          {{d[itemLabelKey]}}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </template>
            <template v-else>
              <div class="hint-row"><i class="el-icon-warning-outline"></i> {{ noDataMessage }} </div>
            </template>

            <template v-if="multiple && maxSelectNumber !== 1">
              <div class="footer-row">
                <el-button @click="hideListPopover"
                           class="btn btn-cancel"
                           type="info"
                           plain
                           size="default" >取消</el-button>
                <el-button @click="confirmSelect"
                           type="primary"
                           class="btn"
                           size="default">确认选择</el-button>
              </div>
            </template>

          </slot>
        </div>
      </div>
      <slot name="footer"></slot>
    </el-popover>

  </div>
</template>
<script>
import {baseDataPrivateAddressList} from "@/components/newSelectPlace/newSelectPlace.utils";
import BScroll from "better-scroll";
import SearchSelectZipCode from "@/components/newSelectPlace/searchSelectZipCode.vue";
import {sliceInputZipCode} from "@/components/newSelectPlace/searchSelectZipCode.utils";

export function baseDataList(){
  return Array(24).fill({}).map((res,index)=>{
    return {
      code:getRandomText(1),
      list:baseDataListItem(),
    }
  });
}
export function baseDataListItem(){
  return Array(10).fill({}).map((res,index)=>{
    let id = getRandomText(10);
    let namec = getRandomText(10);
    return {
      label:namec,
      value:id,
      namec:namec,
      id:id,
      isPrivateAddress:false,
      quoteStatus:undefined,
      disabled:Math.random() * 10 > 5 ,
    }
  })
}

let i = 1;
export function getRandomText(number){
  let arr = ["A","B","C","D","E","F","G","H","I","J","K","M","N","O","K"];
  let str = Array(number).fill("").map(res=>{
    let n = Math.floor(Math.random() * arr.length);
    return arr[n];
  })
  return str.join('');
}
export default {
  name: "selectPlaceTemplate3",
  components: {SearchSelectZipCode},
  emits: [
    'update:modelValue',
    'update:visible',
    'blur',
    'focus',
    'change',
    'clear',
    'hide',
    'show',
    'after-leave',
    'selectItem',
    'after-enter',
    'update:defaultTabValue',
  ],
  props:{
    // 是否展示默认的input（配套的search）,为false时需要自定义
    showInputView: {
      type:Boolean,
      default:true,
    },
    // 是否默认样式
    isDefaultStyle: {
      type:Boolean,
      default:true,
    },
    isWhiteStyle:Boolean,
    isLeftPadding: {
      type:Boolean,
      default:true,
    },
    // 选中值,单选Object/Array[{}],多选必须Array
    modelValue:{
      type:[Object,Array],
      default:()=>({}),
    },
    // 输入框是否禁用，禁用情况下点击输入框页无法弹出列表
    disabled:{
      type:Boolean,
      default:false,
    },
    // 是否支持多选
    multiple:{
      type:Boolean,
      default:false,
    },
    //最大选择数量,为零时不限制，只在选择某个item操作后做判断处理，不处理默认传入的value。
    maxSelectNumber:{
      type:Number,
      default:0,
    },
    // 是否可清空
    clearable:{
      type:Boolean,
      default:true,
    },
    // 是否展示下拉箭头
    arrowDownIcon:Boolean,
    // 选中某项item时（或全选时），会调用该callback,返回true/false
    selectItemCallback:{
      type:Function,
      // default(item,list,currentTabValue){
        // item 当前点击准备选择的item,（如果是全选时则，则为[item1,item2,item3....]的数组结构）
        // list 已选择的item集合，即selectValue[](copy版本,只有返回ture，对该list的修改才会生效)
        // currentTabValue 当前点击的item所属的tab Value
        // 返回true则正常执行插入list，返回false则不执行后续逻辑
        // return true;
      // },
    },
    // 是否在选中某项时过滤已选列表中 不同仓库类型(是否为私人地址) 的item
    // filterPrivateAddress:{
    //   type:Boolean,
    //   default:false,
    // },
    // 是否允许同时选中不同类型的值(不同类型)
    // differentTypesValue:{
    //   type:Boolean,
    //   default:false,
    // },
    // 显示 正在请求数据中...
    isLoading:{
      type:Boolean,
      default:false,
    },
    // 加载时显示的文本
    loadingText:{
      type:String,
      default:"正在请求数据中...",
    },
    //输入框提示信息
    placeholder:{
      type:String,
      default:"请选择",
    },
    //列表为空时的提示信息
    noDataMessage:{
      type:String,
      default:"暂未找到您要的信息",
    },
    //弹出层宽度
    popoverWidth:{
      type:String,
      default:"625",
    },
    //出现位置的偏移量
    offset:{
      type:Number,
      default:undefined,
    },
    // 弹窗class
    popperClass:{
      type:String,
      default:"",
    },
    //tab是否可见
    isVisibleTab:{
      type:Boolean,
      default:true,
    },
    // 默认选中的tab
    defaultTabValue:{
      type:String,
      default:"fba",
    },
    // 展示的 FBA/私人地址等tabs配置项列表
    tabHeadList:{
      type:Array,
      default:()=>[
        {
          label:"FBA仓库",
          // tab自身标识
          value:"fba",
          // 列表的层级，只支持1和2
          listLevel:3,
          // 2级列表是否显示左边的分组标签（展示首字母开头）
          showLeftLabel:true,
          // 是否展示全选按钮
          openSelectAll:true,
        },
        // {label:"FBX仓库", value:"FBX", listLevel:2, showLeftLabel:true, openSelectAll:true, },
        {label:"目的城市邮编首字母", value:"zipCode", listLevel:1, showLeftLabel:false, openSelectAll:true, },
        {label:"城市名称", value:"privateCity", listLevel:2, showLeftLabel:true, openSelectAll:true, },
        // {label:"test1", value:"privateCity1", listLevel:2, showLeftLabel:true, openSelectAll:true, },
        // {label:"test2", value:"privateCity2", listLevel:2, showLeftLabel:true, openSelectAll:true, },
        // {label:"test3", value:"privateCity3", listLevel:2, showLeftLabel:true, openSelectAll:true, },
        // {label:"test4", value:"privateCity4", listLevel:2, showLeftLabel:true, openSelectAll:true, },
        // {label:"test5", value:"privateCity5", listLevel:2, showLeftLabel:true, openSelectAll:true, },
        // {label:"test6", value:"privateCity6", listLevel:2, showLeftLabel:true, openSelectAll:true, },
        // {label:"test7", value:"privateCity7", listLevel:2, showLeftLabel:true, openSelectAll:true, },
        // {label:"test8", value:"privateCity8", listLevel:2, showLeftLabel:true, openSelectAll:true, },
        // {label:"非FBA仓", value:"noFba", listLevel:1,},
      ],
    },
    // 与tabHeadList[index].value对应的数据集
    data:{
      type:Object,
      default(){
       return {
         // {code:"A",list:[],} list 数据结构参考 baseDataPrivateAddressList()
         fba:baseDataList(),
         fbx:baseDataList(),
         zipCode:baseDataPrivateAddressList(),
         // {code:"广西",list:[],} list数据结构参考 baseDataPrivateAddressList()
         privateCity:baseDataList(),
       }
      }
    },
    // 多级列表时：一级分组展示的文本取值key
    zeroLabelKey: {
      type:String,
      default: 'warehouseTypeName'
    },
    // 多级列表时：一级分组展示的文本取值key
    zeroChildrenKey: {
      type:String,
      default: 'list'
    },
    // 多级列表时：二级分组展示的文本取值key
    oneLabelKey:{
      type:String,
      default:"code",
    },
    // 多级列表时：二级分组 子级列表的取值key
    oneChildrenKey:{
      type:String,
      default:"list",
    },
    // 选中item数据：展示的文本取值key，输入框也取该配置展示（无论是一级还是多级列表）
    itemLabelKey:{
      type:String,
      default:"namec",
    },
    // item数据中参与输入筛选的字段
    itemFilterKeys:{
      type:[String,Array],
      default:["namec","code"],
    },
    // 选中item数据：进行匹配校验的字段（无论是一级还是多级列表）
    itemValueKey:{
      type:String,
      default:"id",
    },
    // 文本输入框筛选时，每个item会调用该callback,返回true则显示，返回false则被过滤（如果为空则调用默认筛选逻辑）
    searchCallback:{
      type:Function,
      // default(text,item){
        // text 筛选文本
        // item item
        // 返回true则正常执行插入list，返回false则不执行后续逻辑
        // return true;
      // },
    },
    //筛选时，当前tabItem项没有筛结果时，是否筛选其他tabs并切换tabItem
    filterOtherTabs:Boolean,
    selectAllButton:Boolean,
    visible:Boolean,
    // 目的地国私人地址类型
    privateAddressType:[String,Number],
    // 目的国id
    endCountryId:[String,Number],
    // 目的国名称
    endCountryName:[String,Number],
    // 目的国Code
    endCountryCode:[String,Number],
    // 手填邮编时，是否截取邮编首字母作为id值 （需要同时满足 privateAddressType === 3 ）
    sliceZipCodeFirstStr:{
      type:Boolean,
      default:false,
    },
  },
  data() {
    return {
      /* 筛选 */
      searchText:"",//输入框搜索文本
      searchLoading:false,//筛选状态
      searchFilerList:[],//筛选出的列表
      /* */
      isFocus:false,//当前输入框是否获得焦点
      visiblePopover:false,// 显示弹出层
      /* 当前选中item 和 tab*/
      selectedValue:[],//选中值
      currentTabValue:"",//当前tab
      zeroGroupTypeIndex: 0,//当前选中的一级分组
      /* 二级滚动列表：当前选中的二级分组 */
      groupsTypeCurrent:0,
      scrollTargetTab:null,
      scrollTargetList:null,
      scrollTargetWTab: null,
      listDomHeight:[],
      // 是否临时禁用(关闭前需要设为true,关闭后再设回false,防止popover组件给触发目标内的input获取焦点导致关闭弹窗失败)
      isTemporaryDisabled:false,
    }
  },
  computed:{
    styleClass(){
      let defaultStyle = "";
      if(this.isWhiteStyle){
        return "is-white-style"
      }else if(this.isDefaultStyle){
        return "is-default-style"
      }
      return defaultStyle
    },
    /**
     * 回显文本状态 class
     * @returns {string}
     */
    showTextStatus(){
      // 搜索框有输入值
      if(this.searchText.length) return "opacity-0"
      // 搜索框没有输入值，但是聚焦状态
      if(this.isFocus) return "opacity-04"
      return "";
    },
    /**
     * 是否有选中值
     * @returns {*}
     */
    isHasSelectValue(){
      // 有选中值时 输入框不显示placeholder
      let list = this.selectedValue;
      return isArray(list) && list.length && list.some((res={})=>res[this.itemValueKey]||res[this.itemValueKey]===0);
    },
    /**
     * 展示的选中文本
     */
    multipleShowName(){
      let list = this.selectedValue;
      if(!isArray(list)) return "";
      return list.map(item =>{
        if(!isObject(item)) return item;
        return item[this.itemLabelKey] || item[this.itemValueKey]
      }).join(",");
    },
    /**
     * currentTabValue 对应的 item data
     */
    currentTabItem(){
      let value = this.currentTabValue;
      let hasTabValue = value || value === 0;
      if( !hasTabValue ) return {};
      let item = this.tabHeadList.find(item => item.value === value);
      if(!isObject(item)) item = {};
      return item;
    },
    /**
     * 当前 currentTabValue 在 data 中对应的数据列表
     */
    currentBaseList(){
      let value = this.currentTabValue;
      let hasTabValue = value || value === 0;
      if( !hasTabValue ) return [];
      let list = this.data[value];
      if(!isArray(list)) list = [];
      return list;
    },
    /**
     * 当前显示在界面上的列表数据：未被筛选或被筛选过的 currentTabValueList
     */
    currentList(){
      if(this.searchText || this.searchLoading){
        return this.searchFilerList;
      }else{
        return this.currentBaseList;
      }
    },
    currentGroupList() {
      if (this.currentTabItem.listLevel >= 3) {
        return this.currentList[this.zeroGroupTypeIndex][this.oneChildrenKey]
      } else {
        return this.currentList
      }
    },
    /**
     * 获取 当前currentList中有效的Item[](1级 or 2级)
     */
    getCurrentListValidList(){
      let list = this.currentList;
      let isLevel = this.currentTabItem.listLevel >=2 ;
      let itemList = [];
      let filter = item => {
        return isObject(item) && (item[this.itemValueKey] || item[this.itemValueKey] === 0) && !item.disabled ;
      }
      list.forEach(one=>{
        if(isLevel) one[this.oneChildrenKey].forEach(two=>{
          if(filter(two)) itemList.push(two);
        });
        else if(filter(one)) itemList.push(one);
      });
      return itemList;
    },
    /**
     * 获取 当前 getCurrentListValidList 中的可选列表(1级 or 2级)
     */
    getCurrentListCanSelectList(){
      let validList = this.getCurrentListValidList;
      let alreadySelectList = this.selectedValue;
      let alreadySelectIds = alreadySelectList.map(item=>item[this.itemValueKey]);
      return validList.filter(item=>!alreadySelectIds.includes(item[this.itemValueKey]));
    },
    /**
     * 刷新 多级列表滚动的条件
     * @returns {false|number}
     */
    isCanInitGroupsScroll(){
      let result = this.currentTabItem.listLevel >= 2 && this.currentList.length;
      return result;
    },
    /**
     * 当前是否选中全部
     */
    isSelectedAll(){
      let list = this.selectedValue;
      if(!isArray(list)) return false;
      let allItem = list.find(item=>item.id === "all");
      return !!allItem;
    },
    /**
     * 需要确认
     * @returns {boolean}
     */
    isNeedConfirm(){
      return this.multiple && this.maxSelectNumber !== 1
    },
  },
  watch:{
    /**
     * 默认选中值
     */
    modelValue:{
      immediate:true,
      // deep: true,
      handler: function (newValue) {
        this.selectedValue = this.receiveDefaultValue(this.modelValue);
        // this.searchText = this.modelValue[0].namec
      },
    },
    /**
     * 默认激活的tab选项
     */
    defaultTabValue:{
      immediate:true,
      handler: function (newValue) {
        console.log("defaultTabValue",newValue,this.currentTabValue === newValue);
        if(this.currentTabValue === newValue ) return false;
        this.currentTabValue = newValue;
        if(this.visiblePopover){
          this.destroyScrollTarget();
          if(this.isCanInitGroupsScroll) this.initGroupsScroll();
        }
      },
    },
    /**
     * 所有列表数据对象
     */
    data:{
      immediate: true,
      deep: true,
      handler: function (newValue) {
        this.zeroGroupTypeIndex = 0
        if(this.visiblePopover){
          this.destroyScrollTarget();
          if(this.isCanInitGroupsScroll) this.initGroupsScroll();
        }
      },
    },
    /**
     * 监听搜索输入框
     * @param newValue
     */
    searchText(newValue){
      this.searchLoading = true;
      this.searchFilerList = [];
      this.destroyScrollTarget();
      this.changeSearch();
    },
  },
  methods:{
    /**
     * 触发搜索
     */
    changeSearch:debounce(function(text = this.searchText){
      text = text.replace(/(^\s+)|(\s+$)/g,"");
      console.log(text);
      // text = text.toUpperCase();
      let baseList = this.currentBaseList;
      let tabItem = this.currentTabItem;
      let filterList = [];
      filterList = this.searchList(text,baseList,tabItem);
      if(!filterList.length && this.filterOtherTabs){
        let tabsList = this.tabHeadList.filter(item => item.value !== this.currentTabValue);
        tabsList.some(item=>{
          let baseList = this.data[item.value];
          let result = this.searchList(text,baseList,item);
          if(result.length){
            tabItem = item;
            filterList = result;
            this.currentTabValue = tabItem.value;
            this.emitChangeTabValue(tabItem.value);
            return true;
          }
          return false;
        })
      }
      console.log('filterList',filterList)
      this.searchFilerList = filterList;
      this.searchLoading = false;
      if(tabItem.listLevel >= 2) this.$nextTick(()=>{
        this.initGroupsScroll();
      })

    },200,false),
    /**
     * 搜索函数
     * @param text  {string}  搜索文本
     * @param baseList  {array} tabItem对应的list数据
     * @param tabItem {object}  tabHeadList列表的某项item
     * @returns {*[]}
     */
    searchList(text="",baseList=[],tabItem={}){
      let filterList = [];
      let hasCallback = typeof this.searchCallback === "function";
      let searchKeys = this.itemFilterKeys;
      if(typeof searchKeys === "string") searchKeys = [searchKeys];
      let filterFunction = (text="",item)=>{
        // console.log("============",item[this.itemLabelKey])
        // let itemLabel = String(item[this.itemLabelKey]||"");
        // let reg = RegExp(text,"ig");
        // return reg.test(itemLabel);
        let values = searchKeys.map(key=>item[key]);
        return values.some(value=>{
          let reg = RegExp(text,"ig");
          return reg.test(value);
        })
      };
      if(tabItem.listLevel === 3 ){
        const outList = []
        baseList.forEach(zero=>{
          let innerFilterList = []
          innerFilterList = zero[this.zeroChildrenKey].map(one => {
            let oneList = one[this.oneChildrenKey];
            if(!(isArray(oneList) && oneList.length)) return false;
            console.log('oneList',oneList)
            oneList = oneList.filter(item=>{
              return hasCallback ? this.searchCallback(text,item) : filterFunction(text,item);
            });
            if(!oneList.length) return false;
            return Object.assign({},one,{
              [this.oneChildrenKey]:oneList,
            });
          })
          innerFilterList = innerFilterList.filter(one=> one !== false);
          if (innerFilterList && innerFilterList.length) {
            outList.push({
              [this.zeroLabelKey]: zero[this.zeroLabelKey],
              [this.zeroChildrenKey]: innerFilterList
            })
          }
        });
        filterList = outList
        this.zeroGroupTypeIndex = 0
        // filterList = filterList.filter(one=> one !== false);
      } else if (tabItem.listLevel === 2) {
        filterList = baseList.map(one=>{
          let oneList = one[this.oneChildrenKey];
          if(!(isArray(oneList) && oneList.length)) return false;
          oneList = oneList.filter(item=>{
            return hasCallback ? this.searchCallback(text,item) : filterFunction(text,item);
          });
          if(!oneList.length) return false;
          return Object.assign({},one,{
            [this.oneChildrenKey]:oneList,
          });
        });
        filterList = filterList.filter(one=> one !== false);
      } else {
        filterList = baseList.filter(item=>{
          return hasCallback ? this.searchCallback(text,item) : filterFunction(text,item);
        });
      }
      return filterList;
    },
    /**
     * 空函数
     * */
    noEvent(){
      console.log("点击")
    },
    /**
     * 失去焦点时触发
     */
    handlerBlur(){
      console.log("失去焦点")
      this.isFocus = false;
      this.$emit('blur');
      // this.hideListPopover();
    },
    /**
     * 获得焦点时触发
     */
    handlerFocus(){
      console.log("获取焦点")
      this.isFocus = true;
      this.$emit('focus');
      this.showListPopover();
    },
    changeArrowDown(){
      let $input = this.$refs?.selectStartInput;
      console.log($input)
      if(this.visiblePopover){
        $input?.blur();
        // this.handlerBlur();
        this.hideListPopover();
        // this.transferFocus();
      }else{
        $input?.focus();
        this.showListPopover();
      }
    },
    transferFocus(){
      // let $target = this.$refs.testSpan;
      // if($target){
        nextTick(()=>{
          let $target = document.createElement("a");
          let content = document.createTextNode("focus");
          $target.style.opacity = "0";
          $target.style.display = "block";
          $target.style.width = "0px";
          $target.style.height = "0px";
          $target.style.overflow = "hidden";
          $target.appendChild(content);
          document.body.append($target);
          let e = document.createEvent("MouseEvents");
          e.initEvent("click",true,true);
          $target.dispatchEvent(e);
          $target.focus();
          setTimeout(()=>{
            $target.remove()
          },2000)
        })
      // }
    },
    /**
     * 按下回车时触发
     */
    handlerEnter(){
      console.log('按下回车')
    },
    handlerTab() {
      console.log('按下tab')
      this.handlerHide();
      this.hideListPopover();
    },
    /**
     * 清空
     */
    clickClear(){
      this.selectedValue = [];
      this.emitChange(undefined);
      this.$emit("clear");
    },
    /**
     * 确认选择
     */
    confirmSelect(){
      this.emitChange();
      this.hideListPopover();
    },
    /**
     * emit change
     * @param value
     */
    emitChange(value = this.selectedValue,notChangeEvent){
      if(!isArray(value)) value = [];
      if(this.multiple || isArray(this.modelValue)){

      }else{
        value = value[0]||{};
      }
      this.$emit("update:modelValue",value);
      if(notChangeEvent !== false) this.$emit("change",value);
    },
    /**
     * 显示、隐藏列表
     */
    showListPopover(){
      console.log("showListPopover()");
      // console.error("show")
      this.visiblePopover = true;
      this.$emit("update:visible",true);
      console.log("this.visiblePopover",this.visiblePopover);
    },
    hideListPopover(){
      console.log("hideListPopover()");
      // console.error("hide")
      this.isTemporaryDisabled = true;
      this.visiblePopover = false;
      this.searchText = "";
      this.$emit("update:visible",false);
      console.log("this.visiblePopover",this.visiblePopover);
    },
    handlerShow(){ //显示时触发
      console.log("显示时触发")
      this.$emit("show");
    },
    handlerAfterEnter(){ //	显示动画播放完毕后触发
      console.log("显示动画播放完毕后触发")
      this.$emit("after-enter");
      if(this.isCanInitGroupsScroll){
        this.$nextTick(()=>this.initGroupsScroll());
      }
    },
    handlerHide(){ // 隐藏时触发
      console.log("隐藏时触发")
      this.isTemporaryDisabled = true;
      this.$emit("hide");
      if(this.isCanInitGroupsScroll){
        this.destroyScrollTarget();
      }
    },
    handlerAfterLeave(){ // 隐藏动画播放完毕后触发
      console.log("隐藏动画播放完毕后触发")
      this.hideListPopover()
      this.$emit("after-leave");
      if(this.multiple && this.maxSelectNumber !== 1) { //多选时，关闭后将输入框内容重置为外部传入实际值
        this.selectedValue = this.receiveDefaultValue(this.modelValue);
      }
      this.$nextTick(()=>{
        setTimeout(()=>{
          this.isTemporaryDisabled = false;
        },100)

      })
    },

    /**
     * 根据 value传入的值（单选/多选）的数据类型处理为selectValue支持的格式 [{}]
     */
    receiveDefaultValue(value){
      let list = [];
      if(this.multiple){
        if(isArray(value)) list = value.concat([]);
        else console.error("multiple values are not arrays[Object]");
      }else{
        if(isObject(value)) list = [value];
        else if(isArray(value)) list = value.concat([]);
      }
      return list;
    },
    /**
     * 切换table
     */
    changeTabs(value,tabItem){
      if(this.currentTabValue === value ) return true;
      this.currentTabValue = value;
      // this.searchText = "";
      // this.searchFilerList = [];
      // this.searchLoading = false;

      this.destroyScrollTarget();
      if(this.searchText){
        this.changeSearch();
        return true;
      }
      if(this.currentTabItem.listLevel >= 2){
        if(this.currentList.length) this.$nextTick(()=>this.initGroupsScroll());
      }else{
        // this.destroyScrollTarget();
      }
      this.emitChangeTabValue(this.currentTabValue);
    },
    emitChangeTabValue(value) {
      this.$emit("update:defaultTabValue",value);
    },
    clickZeroType(index, item) {
      this.zeroGroupTypeIndex = index
      this.destroyScrollTarget();
      if(this.currentTabItem.listLevel >= 2){
        if(this.currentList[this.zeroGroupTypeIndex][this.zeroChildrenKey].length) this.$nextTick(()=>this.initGroupsScroll());
      }
    },
    /**
     * 二级滚动列表：点击切换一级分组
     */
    clickAlertListType(index,item,e){
      if (!e._constructed) {
        return false;
      } else {
        let rightItems = this.$refs.list.getElementsByClassName("groups-li");
        let targetIndex = this.currentTabItem.openSelectAll ? index + 1 : index;
        let el = rightItems[targetIndex];
        console.log(el)
        this.scrollTargetList.scrollToElement(el, 300);
      }
    },
    /**
     * 初始化分组滚动容器并注册监听
     */
    initGroupsScroll:function(){
      console.log("initGroupsScroll() = ",++i);
      if(!this.currentList.length) return false;
      if(!(this.$refs.tabs && this.$refs.list)) return false;
      this.scrollTargetTab = new BScroll(this.$refs.tabs, {
        click: true,
        mouseWheel: true,
        // tap: true,
      });
      this.scrollTargetList = new BScroll(this.$refs.list, {
        probeType: 3, //探针的效果，实时获取滚动高度
        mouseWheel: true, // 开启鼠标滚轮模式
        // disableMouse: true,
        // click: true,
        scrollbar:{
          fade:false,
          interactive:true,
          scrollbarTrackClickable:true,
        },
      });
      if (this.$refs.wTabs) {
        this.scrollTargetWTab = new BScroll(this.$refs.wTabs, {
          click: true,
          mouseWheel: true
        })
      }

      // 列表滚动处理逻辑
      this.scrollTargetList.on("scroll", e => this.listenListScroll(e));

      // 存储 列表item 高度
      this.countListDomHeight();
    },
    /**
     * 列表滚动处理逻辑
     * @param e
     */
    listenListScroll:function (e){
      const y = Math.abs(Math.round(e.y));
      const list = this.listDomHeight;
      // const boxHeight = this.$refs.list.clientHeight;
      // const lastItemHeight = list[list.length - 1];
      for (let i = 0; i < list.length; i++) {
        let height = list[i];
        let height2 = list[i + 1];
        if( !height2 || ( y >= height  && y < height2) ){
          this.groupsTypeCurrent = i;
          break;
        }
        // console.log(boxHeight,lastItemHeight,y);
        // console.log(lastItemHeight - y <= boxHeight,list.length - 1,list);
        // if(lastItemHeight - y <= boxHeight){
        //   this.groupsTypeCurrent = list.length - 1;
        //   console.log("ok",list.length - 1)
        //   break;
        // }
      }
    },
    /**
     * 当列表数据变动时（dom内容改变时），需要重新计算dom高度等数据
     */
    refreshScrollDom(){
      this.scrollTargetTab.refresh();
      this.scrollTargetList.refresh();
      this.scrollTargetList.scrollTo(0,0);
      this.scrollTargetWTab.refresh()
      this.countListDomHeight();
    },
    /**
     * 销毁 BetterScroll，解绑事件
     */
    destroyScrollTarget(){
      // if(this.scrollTargetTab && this.scrollTargetTab.destroy) this.scrollTargetTab = this.scrollTargetTab.destroy();
      // if(this.scrollTargetList && this.scrollTargetList.destroy) this.scrollTargetList = this.scrollTargetList.destroy();
      this.scrollTargetTab = this.scrollTargetTab && typeof this.scrollTargetTab.destroy === "function" ? this.scrollTargetTab.destroy() : null;
      this.scrollTargetWTab = this.scrollTargetWTab && typeof this.scrollTargetWTab.destroy === 'function' ? this.scrollTargetWTab.destroy() : null;
      // this.scrollTargetList = this.scrollTargetList && typeof this.scrollTargetList.destroy === "function" ? this.scrollTargetList.destroy() : null;
      if(this.scrollTargetList && typeof this.scrollTargetList.destroy === "function"){
        this.scrollTargetList.scrollTo(0,0);
        this.scrollTargetList = this.scrollTargetList.destroy();
      }else{
        this.scrollTargetList = null;
      }
      this.listDomHeight = [];
    },
    /**
     * 计算列表中每一个item的高度
     */
    countListDomHeight(){
      const target = this.$refs.list;
      if(!target) return this.listDomHeight = [];
      let listItems = target.getElementsByClassName("groups-li");
      let height = 0;
      let arr = [height];
      for (let i = 0; i < listItems.length; i++) {
        let item = listItems[i];
        height += item.clientHeight||0;
        arr.push(height);
      }
      this.listDomHeight = arr;
    },
    /**
     * 判断某项item是否选中
     */
    judgeItemIsSelect(item={}){
      let list = this.selectedValue;
      // if(this.isSelectedAll) return true;
      let selectSelf = isArray(list) && list.some((res={})=>res[this.itemValueKey] === item[this.itemValueKey]);
      if(selectSelf) return true;
      return false;
      // if(this.multiple){
      //   return isArray(this.modelValue) && this.modelValue.some((res={})=>res[this.idKey] === item[this.idKey]);
      // }else{
      //   return this.modelValue[this.idKey] === item[this.idKey];
      // }
    },
    /**
     * 点击选中某个值
     * @param item
     */
    selectItem(item){
      if(item.disabled) return false;
      let list = this.selectedValue||[];
      list = JSON.parse(JSON.stringify(list));
      let hasCallback = typeof this.selectItemCallback === "function";
      if(hasCallback){
        let result = this.selectItemCallback(item,list,this.multiple);
        if(!result) return false;
      }
      if(this.multiple && this.maxSelectNumber !== 1 ){
        const index = list.findIndex((res={})=>res.id === item.id);
        if(index >=0) {
          list.splice(index,1);
        }else{
          list.push(item);
        }
        let maxSelectLength = this.maxSelectNumber <= 0 ? 0 : this.maxSelectNumber;
        if(maxSelectLength && list.length > maxSelectLength){
          list.splice(0,list.length - maxSelectLength);
        }
        this.selectedValue = list;
        // 一个以上的选择点击确认按钮

      }else{
        this.selectedValue = [item];
        this.$emit("selectItem",item);
        this.emitChange();
        this.hideListPopover();
      }
    },
    /**
     * 点击全选(每个类型tab下的)
     */
    clickSelectAllItem(){
      if(this.getCurrentListCanSelectList.length){
        // 全选
        let alreadySelectList = this.selectedValue;
        let itemList = [];
        if(this.maxSelectNumber <= 0){
          let n = this.getCurrentListCanSelectList.length;
          itemList = this.getCurrentListCanSelectList.slice(0,n);
        }else{
          let n = this.maxSelectNumber - alreadySelectList.length;
          if(n < 1) {
            this.$message.error(`最多只能选择${this.maxSelectNumber}个选项`);
            return false;
          }
          itemList = this.getCurrentListCanSelectList.slice(0,n);
        }

        let hasCallback = typeof this.selectItemCallback === "function";
        if(hasCallback){
          alreadySelectList = JSON.parse(JSON.stringify(alreadySelectList));
          let result = this.selectItemCallback(itemList,alreadySelectList,this.multiple);
          if(!result) return false;
        }
        this.selectedValue = alreadySelectList.concat(itemList);
      }else{
        // 反选
        let currentListIds = this.getCurrentListValidList.map(item=>item[this.itemValueKey]);
        this.selectedValue = this.selectedValue.filter(item=>!currentListIds.includes(item[this.itemValueKey]));
      }
    },
    /**
     * 点击全选(类型tab上的)
     */
    clickSelectAll(){
      let allItem = {
        [this.itemLabelKey]:"全部",
        [this.itemValueKey]:"all",
        "type":"all",//all fba privateCity-城市  zipCode-邮编
      };
      if(this.isSelectedAll) {
        this.selectedValue = [];
      }else{
        this.selectedValue = [allItem];
      }
      if(!this.isNeedConfirm){
        this.$emit("selectItem",allItem);
        this.emitChange();
        this.hideListPopover();
      }
    },
    /**
     * 全局关闭
     */
    globalClosePopover(e){
      if(!this.visiblePopover) return false;
      let wrapView = this.$refs.startPopover;
      let {contentRef,triggerRef} = !wrapView?null:wrapView.popperRef;
      // console.log(wrapView.popperRef)
      // console.log(contentRef)
      // console.log(triggerRef)
      if(triggerRef && triggerRef.contains && triggerRef.contains(e.target)) return true;
      if(contentRef && contentRef.contains && contentRef.contains(e.target)) return true;
      this.hideListPopover();
    },
    /* 手填邮编 */
    changeInputZipCode(item){
      let _endWarehouseId = item.id;
      if(this.sliceZipCodeFirstStr && this.privateAddressType === 3) _endWarehouseId = sliceInputZipCode(item.id,true,3);
      let newItem = {
        // 目的国家
        country: this.endCountryName||"",
        countryCode: this.endCountryCode||"",
        countryId: this.endCountryId||"",
        // 目的国家对应 私人地址派送类型 1:邮编 2：城市 2:邮编开头
        privateAddressType: this.privateAddressType,
        // 对应城市
        city: "",
        cityId: "",
        // fba 邮编
        id: _endWarehouseId,
        namec: `${item.namec}`,
        namee: `${item.namec}`,
        isPrivateAddress:true,
        isPrivateAddressCity:false,
        _description:`私人地址(邮编)${item.namec}`,
      }
      this.selectItem(newItem);
    },
  },
  created() {
    // this.countDefaultSelect();
    document.addEventListener("click",this.globalClosePopover,false);
  },
  mounted() {
    if(this.visible) this.visiblePopover = this.visible;
  },
  destroy() {
    document.removeEventListener("click",this.globalClosePopover);
  },
}
</script>

<style lang="scss">
.select-place-template-popper-3{
  --sp-popover-min-height: 200px;
  --sp-popover-max-height: 250px;
  &.is-end-country{
    --sp-popover-max-height: 292px;
  }
  &.el-popover.el-popper{
    padding: 0;
    border-radius: 10px;
  }
}
</style>
<style scoped lang="scss">
/* 最外层容器 */
.select-place-template{
  --sp-input-color: inherit;
  --sp-input-font-size: 18px;
  --sp-input-font-weight: 600;
  --sp-input-placeholder-color: #999;
  --sp-input-placeholder-font-weight: 600;
  //width: 100%;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;

  /* 默认样式 */
  width: 200px;
  max-width: 100%;
  height: 50px;
  max-height: 100%;
  background-color: transparent;
  //background-color: #F5F5F5;
  border-radius: 10px;

  :deep(.el-input__inner){
    height: inherit;
    line-height: inherit;
  }
  &,
  * {
    box-sizing: border-box;
  }
  &:not(.is-disabled){
    .btn-arrow-down{
      cursor: pointer;
    }
    &:hover{
      .btn-delete{
        display: flex;
      }
      //.search-text{
      //  :deep(input){
      //    padding-right: 0;
      //  }
      //  &:not([class~="is-input"]){
      //    padding-right: 0;
      //  }
      //}
      //.value-text{
      //  right: 30px;
      //}
    }
  }
}
.input-box{
  flex: 1;
  position: relative;
  color: var(--sp-input-color);
}
.btn-delete{
  position: relative;
  z-index: 1;
  min-width: 30px;
  height: 30px;
  text-align: center;
  font-size: 14px;
  display: none;
  //display: flex;
  align-items: center;
  justify-content: center;
  .icon{
    cursor: pointer;
    color: #c0c4cc;
    &:hover{
      color: #909399;
    }
  }
}
.btn-arrow-down{
  position: relative;
  z-index: 1;
  min-width: 30px;
  height: 30px;
  text-align: center;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;

}
.icon-arrow-down{
  --arrow-color: #999999;
  --arrow-width: 14px;
  --arrow-height: 8px;
  display: block;
  border-style: solid solid none solid;
  border-color: var(--arrow-color) transparent transparent transparent;
  border-width: var(--arrow-height) calc(var(--arrow-width) / 2)  0 calc(var(--arrow-width) / 2);
  transition: 0.4s ease;
  transform: rotateX(0deg);
}
.is-rotate-x-180{
  transform: rotateX(180deg);
}
.is-rotate-z-180{
  transform: rotateZ(-180deg);
}


/* 搜索框 */
.search-text{
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  font-size: var(--sp-input-font-size);
  font-weight: var(--sp-input-font-weight);
  &,
  &.is-disabled :deep(.el-input__wrapper),
  &.is-disabled :deep(input),
  :deep(.el-input__wrapper),
  :deep(input)
  {
    box-sizing: border-box;
    color: inherit;
    background-color: transparent;
    box-shadow: none;
  }
  :deep(.el-input__wrapper){
    padding: 0;
  }
  :deep(input){
    min-width: 0;
    width: 100%;
    height: 100%;
    border: none;
    font-weight: inherit;
  }
  ::-webkit-input-placeholder {
    font-weight: var(--sp-input-placeholder-font-weight);
    color: var(--sp-input-placeholder-color);
  }
  &:not([class~="is-input"]){
    display: flex;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 15px;
    cursor: pointer;
    color: #999;
  }
  &.is-clear{
    //:deep(input){
    //  padding-right: 0;
    //}
    //&:not([class~="is-input"]){
    //  padding-right: 0;
    //}
  }
}

/* 选中值回显文本 */
.value-text{
  position: absolute;
  z-index: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  transition: opacity 0.4s ease;
  font-size: var(--sp-input-font-size);
  font-weight: var(--sp-input-font-weight);
  &.is-clear{
    //right: 30px;
  }
}
.opacity-0{
  opacity: 0;
}
.opacity-04{
  opacity: 0.4;
}
.text-row{
  //padding: 0 11px;
  //font-size: 16px;
  //color: inherit;
  line-height: 30px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.is-default-style{
  &.select-popover{

  }
  .search-text{
    //color: #333333;
  }
}
.is-left-padding{
  .text-row,
  .search-text{
    padding-left: 15px;
    //  :deep(.el-input__wrapper){
    //    padding-left: 15px;
    //    padding-right: 15px;
    //  }
  }
}
.is-white-style{
  --sp-input-color:white;
}

/* 弹出框内容 */
.alter-place-bpx{
  display: flex;
  //align-content: space-evenly;
  align-content: space-evenly;
  min-height: var(--sp-popover-min-height);
  max-height: var(--sp-popover-max-height);
  height: auto;
  &--left{
    // $-left-width:100px;
    $-left-width:140px;
    $-left-inner-padding:12px;
    box-sizing: border-box;
    width: $-left-width;
    //min-width: 100px;
    background-color: #F1F1F1;
    border-radius: 10px 0 0 10px;
    //height: 250px;
    overflow: hidden;
    .left-tabs{
      box-sizing: border-box;
      width: $-left-width + 17px;
      height: 100%;
      padding: $-left-inner-padding 0 $-left-inner-padding $-left-inner-padding;
      overflow: hidden scroll;
      &--item{
        $-left-item-width: $-left-width -  $-left-inner-padding * 2;
        width: $-left-item-width;
        box-sizing: border-box;
        border-radius: 5px;
        font-size: 16px;
        font-weight: 500;
        color: #333333;
        text-align: center;
        &:not(.is-multi-line){
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          line-height: 28px;
        }
        &.is-multi-line{
          padding: 4px 0;
          line-height: 20px;
        }
        &:not(:first-child){
          margin-top: 12px;
        }
        &:not(.is-active){
          cursor: pointer;
          &:hover{
            color: var(--el-color-primary);
          }
        }

        &.is-active{
          background-color: var(--el-color-primary);
          color: white;
        }
      }
    }
    /*.left-tabs.el-tabs{
      height: 100%;
      .el-tabs__header {
        margin-right: 0 !important;
      }
      .el-tabs__item{
        padding: 0 10px;
      }
      .el-tabs__nav-wrap:after{
        content: none;
        display: none;
      }
      .el-tabs__active-bar{
        display: none;
      }
      .el-tabs__content {

      }
    }

    &{
      .el-tabs__nav{
        padding: 0 10px;
      }
      .el-tabs__item + .el-tabs__item{
        margin-top: 10px;
      }
      .el-tabs__item{
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 16px;
        font-weight: 500;
        color: #333333;
        text-align: center !important;
        height: 28px;
        line-height: 28px;
        &.is-active{
          //height: 28px;
          color: white;
          background-color: var(--el-color-primary);
          border-radius: 5px;
        }
      }
    }*/
  }
  &--right{
    flex: 1;
    box-sizing: border-box;
    padding: 12px 12px 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
}
.zero-type-wrap {
  display: flex;
  align-items: center;
  &_item {
    min-width: 80px;
    height: 28px;
    border-radius: 4px;
    cursor: pointer;
    background-color: #F5F5F5;
    color: #141414;
    text-align: center;
    line-height: 28px;
    & + & {
      margin-left: 10px;
    }
    &:hover {
      background-color: var(--el-color-primary);
      color: #FFFFFF;
    }
  }
  .is-active {
    background-color: var(--el-color-primary);
    color: #FFFFFF;
  }
}
.groups-type-wrap{
  overflow: hidden;
  margin-bottom: 10px;
}
.groups-type{
  display: flex;
  align-items: center;
  border-bottom: 1px solid #F5F5F5;
  &:not([class~="align-left"]){
    justify-content: space-between;
  }
  &__item{
    .align-left & {
      margin-right: 10px;
    }
    white-space: nowrap;
    line-height: 28px;
    //font-size: 16px;
    text-align: center;
    cursor: pointer;
    position: relative;
    &.is-undefined{
      display: none !important;
    }
    &.is-active{
      color: var(--el-color-primary);
      font-weight: 600;
      &:before{
        content: "";
        position: absolute;
        z-index: 1;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 2px;
        background-color: var(--el-color-primary);
      }
    }
  }
}
.groups-list-wrap{
  flex: 1;
  //height: 200px;
  margin-right: -14px;
  overflow: hidden;
  position: relative;
  &.is-allow-scroll{
    overflow-y: auto;
  }
}
.groups-list{

}
.groups-li{
  display: flex;
  &__left{
    box-sizing: border-box;
    width: 50px;
    color: #333;
    font-weight: 600;
    margin-bottom: 10px;
    line-height: 34px;
    height: 34px;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &__right{
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
  }
  &__item{
    box-sizing: border-box;
    width: 70px;
    padding: 0 6px;
    background: #f5f5f5;
    margin-right: 10px;
    margin-bottom: 6px;
    border-radius: 20px;
    color: #666;
    cursor: pointer;
    min-width: 60px;
    height: 34px;
    line-height: 34px;
    text-align: center;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &:not(.is-active):not(.is-disabled):hover{
      color:var(--el-color-primary);
      background-color: var(--el-color-primary-light-9);
    }
    &.is-active{
      background: var(--el-color-primary);
      color: white;
    }
    &.is-disabled{
      cursor: not-allowed;
      color: #bcbec2;
      background-color: #f4f4f5;
      border-color: #e9e9eb;
    }
  }
}

.footer-row{
  display: flex;
  justify-content: flex-end;
  margin-top: 14px;
  .btn{
    width: 123px;
    border-radius: 5px;
  }
  .btn-cancel{
    &:not(:hover){
      color: #999999;
      background-color: #EEEEEE;
      border-color: #EEEEEE;
    }
  }
}
.m-r-20-i {
  margin-right: 20px !important;
}
</style>
