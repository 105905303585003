<template>
  <!--首页：覆盖国家-->
  <div class="coverage-country">
    <div class="left-box">
      <div class="country-tabs">
        <div
          class="country-tabs--item"
          :class="{ 'is-active': item.value === currentValue }"
          @click="clickTabsItem(item, index)"
          v-for="(item, index) in data"
          :key="item.value"
        >
          {{ item.label }}
          <span>({{ item.total }})</span>
        </div>
      </div>
    </div>
    <div class="right-box">
      <template v-if="currentList.length">
        <div
          class="slide-arrow slide-arrow--left"
          @click="clickPrev"
          v-if="slideIndex > 0"
        >
          <el-icon>
            <DArrowLeft />
          </el-icon>
        </div>
        <div
          class="slide-arrow slide-arrow--right"
          @click="clickNext"
          v-if="slideIndex + 1 !== currentList.length"
        >
          <el-icon>
            <DArrowRight />
          </el-icon>
        </div>
      </template>
      <div class="country-switch">
        <!--          {{currentList.length}}-->
        <el-carousel
          ref="countrySlide"
          style="height: 100%"
          height="100%"
          arrow="never"
          :autoplay="true"
          trigger="click"
          @change="changeSlideIndex"
        >
          <el-carousel-item
            v-for="(sonList, sonIndex) in currentList"
            :key="sonIndex"
          >
            <div class="country-list">
              <!--{{sonList}}-->
              <coverage-country-single-country class="country-item" v-for="(item, index) in sonList" :key="item.id"
                :country-cover="item.logoUrl" :country-name="item.countryName" :currency="item.currency" :needInquiryFlag="item.needInquiryFlag" :preferential-flag="item.preferentialFlag"
                :chargeUnit="item.chargeType" :price="item.minPrice" @lookDetails="lookDetails(item, index)" />
              <template v-if="sonList.length % 4 !== 0">
                <div
                  class="country-item"
                  v-for="(item, index) in Array(4 - (sonList.length % 4)).fill(
                    1
                  )"
                  :key="`country_${sonIndex}_${index}`"
                />
              </template>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
  </div>
</template>

<script setup>
import CoverageCountrySingleCountry from "@/views/home/components/coverageCountry/coverageCountrySingleCountry.vue";
import GWrap from "@/components/gWrap/gWrap.vue";
import { ElMessage } from "element-plus";

const emit = defineEmits(["update:currentValue", "change"]);
const props = defineProps({
  data: {
    type: Array,
    default: () => [],
  },
  currentValue: [String, Number],
  // bgUrl: {
  //   type: String,
  //   default: ossPath + "/pages/home/coverage_country_bg.png",
  // }
});
let countrySlide = ref(null);
let slideIndex = ref(0);
let currentList = computed(() => {
  let item = props.data.find((item) => item.value === props.currentValue);
  if (isObject(item) && isArray(item.groupingList)) {
    return item.groupingList;
  } else {
    return [];
  }
});
function clickTabsItem(item, index) {
  if (props.currentValue !== item.value) {
    emit("update:currentValue", item.value);
    emit("change", item.value, item);
    slideIndex.value = 0;
  }
}

function lookDetails(item, index) {
  // 起运城市
  let _startCity = {
    id: item.departureCityId,
    namec: item.departureCity,
  };
  // 目的国
  let _endCountry = {
    id: item.countryId,
    namec: item.countryName,
    code: item.countryCode,
    privateAddressType: item.privateAddressType,
  };
  // 目的地
  let _endWarehouse = {};
  // 货物类型
  let _goodsType = {};
  // 货物名称
  let _goodsName = {};

  if (!_startCity.id) return ElMessage.info("缺少起运地id");
  if (!_endCountry.id) return ElMessage.info("缺少目的国id");

  let searchData = {
    // 物流产品类型
    transportationType: item.businessType || "FBX",
    // 起运城市
    startCityId: _startCity.id,
    startCityName: _startCity.namec,
    // 目的国
    endCountryId: _endCountry.id,
    endCountryName: _endCountry.namec,
    endCountryCode: _endCountry.code,
    privateAddressType: _endCountry.privateAddressType,
    // 目的地（目的仓）
    // endWarehouseId:_endWarehouse.id,
    // endWarehouseName:_endWarehouse.namec,
    // isPrivateAddress:_endWarehouse.isPrivateAddress,
    // 货物类型
    // goodsTypeId: _goodsType.goodsTypeId,
    // goodsTypeName: _goodsType.goodsTypeName,
    // 商品名称
    // goodsNameId: _goodsName.id,
    // goodsNameName: _goodsName.namec,
    // cbm
    // cbm: undefined,
  };
  let user_info = JSON.parse(localStorage.getItem("user_info")) || {};
  let use_btn_permission_list =
    JSON.parse(localStorage.getItem("use_btn_permission_list")) || [];
  if (
    Array.isArray(use_btn_permission_list) &&
    use_btn_permission_list.length > 0
  ) {
    api.operate.addSysUserOperateLog({
      customerId: user_info.customerId,
      userId: user_info.id,
      operationButtonCode: "COVER_THE_COUNTRY",
      operationContent: _endCountry.namec,
      appType: "oms",
      productPriceHeaderId: "",
      loginType: "",
      remark: "",
    });
  }

  Object.keys(searchData).forEach((key) => {
    let value = searchData[key];
    if (typeof value === "undefined") searchData[key] = "";
  });
  console.log("跳转参数", searchData);
  localStorage.setItem("search_product_list_data", JSON.stringify(searchData));
  router.push({
    path: "/productList",
    query: {
      remember: "no",
    },
  });
}

function changeSlideIndex(value) {
  slideIndex.value = value;
}
function clickPrev() {
  unref(countrySlide).prev();
}
function clickNext() {
  unref(countrySlide).next();
}
</script>

<style scoped lang="scss">
.coverage-country {
  height: 384px;
  display: flex;
  padding-bottom: 92px;
}

.left-box {
  width: 120px;
  //min-width: 125px;
  height: 384px;
  background-color: #ffffff;
  overflow: hidden;
  border-radius: 12px;
}

.country-tabs {
  // width: 125 + 20px;
  height: 100%;
  overflow: hidden auto;
  padding-top: 20px;
  &--item {
    // width: 105px;
    height: 38px;
    line-height: 38px;
    // text-align: center;
    // border-radius: 5px;
    background-color: transparent;
    font-size: 14px;
    font-weight: 500;
    color: #333333;
    // margin: 22px 0 22px 10px;
    margin-bottom: 10px;
    padding-left: 12px;

    &.is-active {
      color: white;
      // background: linear-gradient(90deg, var(--el-color-primary-light-5), var(--el-color-primary));
      background: var(--el-color-primary);
    }

    &:not(.is-active) {
      cursor: pointer;

      &:hover {
        color: white;
        // background: linear-gradient(225deg, var(--el-color-primary-light-5), var(--el-color-primary));
        background: var(--el-color-primary);
      }
    }
  }
}

.right-box {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}

.slide-arrow {
  $--width: 45px;
  $--height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  border-radius: 50%;
  color: #adadad;
  position: absolute;
  top: 50%;
  z-index: 10;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  width: $--width;
  height: $--height;

  &:hover {
    color: var(--el-color-primary);
  }

  &--left {
    left: 0;
  }

  &--right {
    right: -$--width;
  }
}

.country-switch {
  box-sizing: border-box;
  width: 1032px;
  height: 392px;
  background-color: #ffffff;
  border-radius: 10px;
  // overflow: auto;
}

.country-list {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 40px 30px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 1fr);
  column-gap: 30px;
  row-gap: 50px;
}

.country-item {
  box-sizing: border-box;
  width: 220px;
  height: 68px;
  border-radius: 8px;

  //margin-right: 10px;
  &:nth-child(4n) {
    margin-right: 0;
  }
}
:deep(.el-carousel__button) {
  width: 40px;
  height: 6px;
  background-color: #d8d8d8;
  opacity: 1;
}
:deep(.el-carousel__indicator.is-active .el-carousel__button) {
  background-color: var(--el-color-primary);
}
</style>
